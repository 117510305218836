import { api } from '../utils/api';
import { Role } from "../types/Role";
import { TypeRequest } from "../types/TypeRequest";

export const getRoles = async (): Promise<Array<Role>> => {
    const response = await api.get(`/api/roles`);
    return response.data;
};

export const createRole = async (request: TypeRequest): Promise<Role> => {
    const response = await api.post(`/api/roles`, request);
    return response.data;
};

export const updateRole = async (id: string, request: TypeRequest): Promise<number> => {
    const response = await api.patch(`/api/roles/${id}`, request);
    return response.data;
};

export const deleteRole = async (id: string): Promise<void> => {
    await api.delete(`/api/roles/${id}`);
};