import { api } from '../utils/api';
import { PagedRequest } from "../types/PagedRequest";
import { PagedResponse } from "../types/PagedResponse";
import { Profile } from "../types/Profile";
import { ListBySkillsRequest } from "../types/ListBySkillsRequest";
import { ProfileCreateRequest } from "../types/ProfileCreateRequest";
import { ProfileUpdateRequest } from "../types/ProfileUpdateRequest";
import { ProfileAuthorizeRequest } from "../types/ProfileAuthorizeRequest";
import { ProfileEnableRequest } from '../types/ProfileEnableRequest';
import { MemberRequest } from '../types/MemberRequest';
import { Project } from '../types/Project';

export const getProfiles = async (request: PagedRequest): Promise<PagedResponse<Profile>> => {
    const response = await api.post(`/api/profiles/list`, request);
    return response.data;
};

export const getProfilesBySkills = async (request: ListBySkillsRequest): Promise<Array<Profile>> => {
    const response = await api.post(`/api/profiles/list/skills`, request);
    return response.data;
};

export const getProfileById = async (id: string): Promise<Profile> => {
    const response = await api.get(`/api/profiles/${id}`);
    return response.data;
};

export const createProfile = async (request: ProfileCreateRequest): Promise<Profile> => {
    const response = await api.post(`/api/profiles`, request);
    return response.data;
};

export const updateProfile = async (id: string, request: ProfileUpdateRequest): Promise<Profile> => {
    const response = await api.patch(`/api/profiles/${id}`, request);
    return response.data;
};

export const deleteProfile = async (id: string): Promise<void> => {
    await api.delete(`/api/profiles/${id}`);
};

export const enableProfile = async (id: string, request: ProfileEnableRequest): Promise<number> => {
    const response = await api.patch(`/api/profiles/${id}/enable`, request);
    return response.data;
};

export const disableProfile = async (id: string, request: ProfileEnableRequest): Promise<number> => {
    const response = await api.patch(`/api/profiles/${id}/disable`, request);
    return response.data;
};

export const addSkillToProfile = async (request: ProfileAuthorizeRequest): Promise<Profile> => {
    const response = await api.post(`/api/profiles/${request.profileId}/skills/${request.skillId}`);
    return response.data;
};

export const removeSkillFromProfile = async (request: ProfileAuthorizeRequest): Promise<Profile> => {
    const response = await api.delete(`/api/profiles/${request.profileId}/skills/${request.skillId}`);
    return response.data;
};

export const addMemberToProfile = async (id: string, request: MemberRequest): Promise<Profile> => {
    const response = await api.post(`/api/profiles/${id}/members`, request);
    return response.data;
};

export const removeMemberFromProfile = async(id: string, request: MemberRequest): Promise<Profile> => {
    const response = await api.delete(`/api/profiles/${id}/members`, {
        data: request
    });
    return response.data;
};

export const getProjectsByProfile = async (profileId: string): Promise<Array<Project>> => {
    const response = await api.get(`/api/profiles/${profileId}/projects`);
    return response.data;
};
