import React, {useState, ReactNode, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    Checkbox, Select, ListItemButton,
} from '@mui/material';
import {Log} from "../../utils/utils";
import {useDb} from "../../contexts/DbContext";

interface ModalInputProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (selectedItems: Array<string>) => void;
    title: string;
    children: ReactNode;
    multiSelect?: boolean;
    initialValue: string[];
}

const ModalInput: React.FC<ModalInputProps> = ({
                                                           open,
                                                           onClose,
                                                           onConfirm,
                                                           title,
                                                           children,
                                                           multiSelect = false,
                                                           initialValue = []
                                                       }) => {

    const {t} = useDb();

    const [selectedItems, setSelectedItems] = useState<Array<string>>([]);

    const handleToggle = (value: string) => () => {
        const currentIndex = selectedItems.indexOf(value);
        const newChecked = [...selectedItems];

        if (currentIndex === -1) {
            if (multiSelect) {
                newChecked.push(value);
            } else {
                newChecked.splice(0, newChecked.length, value);
            }
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedItems(newChecked);
    };

    const handleConfirm = () => {
        onConfirm(selectedItems);
        Log.debug('ModalInput: ' + JSON.stringify(selectedItems));
        onClose();
    };

    useEffect(() => {
        setSelectedItems(initialValue);
    }, [initialValue]);

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="selection-dialog-title">
            <DialogTitle id="selection-dialog-title" sx={{fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}>{title}</DialogTitle>
            <DialogContent>
                <List>
                    {React.Children.map(children, (child) => {
                        if (React.isValidElement(child)) {
                            return (
                                <ListItemButton
                                    onClick={handleToggle(child.key as string)}
                                    dense
                                >
                                    <Checkbox
                                        edge="start"
                                        checked={selectedItems.indexOf(child.key as string) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                    <ListItemText primary={child.props.children} />
                                </ListItemButton>
                            );
                        }
                        return null;
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" sx={{fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}>
                    {t('Cancel')}
                </Button>
                <Button onClick={handleConfirm} color="primary" sx={{fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}>
                    {t('Ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalInput;
