import React from "react";
import {Avatar, Box, Card, CardContent, Chip, Typography, useTheme} from "@mui/material";
import {ContentStats} from "../../pages/users/profile/OverviewPage";
import {styled} from "@mui/material/styles";
import {
    AudioFileTwoTone, VideoFileTwoTone, PagesTwoTone, ImageTwoTone, FolderTwoTone,
} from "@mui/icons-material";
import {useDb} from "../../contexts/DbContext";

interface ContentsStatsCardProps {
    contentStats: ContentStats;
    sx?:object;
    OnSelected?: (id:string) => void;
}

const RootWrapper = styled(Card)(
    ({ theme }) => `
    background: ${theme.colors.alpha.black[100]};
    color: ${theme.colors.alpha.white[100]};
`
);

const AvatarStatItem = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
`
);

const TypographySecondary = styled(Typography)(
    ({ theme }) => `
      color: ${theme.colors.alpha.white[70]};
`
);

export const ContentStatsCard: React.FC<ContentsStatsCardProps>=({contentStats, sx={}, OnSelected})=>{

    const theme = useTheme();
    const {t} = useDb();

    return (

        <Box sx={sx}>

            <RootWrapper
                sx={{
                    p: 2
                }}
                onClick={(e)=>{
                    if (OnSelected){
                        OnSelected('ContentStatsCard');
                    }
                }}
            >

                <Typography
                    variant="h3"
                    sx={{
                        px: { xs: 1, sm: 2 },
                        pb: 1,
                        pt: 2,
                        fontFamily: 'Oswald, serif',
                        fontSize: { xs: theme.typography.pxToRem(18), sm: theme.typography.pxToRem(23) },
                        color: `${theme.colors.alpha.white[100]}`
                    }}
                >
                    {t('Contents')}
                </Typography>

                <CardContent>
                    <Box
                        display="flex"
                        sx={{
                            px: { xs: 1, sm: 2 },
                            pb: 3
                        }}
                        alignItems="center"
                    >
                        <AvatarStatItem
                            sx={{
                                mr: { xs: 1, sm: 2 }, // Margin a destra solo su schermi più grandi
                                mb: { xs: 1, sm: 0 }, // Margin inferiore sui dispositivi piccoli
                            }}
                            variant="rounded"
                        >
                            <FolderTwoTone fontSize="large" />
                        </AvatarStatItem>
                        <Box>
                            <Typography variant="h1" sx={{fontFamily:'Oswald, serif', fontSize: { xs: '1.5rem', sm: '2rem' }}}>{contentStats
                                ?contentStats.count
                                :'n/a' }</Typography>
                            <TypographySecondary variant="subtitle2" noWrap sx={{fontFamily:'Oswald, serif'}}>
                                {t('Available Contents')}
                            </TypographySecondary>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        sx={{
                            px: { xs: 1, sm: 2 },
                            pb: 0.4
                        }}
                        alignItems="center"
                    >
                        <AvatarStatItem
                            sx={{
                                width: '36px',
                                height: '36px',
                                mr: 2
                            }}
                            variant="rounded"
                        >
                            <AudioFileTwoTone fontSize="small" />
                        </AvatarStatItem>
                        <Box>
                            <Typography variant="h4" sx={{fontFamily:'Oswald, serif'}}>{contentStats
                                ?contentStats.audio
                                :'n/a' }</Typography>
                            <TypographySecondary variant="subtitle2" noWrap sx={{fontFamily:'Oswald, serif'}}>
                                {t('Audio')}
                            </TypographySecondary>
                        </Box>

                    </Box>

                    <Box
                        display="flex"
                        sx={{
                            px: { xs: 1, sm: 2 },
                            pb: 0.4
                        }}
                        alignItems="center"
                    >
                        <AvatarStatItem
                            sx={{
                                width: '36px',
                                height: '36px',
                                mr: 2
                            }}
                            variant="rounded"
                        >
                            <VideoFileTwoTone fontSize="small" />
                        </AvatarStatItem>
                        <Box>
                            <Typography variant="h4" sx={{fontFamily:'Oswald, serif'}}>{contentStats
                                ?contentStats.video
                                :'n/a' }</Typography>
                            <TypographySecondary variant="subtitle2" noWrap sx={{fontFamily:'Oswald, serif'}}>
                                {t('Video')}
                            </TypographySecondary>
                        </Box>

                    </Box>

                    <Box
                        display="flex"
                        sx={{
                            px: { xs: 1, sm: 2 },
                            pb: 0.4
                        }}
                        alignItems="center"
                    >
                        <AvatarStatItem
                            sx={{
                                width: '36px',
                                height: '36px',
                                mr: 2
                            }}
                            variant="rounded"
                        >
                            <ImageTwoTone fontSize="small" />
                        </AvatarStatItem>
                        <Box>
                            <Typography variant="h4" sx={{fontFamily:'Oswald, serif'}}>{contentStats
                                ?contentStats.image
                                :'n/a' }</Typography>
                            <TypographySecondary variant="subtitle2" noWrap sx={{fontFamily:'Oswald, serif'}}>
                                {t('Images')}
                            </TypographySecondary>
                        </Box>

                    </Box>

                    <Box
                        display="flex"
                        sx={{
                            px: { xs: 1, sm: 2 },
                            pb: 0.4
                        }}
                        alignItems="center"
                    >
                        <AvatarStatItem
                            sx={{
                                width: '36px',
                                height: '36px',
                                mr: 2
                            }}
                            variant="rounded"
                        >
                            <PagesTwoTone fontSize="small" />
                        </AvatarStatItem>
                        <Box>
                            <Typography variant="h4" sx={{fontFamily:'Oswald, serif'}}>{contentStats
                                ?contentStats.doc
                                :'n/a' }</Typography>
                            <TypographySecondary variant="subtitle2" noWrap sx={{fontFamily:'Oswald, serif'}}>
                                {t('Documents')}
                            </TypographySecondary>
                        </Box>

                    </Box>

                </CardContent>

            </RootWrapper>

        </Box>

    );
}
