import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Helmet} from "react-helmet-async";
import {useDb} from "../../../contexts/DbContext";
import {useParams} from "react-router";
import {Button, Card, CardContent, CardHeader, Typography} from "@mui/material";
import CardActions from "@mui/material/CardActions";
import {useNavigate} from "react-router-dom";
import {AxiosError} from "axios";
import {applyDeleteToken} from "../../../services/userService";
import {logout, refreshTokenIfNeeded} from "../../../services/authService";
import {useAuth} from "../../../contexts/AuthContext";

export const DeletedPage: React.FC = () => {

    const {t} = useDb();
    const {me} = useAuth();
    const {id} = useParams();
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const getErrorMessage = (error : AxiosError)=>{
        let errorMessage: string | null = null;
        const errorNum = error.response?.status;
        const simpleData =  error.response?.data as {
            status: number;
            detail: string;
        }
        const detailErr = simpleData?.detail;

        if (detailErr) {
            return detailErr;
        }

        switch (errorNum) {
            case 403:
                errorMessage = t('Unknown Profile');
                break;
            case 401:
                errorMessage = t('The user is unknown or the password is incorrect.');
                break;
            default:
                errorMessage = t("An issue happened at backend") +" (" + errorNum + ")";
                break;
        }

        return errorMessage;
    }

    useEffect(() => {

        const loadBackenData = async (token:string)=>{

            try{
                await refreshTokenIfNeeded();
                await applyDeleteToken(token);
                await logout();
                setSuccess(true);
            }
            catch(error){
                setErrorMessage(t(getErrorMessage(error)));
                setSuccess(false);
            }
        }

        loadBackenData(id);

    }, [id]);

    return (
        <Box sx={{
            background: 'url(/static/images/home/subscription-background.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            display: 'flex',
            flexDirection: 'column',
            gap:2,
            height: "100%", width: '100%',
        }}>

            <Helmet>
                <title>The Specialist - {t('Bye Bye!') + ' ' + me?.firstname}</title>
            </Helmet>

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100vw',
                height: '100vh',
                padding: {xs:4,md:0}
            }}>
                {success ? (
                    <Card sx={{minWidth: {xs:'300px', sm:'400px'}}}>
                        <CardHeader
                            title={
                                <Typography gutterBottom variant="h3" fontFamily={'Oswald,sans-serif'} textAlign={'center'}>
                                    {t('Account Deleted')}
                                </Typography>
                            }
                        />

                        <CardContent sx={{display:'flex', flexGrow: 0, justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
                            <Typography gutterBottom fontFamily={'Oswald,sans-serif'} textAlign={'center'} marginTop={4} fontSize={18} >
                                {me
                                    ?me.firstname + ' ,' + t('your account has been deleted. We hope to see you again in The Specialist!')
                                    :t('Your account has been deleted. We hope to see you again in The Specialist!')}
                            </Typography>
                        </CardContent>
                        <CardActions>

                            <Box sx={{display: 'flex', flexDirection: 'row', alignItems:'center' ,justifyContent: 'center', width: '100%', mt:4}}>

                                <Button
                                    size="large"
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                    color="primary"
                                    sx={{mt: 3, mb: 2, mr: 2, ml: 2,fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}
                                    onClick={(e) => {
                                        navigate('/',{replace:true});
                                    }}
                                >
                                    {t('Ok')}
                                </Button>

                            </Box>
                        </CardActions>
                    </Card>
                )
                :(
                        <Card sx={{minWidth: {xs:'300px', sm:'400px'}}}>
                            <CardHeader
                                title={
                                    <Typography gutterBottom variant="h3" fontFamily={'Oswald,sans-serif'} textAlign={'center'}>
                                        {t('Cannot Delete your Account')}
                                    </Typography>
                                }
                            />

                            <CardContent sx={{display:'flex', flexGrow: 0, justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
                                <Typography gutterBottom fontFamily={'Oswald,sans-serif'} textAlign={'center'} marginTop={4} fontSize={18} >
                                    {me
                                        ?me.firstname + ' ,' + t('an error occurred during deleting your account.')
                                        :t('An error occurred during deleting your account.')} : {errorMessage}
                                </Typography>
                            </CardContent>
                            <CardActions>

                                <Box sx={{display: 'flex', flexDirection: 'row', alignItems:'center' ,justifyContent: 'center', width: '100%', mt:4}}>

                                    <Button
                                        size="large"
                                        variant="contained"
                                        type="submit"
                                        fullWidth
                                        color="primary"
                                        sx={{mt: 3, mb: 2, mr: 2, ml: 2,fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}
                                        onClick={(e) => {
                                            navigate('/',{replace:true});
                                        }}
                                    >
                                        {t('Ok')}
                                    </Button>

                                </Box>
                            </CardActions>
                        </Card>
                    )}
            </Box>
        </Box>
    );
}
