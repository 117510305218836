import {User} from "../../types/User";
import {styled} from "@mui/material/styles";
import React, {useRef, useState} from "react";
import {Skill} from "../../types/Skill";
import {useDb} from "../../contexts/DbContext";
import {convertToEmoji, isNullOrUndefined, Log} from "../../utils/utils";
import ModalInput from "../ModalInput";
import {CountryItem} from "../CountryItem";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import {SkillItem} from "./SkillItem";

interface SkillsProps {

    skills?: Skill[];
    onUpdateSkillData?(arg: {skills: Skill[]}): void,

    isEditing?: boolean,
    language?: string,
    label?: string,

}

const SkillList = styled('div')(({theme}) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    listStyle: 'none',
    padding: 0,
}));

export const Skills: React.FC<SkillsProps> = ({skills=[], onUpdateSkillData=null, isEditing=false, language='en-EN', label='Edit'}) => {

    // Modal input

    const {t} = useDb();

    const [open, setOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState<Skill[]>(skills);
    const {skills : availableSkills, isLoading} = useDb();
    const innerSkills = useRef<Array<Skill>>([]);

    function handleAddSkill() {
        Log.debug('Add Skill');
        setOpen(true);
    }

    const handleClose = () => setOpen(false);

    function handleRemoveSkill(id) {
        Log.debug('Remove Skill: ' + id);
        if (innerSkills.current.length < 1) {
            return; // We need at least a country
        } else {
            innerSkills.current = innerSkills.current.filter(
                (skill) => skill.id !== id
            );
            if (onUpdateSkillData){
                onUpdateSkillData({
                    skills: innerSkills.current
                })
            }
            setSelectedItems(innerSkills.current);
        }
    }

    function handleSkillModification(items: Array<string>) {
        Log.debug('handleSkillModification:' + JSON.stringify(items));
        const newItems = items.filter((c) => !isNullOrUndefined(c));
        const skills_arr = availableSkills.filter((skill) => newItems.includes(skill.id));
        setSelectedItems(skills_arr);
        if (onUpdateSkillData){
            onUpdateSkillData({
                skills: skills_arr
            })
        }
    }

    Log.debug('SelectedItems: ->');
    Log.debug(selectedItems);

    Log.debug('skills ->');
    Log.debug(availableSkills);

    innerSkills.current = [...skills.map((skill) => {

        if (isNullOrUndefined(availableSkills))
            return null;

        const skillInfo = availableSkills.find((c) => c.id === skill.id);

        if (skillInfo) {
            return {
                id: skill.id,
                name: skillInfo.name,
            };
        } else {
            return null;
        }
    }).filter(Boolean)]; // Rimuove eventuali elementi null

    Log.debug('innerSkills ->');
    Log.debug(innerSkills.current);

    return (
        <>

            <ModalInput
                open={open}
                onClose={handleClose}
                onConfirm={handleSkillModification}
                title={t('Choose from the list')}
                multiSelect={true}
                initialValue={innerSkills.current?.map(skill => {
                    if (!isNullOrUndefined(skill)) {
                        return skill.id;
                    }
                })}>

                {availableSkills?.map((skill) =>
                    (
                        !isNullOrUndefined(skill) &&
                        <div key={skill.id}>
                            <SkillItem
                                skill={skill}
                                isEditing={false}
                                language={language}
                                onDelete={() => handleRemoveSkill(skill.id)}
                                key={skill.id}
                            />
                        </div>

                    ))
                }
            </ModalInput>

            <SkillList sx={{mt:1,mb:1, width:'100%'}}>
                {innerSkills.current.map(skill => (
                    !isNullOrUndefined(skill) &&
                    <SkillItem
                        skill={skill}
                        isEditing={isEditing}
                        language={language}
                        onDelete={() => handleRemoveSkill(skill.id)}
                        key={skill.id}
                    />
                ))}

                {isEditing && (

                    <Box>
                        <Button
                            size="medium"
                            variant="contained"
                            type="submit"
                            color="primary"
                            sx={{mt: 0, ml:2, mb: 0, fontFamily: 'Oswald, sans-serif', fontSize: {xs:'14px',md:'16px'}}}
                            onClick={handleAddSkill}
                        >
                            {label}
                        </Button>
                    </Box>

                )}
            </SkillList>



        </>
    );

}
