import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    MenuItem,
    TextField,
    IconButton,
    List,
    ListItemText,
    Typography,
    Paper, ListItemButton
} from '@mui/material';
import {Delete} from '@mui/icons-material';
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import {useAuth} from "../../contexts/AuthContext";
import {useDb} from "../../contexts/DbContext";
import {useUsers} from "../../contexts/UsersContext";
import {Profile} from "../../types/Profile";
import {useProfile} from "../../contexts/ProfileContext";
import {isNullOrUndefined} from "../../utils/utils";
import {MemberRequest} from "../../types/MemberRequest";
import ModalDialog from "../ModalDialog";
import {search, searchUsersBySkill} from "../../services/userService";
import {PagedRequest} from "../../types/PagedRequest";
import {searchSkills} from "../../services/skillService";

interface MemberListProps {
    title?: string;
    subtitle?: string;
    profileId?: string;
}

interface MemberListType {
    userId?: string;
    userName?: string;
    roleId?: string;
    roleName?: string;
}

const extractRoleName = (input: string) => {
    const match = input.match(/\(([^)]+)\)/);
    return match ? match[1] : null;
}

const MemberList: React.FC<MemberListProps> = ({ title='', subtitle='',profileId=''}) => {
    const [searchQuery, setSearchQuery] = useState(''); // Per filtrare nel primo Select
    const [firstSelectValue, setFirstSelectValue] = useState<MemberListType|null>(null); // Valore selezionato dal primo Select
    const [secondSelectValue, setSecondSelectValue] = useState(''); // Valore selezionato dal secondo Select
    const [selectedItems, setSelectedItems] = useState<{id:string;roleId: string;name:string;}[]>([]); // Lista degli elementi combinati selezionati
    const [selectedListItem, setSelectedListItem] = useState<number | null>(null); // Elemento selezionato dalla lista
    const [showDropdown, setShowDropdown] = useState(false); // Per mostrare il menu a tendina
    const [dynamicArray, setDynamicArray] = useState<MemberListType[]>([]);
    const [currentProfile,setCurrentProfile] = useState<Profile>(null);
    const [errorFirstSelect, setErrorFirstSelect] = useState<string>('');
    const [errorSecondSelect, setErrorSecondSelect] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [messageModal, setMessageModal] = useState<string>('');

    const {me}=useAuth();

    const {t,profileRoles}=useDb();

    // const {users,
    //     fetchUsers,
    //     error : errorQuery,
    //     isLoading: loadingQuery
    // } = useUsers();

    const {
        profile,
        fetchProfileById,
        addUserMemberToProfile,
        removeUserMemberFromProfile,
        error: errorProfile,
        isLoading: loadingProfile,
    } = useProfile();

    // Riferimenti per la tendina e il TextField
    const textFieldRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    // Array per il secondo Select (array fisso)
    const rolesArray = profileRoles?profileRoles:[];

    // Funzione per aggiungere elementi alla lista
    const handleAddItem = async () => {

        setErrorFirstSelect('');
        setErrorSecondSelect('');

        if (firstSelectValue && secondSelectValue) {

            const sel = selectedItems.find((item) => item.id === firstSelectValue.userId);

            if (sel){
                setShowModal(true);
                setMessageModal("L'utente " + firstSelectValue.userName + " è già un membro");
                return;
            }

            const role = rolesArray.find((role) => role.name === secondSelectValue);

            const arg : MemberRequest = {
                userId: firstSelectValue.userId,
                profileRoleId: role.id,
            }

            await addUserMemberToProfile(currentProfile.id, arg);

        }
        else if (isNullOrUndefined(firstSelectValue)) {
            setErrorFirstSelect('Questo campo è necessario');
        }
        else if (isNullOrUndefined(secondSelectValue)||secondSelectValue==='') {
            setErrorSecondSelect('Questo campo è necessario');
        }

    };

    // Funzione per eliminare un elemento selezionato
    const handleDeleteItem = async () => {
        if (selectedListItem !== null) {
            const items = selectedItems.filter((_, index) => index === selectedListItem);
            if (items && items.length>0){

                if (items.at(0).id===me?.id){
                    setShowModal(true);
                    setMessageModal("Non ti puoi eliminare dai membri del profilo");
                    return;
                }

                const role = rolesArray.find((role) => role.name === extractRoleName(items.at(0).name));

                const arg : MemberRequest = {
                    userId: items.at(0).id,
                    profileRoleId: role?role.id:'',
                }
                await removeUserMemberFromProfile(currentProfile.id, arg);
            }
            setSelectedListItem(null); // Reset della selezione
        }
    };

    // Gestisce la selezione di un elemento dal menu a tendina
    const handleSelectItem = (item: MemberListType) => {
        setFirstSelectValue(item); // Imposta il valore selezionato
        setSearchQuery(item.userName); // Aggiorna il campo di ricerca
        setShowDropdown(false); // Chiude il menu a tendina
    };

    // Funzione per chiudere la tendina cliccando fuori
    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node) &&
            !textFieldRef.current?.contains(event.target as Node)
        ) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        // Aggiunge l'event listener per rilevare i click al di fuori del TextField e della tendina
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {

        const loadPublicUser = async (s:string)=>{

            try{

                let users_arr = [];

                users_arr = await searchUsersBySkill(s);
                const arg: PagedRequest = {
                    "page":0,
                    "size":0,
                    "filter": s,
                }
                const response = await search(arg);
                if (response){
                    users_arr = users_arr.concat(response.items);
                }
                setDynamicArray(users_arr.map(
                    (user)=>{
                        return {
                            userId: user.id,
                            userName: user.firstname + " " + user.lastname,
                        }
                    })
                )

            }
            catch (err){
                setDynamicArray([]);
            }
        }

        loadPublicUser(searchQuery);

    }, [searchQuery]);

    useEffect(() => {

        const requestProfile = async (id: string)=>{
            await fetchProfileById(id);
        }

        if (isNullOrUndefined(profileId))
            return;

        requestProfile(profileId);

    }, [profileId]);

    useEffect(() => {

        setCurrentProfile(profile);
        const selected = profile?.members?.map((member)=>{
            return {
                id: member.userId,
                roleId: member?.role.id,
                name: member.firstname + " " + member.lastname + " (" + t(member.role.name) +")",
            }
        })
        setSelectedItems(selected);
        setFirstSelectValue(null);
        setSecondSelectValue('');
        setSearchQuery(''); // Resetta il campo di ricerca

    }, [profile]);

    return (
        <Box maxWidth='500px'>

            <ModalDialog title={t('Error')} label={messageModal} buttonLabel={t('Ok')} onClose={()=>{setShowModal(false)}} open={showModal}/>

            <Box sx={{ display: 'flex', flexDirection: 'column' , maxWidth: '500px',mb:4}}>

                {/* Titolo e Sottotitolo */}
                <Typography variant="h4" sx={{fontFamily:'Oswald',textAlign:'center'}}>
                    {title}
                </Typography>
                <Typography variant="subtitle1" sx={{fontFamily:'Oswald',textAlign:'center'}}>
                    {subtitle}
                </Typography>

            </Box>

            {/* Sezione con il TextField per la query dinamica, secondo Select e il pulsante + */}
            <Box
                display="flex"
                alignItems="center"
                mt={2}
                gap={2}
                flexDirection={{xs: 'column', sm: 'row'}}
                position="relative"
                maxWidth='500px'>

                {/* TextField con query dinamica */}
                <TextField
                    InputProps={{
                        style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                    }}
                    InputLabelProps={{
                        style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                    }}
                    FormHelperTextProps={{
                        style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                    }}
                    ref={textFieldRef}
                    label={t('Search or select user')}
                    required
                    value={searchQuery}
                    onChange={(e) => {
                        setErrorFirstSelect('');
                        setFirstSelectValue(null); // Annulla la precedente scelta
                        setSearchQuery(e.target.value); // Aggiorna la query con il testo digitato
                        setShowDropdown(true); // Mostra il menu a tendina quando si inizia a digitare
                    }}
                    fullWidth
                    error={errorFirstSelect!==''}
                    helperText={errorFirstSelect}
                />

                {/* Dropdown dinamico, appare quando ci sono risultati filtrati */}
                {showDropdown && (
                    <Paper
                        ref={dropdownRef}
                        elevation={3}
                        sx={{
                            position: 'absolute',
                            top: '100%',
                            left: '5px',
                            width: textFieldRef.current?.offsetWidth*0.98, // Imposta la larghezza della tendina uguale a quella del TextField
                            zIndex: 10,
                            maxHeight: 200,
                            overflowY: 'auto',
                            borderRadius: 0,
                        }}
                    >
                        {dynamicArray
                            .filter((item) => item.userName.toLowerCase().includes(searchQuery.toLowerCase()))
                            .map((item) => (
                                <MenuItem key={item.userId} onClick={() => handleSelectItem(item)}>
                                    {t(item.userName)}
                                </MenuItem>
                            ))}
                    </Paper>
                )}

                {/* Secondo Select fisso */}
                <TextField
                    InputProps={{
                        style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                    }}
                    InputLabelProps={{
                        style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                    }}
                    FormHelperTextProps={{
                        style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                    }}
                    select
                    required
                    value={secondSelectValue}
                    onChange={(e) => {
                        setErrorSecondSelect('');
                        setSecondSelectValue(e.target.value);
                    }}
                    label={t('Role')}
                    sx={{width: {xs:'100%', sm:'50%'}}}
                    fullWidth
                    error={errorSecondSelect!==''}
                    helperText={errorSecondSelect}
                >
                    <MenuItem sx={{fontFamily: 'Oswald, sans-serif', fontSize: { xs: '14px', sm: '16px' }}} value="" disabled>
                        {t('Select role')}
                    </MenuItem>
                    {rolesArray.map((role) => (
                        <MenuItem key={role.id} value={role.name} sx={{fontFamily: 'Oswald, sans-serif', fontSize: { xs: '14px', sm: '16px' }}}>
                            {t(role.name)}
                        </MenuItem>
                    ))}
                </TextField>

                {/* Pulsante circolare + */}
                <IconButton
                    sx={{
                        alignSelf: {xs:'flex-end'},
                        borderRadius: '50%',  // Fa sì che il bottone sia circolare
                        width: 40,            // Imposta la larghezza del cerchio
                        height: 40,           // Imposta l'altezza del cerchio
                        backgroundColor: '#1976d2', // Colore di sfondo
                        color: 'white',       // Colore dell'icona
                        '&:hover': {
                            backgroundColor: '#1565c0', // Colore di sfondo al passaggio del mouse
                        },
                    }}
                    onClick={handleAddItem}>
                    <AddTwoToneIcon/>
                </IconButton>

            </Box>

            {/* Lista selezionata e pulsante delete */}
            <Box mt={2} display="flex" flexDirection="column" alignItems="start">

                <Box
                    sx={{
                        width: '100%',
                        maxWidth: 500,
                        padding: 0,
                        overflow: 'hidden',
                    }}
                >

                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'start'}}>
                        <Typography variant="h5" sx={{mt:1, fontFamily: 'Oswald', fontSize: { xs: '16px', sm: '16px' }}}>
                            {t('Selected Members')}
                        </Typography>
                        <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'end', mb:1}}>
                            {/* Pulsante delete */}
                            <IconButton
                                onClick={handleDeleteItem}
                                color="secondary"
                                disabled={selectedListItem === null}
                            >
                                <Delete/>
                            </IconButton>
                        </Box>
                    </Box>

                    {/* Lista degli elementi selezionati */}
                    <List sx={{ maxHeight: 150, overflowY: 'auto',border: '1px, solid',borderColor: '', borderRadius: 1, minHeight: 150 }}>
                        {selectedItems?.map((item, index) => (
                            <ListItemButton
                                key={index}
                                selected={selectedListItem === index}
                                onClick={() => setSelectedListItem(index)}
                            >
                                <ListItemText primary={item.name} primaryTypographyProps={{fontFamily: 'Oswald', fontSize: { xs: '16px', sm: '16px' }}} />
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
            </Box>
        </Box>
    );
};

export default MemberList;
