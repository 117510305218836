import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import Box from "@mui/material/Box";

interface ModalDialogProps {
    id?: string;
    label?: string;
    title?: string;
    buttonLabel?: string;
    cancelButtonLabel?: string;
    open: boolean; // Stato che controlla l'apertura
    onClose: () => void;// Funzione di chiusura
    onCancel?: () => void; // Funzione di cancellazione
}

const ModalDialog: React.FC<ModalDialogProps> = ({ id='a',
                                                     label,
                                                     title,
                                                     buttonLabel='OK',
                                                     cancelButtonLabel = null,
                                                     open,
                                                     onClose,
                                                     onCancel = null}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="modal-dialog-title"
            aria-describedby="modal-dialog-description"
        >
            <DialogTitle id={`${id}-modal-dialog-title`} sx={{fontFamily: 'Oswald, sans-serif'}}>{title}</DialogTitle>
            <DialogContent>
                <Typography id={`${id}-modal-dialog-description`} sx={{fontFamily: 'Oswald, sans-serif'}}>
                    {label}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                    <Button onClick={onClose} color="primary" autoFocus sx={{fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}>
                        {buttonLabel}
                    </Button>
                    {cancelButtonLabel && (<Button onClick={onCancel} color="primary" autoFocus sx={{fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}>
                        {cancelButtonLabel}
                    </Button>)}
                </Box>

            </DialogActions>
        </Dialog>
    );
};

export default ModalDialog;
