import React, {createContext, useContext, useReducer} from "react";
import {PagedRequest} from "../types/PagedRequest";
import {getTraces} from "../services/traceService";
import {Log} from "../utils/utils";
import {refreshTokenIfNeeded} from "../services/authService";
import {AxiosError} from "axios";

const TraceContext = createContext(null);

const initialState = {
    traces: [],
    isLoaded: false,
    error: null
}

function reducer(state, action) {
    switch (action.type) {
        case 'loading':
            return {
                ...state,
                isLoading: true,
                error: null,
            }

        case 'got-traces-paged':
            return {
                ...state,
                isLoading: false,
                traces: action.payload,
            }

        case 'rejected':
            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }

        default:
            throw new Error('Unknown action type')

    }
}

function TraceProvider({children}: { children: React.ReactNode }) {
    const [{
        traces,
        isLoading,
        error
    }, dispatch] = useReducer(reducer, initialState);

    async function fetchPagedTraces(request: PagedRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getTraces(request);
            dispatch({type: 'got-traces-paged', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Traces, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    return <TraceContext.Provider value={{
        traces,
        isLoading,
        fetchPagedTraces,
        error
    }}>{children}</TraceContext.Provider>

}

function useTrace() {
    const context = useContext(TraceContext);
    if (context === undefined) {
        throw new Error('useTrace must be used within a TraceProvider');
    }
    return context;
}

export {TraceProvider, useTrace};
