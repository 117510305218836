import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

import React, {useMemo} from "react";
import Box from "@mui/material/Box";
import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Log} from "../../utils/utils";

const CardCover = styled(Card)(
    ({theme}) => `
    position: relative;

    .MuiCardMedia-root {
      height: ${theme.spacing(26)};
    }
`
);

export interface PreviewProps {
    style?: object,
    doc?: any,
    docs?: Array<any>,
    isPrivate?: boolean,
}

export const Preview: React.FC<PreviewProps> = ({
                                                    style = {maxHeight: 100, maxWidth: 100},
                                                    docs = [{uri: ''}],
                                                    doc,
                                                    isPrivate = false
                                                }) => {

    const token = sessionStorage.getItem("bearer");
    const bearer = token?
        JSON.parse(token).bearer
        : '';

    const headers = useMemo(() => ({
        "Authorization": `Bearer ${bearer}`,
    }), [bearer]);

    Log.debug(docs);
    Log.debug(doc);

    return (
        <Box display="flex" flexDirection="column" alignItems='center'>
            {!isPrivate && (
                <DocViewer
                    prefetchMethod="GET"
                    requestHeaders={headers}
                    activeDocument={doc}
                    style={style}
                    initialActiveDocument={doc}

                    config={{
                        loadingRenderer: {
                            showLoadingTimeout:3000,
                        },
                        header: {
                            disableHeader: true,
                            disableFileName: true,
                            retainURLParams: false,
                        },
                        csvDelimiter: ",", // "," as default,
                        pdfZoom: {
                            defaultZoom: 1.1, // 1 as default,
                            zoomJump: 0.2, // 0.1 as default,
                        },
                        pdfVerticalScrollByDefault: false, // false as default
                    }}
                    documents={docs}
                    pluginRenderers={[...DocViewerRenderers]}/>
            )
            }
        </Box>
    );
}
