import React from 'react';
import { Dialog, DialogContent, DialogActions, Button} from '@mui/material';

interface ModalItemProps {
    children: React.ReactNode;
    buttonLabel?: string;
    open: boolean; // Stato che controlla l'apertura
    onClose: () => void;// Funzione di chiusura
}

const ModalItem: React.FC<ModalItemProps> = ({ buttonLabel='OK', open, onClose, children }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="modal-dialog-title"
            aria-describedby="modal-dialog-description"
        >
            <DialogContent>
                    {children}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={onClose} color="primary" autoFocus sx={{fontFamily: 'Oswald, sans-serif', fontSize: '16px',mb:2,mr:2}}>
                    {buttonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalItem;
