import {Box, Link, Typography, styled, alpha} from '@mui/material';
import React from "react";

const FooterWrapper = styled(Box)(
    ({theme}) => `
        background-color: ${alpha(theme.header.background, 0.0)};
        display: 'flex';
        justify-content: space-between;
        align-items: center;
        bottom: 32px;
        margin-left: ${theme.spacing(5)};
        left: 0;
        width: 95%;
        padding: ${theme.spacing(1)};
        
         @media (max-width: ${theme.breakpoints.values.sm}px) {
            flex-direction: column;
            align-items: flex-start;
            padding: ${theme.spacing(1, 2)};
            bottom: 16px;
        }
`
);

const PageFooter: React.FC = () => {
// function Footer(showTicker = true) {
    return (
        <FooterWrapper className="footer-wrapper">
            <Box
                pb={0} pt={2}
                display={'flex'}
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                justifyContent="space-between"
                width="100%"
            >
                <Typography variant="h4" sx={{ fontFamily: 'Oswald, serif', textAlign: { xs: 'center', sm: 'left' } }}>
                    &copy; 2024 - TheSpecialist
                </Typography>
                <Typography
                    sx={{
                        pt: { xs: 0, sm: 0 },
                        fontFamily: 'Oswald, serif',
                        textAlign: { xs: 'center', sm: 'right' }
                    }}
                    variant="subtitle1"
                >
                    Crafted by{' '}
                    <Link
                        href="https://kakama.eu"
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="h4"
                        sx={{ fontFamily: 'Oswald, serif' }}
                    >
                        kakama.eu
                    </Link>
                </Typography>
            </Box>
        </FooterWrapper>

    );
}

export default PageFooter;
