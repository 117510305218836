import {User} from "../../types/User";
import {Content} from "../../types/Content";
import React, {useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Grid2";
import {Preview} from "../Preview";
import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useContent} from "../../contexts/ContentContext";
import {useAuth} from "../../contexts/AuthContext";
import {useUsers} from "../../contexts/UsersContext";
import {getContentMetadata, isNullOrUndefined, Log} from "../../utils/utils";
import {ContentItem} from "./ContentItem";
import SelectList from "../SelectList";
import {ContentMetadata} from "../../types/ContentMetadata";
import ScrollableHtml from "../ScrollableHtml";
import ImageCard from "../ImageCard";
import CardMedia from "@mui/material/CardMedia";
import {refreshTokenIfNeeded} from "../../services/authService";
import {getContentsByUser, getUserById} from "../../services/userService";
import Profile from "../../pages/profile";
import {ContentMediaCard} from "../ContentMediaCard";
import {useDb} from "../../contexts/DbContext";

interface ContentSelectionProps {
    userId?: string,
    initialValues?: Content[],
    onUpdateContentData?(arg: { userId: string, contents: Content[] }): void,
    isEditing?: boolean,
    language?: string,
    label?: string,
}

const ContentList = styled('div')(({theme}) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    listStyle: 'none',
    padding: 0,
}));

export const ContentSelection: React.FC<ContentSelectionProps> = ({userId='', onUpdateContentData=null, isEditing=false, language='en-EN', label='Edit',initialValues=[]})=>{

    const {t} = useDb();
    const {me,refreshMe} = useAuth();
    const {media,getMediaForUser} = useContent();
    const [currentUser,setCurrentUser]=useState<User>(null);
    const [currentMedia,setCurrentMedia]= useState<ContentMetadata[]>([]);
    const userContents = useRef<Array<{id: string; meta: ContentMetadata;}>>();
    const [availableContents, setAvailableContents] = useState<Content[]>([]);
    const [selectedContents,setSelectedContents]=useState<Content[]>([]);
    const [selectedContent, setSelectedContent]=useState<ContentMetadata>(null);
    const [previewContent, setPreviewContent]=useState<ContentMetadata>(null);


    const isEdit = (isEditing && userId === me?.id);

    useEffect(() => {

        const loadBackendData = async (id: string)=>{

            try{
                await refreshTokenIfNeeded();

                if (!me){
                    await refreshMe();
                }

                const user = await getUserById(id);
                setCurrentUser(user);
                const contents = await getContentsByUser(id);
                setAvailableContents(contents);
                await getMediaForUser(id,true);
            }
            catch(error) {
                Log.error(error.message);
            }
        }

        loadBackendData(userId);

    }, [userId]);


    useEffect(() => {
        if (media){
            setCurrentMedia(Array.from(media.entries()).map((item)=>item[1]));
            Log.debug('====MEDIA===')
            Log.debug(media);
        }
    }, [media]);

    const handleChangedItems = (items: Array<string>)=>{
        const newItems = items.filter((c) => !isNullOrUndefined(c));
        const contents_arr = availableContents.filter((content)=>newItems.includes(content.id));
        setSelectedContents(contents_arr);
        if (onUpdateContentData){
            onUpdateContentData({
                userId: currentUser.id,
                contents: contents_arr
            });
        }
    }

    userContents.current = [...initialValues.map((content) => {

        const contentInfo = availableContents.find((c) => c.id === content.id);

        if (contentInfo) {
            const res = {id: contentInfo.id, meta: getContentMetadata(contentInfo)};
            Log.debug(res);
            return res;
        } else {
            Log.debug('======USER CONTENT NULL ========');
            return null;
        }
    }).filter(Boolean)];

    const handleChangedSelectedItem = (item)=>{
        Log.debug(item);
        setSelectedContent(currentMedia.find((med)=>med.id === item));
    }

    const handleChangedPreviewItem = (item)=>{
        Log.debug(item);
        setPreviewContent(currentMedia.find((med)=>med.id === item));
    }

    Log.debug('=====AVAILABLE CONTENTS=======');
    Log.debug(availableContents);
    Log.debug(currentUser);
    Log.debug(userContents);
    Log.debug(selectedContent);

    return (
        <Card sx={{ flexGrow: 1, padding: 2, maxWidth: { xs: '100%', md: '100%' }, margin: { xs: '0 auto', md: 'inherit' } }}>
            <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Oswald, serif', mb: 2, fontSize: { xs: '1rem', md: '1.5rem' } }}>
                {t('Contents')}
            </Typography>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12, md: 12}}>
                        {availableContents.length > 0 && (
                            <SelectList
                                onChanged={handleChangedItems}
                                onItemHover={handleChangedPreviewItem}
                                onItemSelected={handleChangedSelectedItem}
                                multiSelect={true}
                                initialValue={userContents.current?.map(content => {
                                    Log.debug('===initialValue===')
                                    Log.debug(content);
                                    if (content) {
                                        return content.id;
                                    }
                                })}>
                                {availableContents?.map((content) =>
                                    (
                                        !isNullOrUndefined(content) &&
                                        <div key={content.id}>
                                            <ContentItem
                                                meta={getContentMetadata(content)}
                                                language={language}
                                            />
                                        </div>
                                    ))
                                }
                            </SelectList>
                        )}
                        {availableContents.length === 0 && (
                            <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Oswald, serif', mb: 2, fontSize: { xs: '0,5rem', md: '1rem' } }}>
                                {t('No Contents Available')}
                            </Typography>
                        )}
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <Typography variant="h4" gutterBottom sx={{fontFamily: 'Oswald, serif', mt:-2}}>
                            {previewContent?.name}
                        </Typography>
                        {availableContents.length > 0 && previewContent?.address?.uri && (
                            <ContentMediaCard
                                media={previewContent}
                                contentType={availableContents.find((item)=>previewContent.id===item.id)?.contentType}
                                sx={{
                                    width: { xs: '100%', md: '200px' },
                                    height: { xs: 'auto', md: '200px' },
                                    backgroundColor: "var(--surface-100)",
                                    mt: 1
                                }}
                                onClick={
                                (content)=>Log.debug(content)
                            }/>
                        )}
                        {availableContents.length > 0 && previewContent?.html && previewContent?.html!=='' && (
                            <ScrollableHtml
                                htmlContent={previewContent.html}
                                marginTop={'10px'}
                                width={'200px'} height={'200px'}/>
                        )}
                        {availableContents.length > 0 && previewContent?.note && previewContent?.note!=='' && (
                            <ScrollableHtml
                                htmlContent={previewContent.note}
                                marginTop={'10px'}
                                width={'200px'} height={'200px'}/>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
