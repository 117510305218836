import {Validation, ValidationType, Validator} from "../../../utils/validate";
import React, {useEffect, useReducer, useRef, useState} from "react";
import {Profile} from "../../../types/Profile";
import Box from "@mui/material/Box";
import {ProfileType} from "../../../types/ProfileType";
import {AxiosError} from "axios";
import {MenuItem, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import {useDb} from "../../../contexts/DbContext";
import Button from "@mui/material/Button";
import {ProfileCreateRequest} from "../../../types/ProfileCreateRequest";
import {useAuth} from "../../../contexts/AuthContext";
import {
    addMemberToProfile,
    addSkillToProfile,
    createProfile,
    deleteProfile, getProfileById, removeMemberFromProfile,
    removeSkillFromProfile,
    updateProfile
} from "../../../services/profileService";
import TransferList, {TransferListItem} from "../../../components/TransferList";
import MemberList from "../../../components/MemberList";
import {ProfileAuthorizeRequest} from "../../../types/ProfileAuthorizeRequest";
import ModalDialog from "../../../components/ModalDialog";
import {MemberRequest} from "../../../types/MemberRequest";
import {ProfileUpdateRequest} from "../../../types/ProfileUpdateRequest";
import {Log, T} from "../../../utils/utils";
import {refreshTokenIfNeeded} from "../../../services/authService";

interface ProfilePageProps {
    profileId?: string;
    OnAbort?: () => void;
    OnSuccess?: () => void;
}

const emptyProfile : Profile = {
    id: '',
    profileType: null,
    name: '',
    created: new Date(),
    updated: new Date(),
    members: [],
    skills: []
}

interface Field {
    value?: string | Array<string> | ProfileType | boolean;
    error?: string;
}

interface FormFields {

    profileName?: Field;
    profileType?: Field;
    members?: Field;
    skills?: Field;
}

interface FieldValidation {
    profileName: Validator[];
    profileType: Validator[];
    members: Validator[];
    skills: Validator[];
}

const t =  async (message) =>{
    return await T(message);
}

const updateFormField = (obj: FormFields, key: string, validators: Validator[], value: string | Array<string> | boolean, validate = false) => {
    const errors: Validator[] = Validation(value, validators).errors;

    // const ll = {
    //     ...obj,
    //     [key]: {
    //         value,
    //         error: validate
    //             ? (errors.length > 0 ? errors[0].message : '')
    //             : '',
    //     }
    // };
    //
    // Log.debug(ll);

    return ({
        ...obj,
        [key]: {
            value,
            error: validate
                ? (errors.length > 0 ? errors[0].message : '')
                : '',
        }
    })
}

// Funzione per ottenere i messaggi tradotti
const initializeFormValidation = async (): Promise<FieldValidation> => {
    const profileNameMessage = await t('Profile Name field is required');
    const profileTypeMessage = await t('Profile Type is required');
    const membersMessage = await t('At least a profile member is required');
    const skillsMessage = await t('At least a profile skill is required');

    return {
        profileName: [
            {
                validatorType: ValidationType.REQUIRED,
                message: profileNameMessage
            },
        ],
        profileType: [
            {
                validatorType: ValidationType.REQUIRED,
                message:  profileTypeMessage
            },
        ],
        members: [
            {
                validatorType: ValidationType.REQUIRED,
                message: membersMessage
            },
            {
                validatorType: ValidationType.GREATER,
                message: membersMessage,
                props: {
                    greater: 0,
                }
            },
        ],
        skills: [
            {
                validatorType: ValidationType.REQUIRED,
                message: skillsMessage
            },
            {
                validatorType: ValidationType.GREATER,
                message: skillsMessage,
                props: {
                    greater: 0,
                }
            },
        ]
    };
};

const getValidator = (formValidation: FieldValidation, id: string) => {

    switch (id) {
        case 'profileName':
            return formValidation.profileName;
        case 'profileType':
            return formValidation.profileType;
        case 'members':
            return formValidation.members;
        case 'skills':
            return formValidation.skills;
        default:
            throw new Error('Unknown id')
    }
}

const getErrorMessage = async (error : AxiosError)=>{
    let errorMessage: string | null = null;
    const errorNum = error.response?.status;
    const simpleData =  error.response?.data as {
        status: number;
        detail: string;
    }
    const detailErr = simpleData?.detail;

    if (detailErr) {
        return detailErr;
    }

    switch (errorNum) {
        case 403:
            errorMessage = await t('Unknown Profile');
            break;
        case 401:
            errorMessage = await t('The user is unknown or the password is incorrect.');
            break;
        default:
            errorMessage = await t("An issue happened at backend") +" (" + errorNum + ")";
            break;
    }

    return errorMessage;
}

const initialState = {
    processStep: 1,
    request: {},
    formValidation: null,
    processError: null,
}

function reducer(state, action) {

    switch (action.type) {

        case 'initialize-form-validation':
            return {
                ...state,
                formValidation: action.payload,
            };

        case 'update-fields':
            return {
                ...state,
                processError: null,
                request: updateFormField(state.request, action.payload.id, getValidator(state.formValidation, action.payload.id), action.payload.value, false),
            }

        case 'next-step':
            return {
                ...state,
                processError: null,
                processStep: (state.processStep < 3 ? state.processStep + 1 : state.processStep),
            }

        case 'prev-step':
            return {
                ...state,
                processError: null,
                processStep: (state.processStep > 1 ? state.processStep - 1 : state.processStep),
            }

        case 'got-errors':
            return {
                ...state,
                processError: null,
                request: updateFormField(state.request, action.payload.id, getValidator(state.formValidation, action.payload.id), action.payload.value, true),
            }

        case 'got-process-error':
            return {
                ...state,
                processError: action.payload,
            }

        default:
            throw new Error('Unknown action type')
    }

}

export const EditProfilePage: React.FC<ProfilePageProps>=({profileId='',OnAbort=null, OnSuccess=null})=>{

    const {me} = useAuth();

    const {
        profileTypes,
        skills,
        t,
    } = useDb();

    const [editedInfo,setEditedInfo]=useState<boolean>(false);
    const [currentProfile, setCurrentProfile] = useState<Profile>(emptyProfile);
    const [availableSkills, setAvailableSkills] = useState<TransferListItem[]>(skills?.map(skill=>({
        id: skill.id,
        name: skill.name,
    })));
    const [selectedSkills, setSelectedSkills] = useState<TransferListItem[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [messageModal, setMessageModal] = useState<string>('');
    const oldProfileRef = useRef<Profile>(null);
    const editProfileRef = useRef<boolean>(profileId && profileId!=='');

    const [{
        processStep,
        request,
        processError,
        formValidation,
    }, dispatch] = useReducer(reducer, initialState);

    // Funzione asincrona che inizializza `formValidation` e chiama il `reducer`
    const initializeAndDispatch = async (dispatch: any) => {
        const formValidation = await initializeFormValidation();

        dispatch({
            type: 'initialize-form-validation',
            payload: formValidation,
        });
    };

    const restoreOldProfile = async () => {

        try{

            // Restore old skills

            const currentSkills = currentProfile?.skills;
            const old = oldProfileRef.current.skills;

            const old_skills: string[] = [...old.map(item => item.id)];
            const new_skills: string[] = currentSkills
                ?[...currentSkills.map(item=>item.id)]
                :[];

            //deleted

            // calcola le skill da eliminare
            const deleted = new_skills.filter(item=>!old_skills.includes(item));
            // calcola le skill da aggiungere
            const added = old_skills.filter(item=>!new_skills.includes(item));

            for (const item of deleted){
                const arg : ProfileAuthorizeRequest = {
                    profileId: currentProfile?.id,
                    skillId: item,
                }

                await removeSkillFromProfile(arg);
            }

            for (const item of added){
                const arg : ProfileAuthorizeRequest = {
                    profileId: currentProfile?.id,
                    skillId: item,
                }
                await addSkillToProfile(arg);
            }

            // Restore old members

            const currentMembers = currentProfile?.members;
            const oldMembers = oldProfileRef.current.members;

            const old_members: string[] = [...oldMembers.map(item => item.userId)];
            const new_members: string[] = currentMembers
                ?[...currentMembers.map(item=>item.userId)]
                :[];

            // calcola i Member da eliminare
            const deletedMembers = new_members.filter(item=>!old_members.includes(item));
            // calcola i Member da aggiungere
            const addedMembers = old_members.filter(item=>!new_members.includes(item));

            for (const item of deletedMembers){
                const arg : MemberRequest = {
                   userId: item,
                   profileRoleId:  currentMembers.find(member=>member.userId===item).role.id
                }

                await removeMemberFromProfile(currentProfile.id,arg);
            }

            for (const item of addedMembers){
                const arg : MemberRequest = {
                    userId: item,
                    profileRoleId:  oldMembers.find(member=>member.userId===item).role.id
                }
                await addMemberToProfile(currentProfile.id,arg);
            }

            // Restore old info
            await updateProfile(oldProfileRef.current.id,{
                name: oldProfileRef.current.name,
                profileTypeId: oldProfileRef.current.profileType.id
            });
        }
        catch (error){
            Log.error(error);
        }

    }

    const handleNextButtonClick = async () => {

        Log.debug('handleNextButtonClick: processStep is: ' + processStep);
        switch (processStep){
            case 1:
            {
                if (editProfileRef.current){

                    let current_fields : FormFields = {};
                    let modified = false;

                    if (!request?.profileName?.value){
                        current_fields = updateFormField(current_fields, 'profileName', getValidator(formValidation,'profileName'), currentProfile.name, true);
                        const err_profileName = Validation(current_fields.profileName.value, formValidation.profileName).errors;
                        if (err_profileName.length>0) {
                            dispatch({type: 'got-errors', payload: {id: "profileName", value: request?.profileName?.value}});
                            break;
                        }
                    }
                    else{
                        current_fields = {
                            ...current_fields,
                            profileName: {value: request.profileName, error: ''}
                        }
                        modified = true;
                    }

                    if (!request?.profileType?.value){
                        const profile = profileTypes.find((profile) => profile.name === currentProfile.profileType.name);
                        current_fields = updateFormField(current_fields, 'profileType', getValidator(formValidation,'profileType'), profile, true);
                        const err_profileType = Validation(current_fields.profileType.value, formValidation.profileType).errors;
                        if (err_profileType.length>0) {
                            dispatch({type: 'got-errors', payload: {id: "profileType", value: request?.profileType?.value}});
                            break;
                        }
                    }
                    else {
                        current_fields = {
                            ...current_fields,
                            profileType: {value: request.profileType,error: ''}
                        }
                        modified = true;
                    }

                    if (modified){
                        try{
                            const arg: ProfileUpdateRequest = {
                                name: current_fields.profileName.value as string,
                                profileTypeId: (current_fields.profileType.value as ProfileType).id,
                            }
                            const data =  await updateProfile(profileId,arg);
                            setCurrentProfile(data);
                        }
                        catch (error){
                            const message = await getErrorMessage(error);
                            dispatch({type: 'got-process-error', payload: message});
                        }
                    }

                    dispatch({type:'next-step'});

                }
                else {

                    const err_profileName = Validation(request?.profileName?.value, formValidation.profileName).errors;
                    const err_profileType = Validation(request?.profileType?.value, formValidation.profileType).errors;
                    if (err_profileName.length>0) {
                        dispatch({type: 'got-errors', payload: {id: "profileName", value: request?.profileName?.value}});
                        break;
                    }
                    if (err_profileType.length>0) {
                        dispatch({type: 'got-errors', payload: {id: "profileType", value: request?.profileType?.value}});
                        break;
                    }
                    try{

                        const arg: ProfileCreateRequest = {
                            name: request.profileName.value,
                            profileTypeId: request.profileType.value.id,
                            email: me.email,
                        }
                        const data =  await createProfile(arg);
                        setCurrentProfile(data);
                        //setCurrentProfile(sample_profile);
                        dispatch({type: 'next-step'});

                    }
                    catch (error){
                        const message = await getErrorMessage(error);
                        dispatch({type: 'got-process-error', payload: message});
                    }

                }
            }
            break;
            case 2:
            {
                Log.debug('processStep is: ' + processStep);
                dispatch({type: 'next-step'});

            }
                break;
            case 3:
            {
                Log.debug('processStep is: ' + processStep);
                if (currentProfile.skills.length === 0){
                    dispatch({type: 'got-process-error', payload: t('At least a skill should be added')});
                }
                else{
                    if (OnSuccess){
                        OnSuccess();
                    }
                }

            }
                break;
            default:
                break;
        }
    }

    const handlePrevButtonClick = async () => {

        Log.debug('handlePrevButtonClick: processStep is: ' + processStep);
        switch(processStep){
            case 1:
                if (editProfileRef.current){
                    await restoreOldProfile();
                }
                else if (OnAbort){
                    try{
                        if (currentProfile && currentProfile.id!==''){
                            await deleteProfile(currentProfile.id);
                        }
                    }
                    catch (error){
                        Log.error(error.message);
                    }
                }
                OnAbort();
                break;
            case 2:
                if (editProfileRef.current){
                    dispatch({type: 'prev-step'});
                }
                else if (OnAbort){
                    OnAbort();
                }
                break;
            case 3:
                dispatch({type: 'prev-step'});
                break;
            default:
                break;
        }

    }

    const handleChangedSelectedSkill = async (selected:TransferListItem[])=> {

        const currentSkills = currentProfile?.skills;
        const _selected = selected
        ? selected:
        [];

        const new_skills: string[] = [..._selected.map(item => item.id)];

        const old_skills: string[] = currentSkills
            ?[...currentSkills.map(item=>item.id)]
            :[];

        // calcola le skill da eliminare
        const deleted = old_skills.filter(item=>!new_skills.includes(item));
        // calcola le skill da aggiungere
        const added = new_skills.filter(item=>!old_skills.includes(item));

        let hasErrorDeleting = false;
        let lastDeletedProfileSkillInfo : Profile = null;

        for (const item of deleted){
            const arg : ProfileAuthorizeRequest = {
                profileId: currentProfile?.id,
                skillId: item,
            }
            //await popSkillFromProfile(arg);
            try{
                lastDeletedProfileSkillInfo = await removeSkillFromProfile(arg);
            }
            catch (error){
                Log.error(error.message);
                hasErrorDeleting = true;
            }

        }

        let hasErrorAdding = false;
        let lastAddedProfileSkillInfo : Profile = null;

        for (const item of added){
            const arg : ProfileAuthorizeRequest = {
                profileId: currentProfile?.id,
                skillId: item,
            }
            //
            try{
                lastAddedProfileSkillInfo = await addSkillToProfile(arg);
            }
            catch (error){
                Log.error(error.message);
                hasErrorAdding = true;
            }

        }

        if (lastAddedProfileSkillInfo){
            setCurrentProfile(lastAddedProfileSkillInfo);
        }
        else if (lastDeletedProfileSkillInfo){
            setCurrentProfile(lastDeletedProfileSkillInfo);
        }
        else if (currentProfile.skills.length === 0){
            setShowModal(true);
            setMessageModal(t('At least a skill should be added'));
        }
        if (hasErrorAdding||hasErrorDeleting){
            setShowModal(true);
            setMessageModal(t('An error occurred modifying Profile Skill'));
        }

    }

    const handleChangeFieldProfileType = (element)=>{
        setEditedInfo(true);
        const profile = profileTypes.find((profile) => profile.name === element.target.value);
        dispatch({type: 'update-fields', payload: {id: "profileType", value: profile}})
    }

    const handleChangeFieldProfileName = (element)=>{
        setEditedInfo(true);
        dispatch({
            type: 'update-fields',
            payload: {id: "profileName", value: element.target.value}
        })
    }

    useEffect(() => {
        initializeAndDispatch(dispatch);
    }, [dispatch]);

    useEffect(() => {

        const loadBackendData = async (id: string)=>{

            if (!id || id===''){
                editProfileRef.current = false;
                return;
            }

            try{
                await refreshTokenIfNeeded();
                const profile = await getProfileById(id);
                if (!oldProfileRef.current){
                    oldProfileRef.current = profile;
                }
                editProfileRef.current = (profileId && profile.id === profileId);
                setCurrentProfile(profile);
                setSelectedSkills(profile.skills?.map((skill)=>{
                    return {
                        id: skill.id,
                        name: skill.name,
                    }
                }));

            }
            catch (error){
                const message = await getErrorMessage(error);
                setShowModal(true);
                setMessageModal(message);
            }
        }

        loadBackendData(profileId);

    }, [profileId]);

    useEffect(() => {
        if (!processError){
            return;
        }
        setShowModal(true);
        setMessageModal(processError);
    }, [processError]);

    return (
        <Box
            sx={{
                marginTop: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
            }}
        >
            {showModal &&  (<ModalDialog title={t('Error')} label={messageModal} buttonLabel={t('Ok')} onClose={
                ()=>{setShowModal(false);setMessageModal('')}} open={showModal}/>)}

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center'
            }}>

                {(processStep === 1) && (
                    <Typography variant='h5' sx={{mt: 2, fontFamily: 'Oswald, sans-serif', fontSize: { xs: '18px', sm: '24px' } }}>
                        <span>{editProfileRef.current
                            ?t('Modify the profile name')
                            :t('Choose a name and a type for your profile')}</span>
                    </Typography>
                )}

            </Box>

            <Box component="div" sx={{mt: 1, p: 1, fontFamily: 'Oswald, sans-serif', width: '100%'}}>

                {(processStep === 1) && (

                    <Box sx={{mt: 1, p: 0, fontFamily: 'Oswald, sans-serif', width: '100%'}}>

                        <TextField
                            InputProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            InputLabelProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            FormHelperTextProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="profileName"
                            label={t('Profile Name')}
                            name="profileName"
                            type="text"
                            variant="filled"
                            value={request?.profileName?.value
                                ? request?.profileName?.value
                                : currentProfile
                                ? currentProfile.name
                                : ''}
                            onBlur={(element) => handleChangeFieldProfileName(element)}
                            onChange={(element) => handleChangeFieldProfileName(element)}
                            error={!!request?.profileName?.error}
                            helperText={request?.profileName?.error}/>

                        <TextField
                            InputProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px', textAlign: 'left'} // Cambia il font
                            }}
                            InputLabelProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                            }}
                            FormHelperTextProps={{
                                style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="profileType"
                            select
                            disabled={editProfileRef.current?editProfileRef.current:false}
                            label={t('Choose a Profile Type')}
                            value={(request && request.profileType?.value)
                                ? request?.profileType.value.name
                                : currentProfile
                                    ? currentProfile.profileType
                                        ? currentProfile.profileType.name
                                        : ''
                                    :''}
                            onChange={(element) => handleChangeFieldProfileType(element)}
                            onBlur={(element) => handleChangeFieldProfileType(element)}
                            error={!!request?.profileType?.error}
                            helperText={request?.profileType?.error}
                        >
                            {profileTypes?.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                    {t(option.name)}
                                </MenuItem>
                            ))}
                        </TextField>

                    </Box>

                )}

                {(processStep === 2) && (
                    <Box sx={{mt: 2, mb: 2} }>
                        <MemberList profileId={currentProfile.id} title={`${t('Define the members of ')} ${currentProfile.name}`} />
                    </Box>
                )}

                {/*{(processStep === 3) && (<></>)}*/}
                {(processStep === 3) && (
                    <Box sx={{mt: 2, mb: 2} }>
                        <TransferList title={`${t('Define the skills of ')} ${currentProfile.name}`} leftItems={availableSkills} rightItems={selectedSkills}
                                      onSelectedItems={(selected)=>handleChangedSelectedSkill([...selected])}/>
                    </Box>
                )}

            </Box>

            {processStep < 4 && (
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>

                    <Button
                        size="large"
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="primary"
                        sx={{mt: 3, mb: 2, mr: 4, fontFamily: 'Oswald, sans-serif', fontSize: { xs: '14px', sm: '16px' }}}
                        onClick={(e) => handlePrevButtonClick()}
                    >
                        {processStep == 1 && t('Cancel')} {(processStep == 2 && editProfileRef.current) && t('Modify Profile')} {(processStep == 2 && !editProfileRef.current) && t('Cancel')} {processStep == 3 && t('Add Members')}
                    </Button>


                    <Button
                        size="large"
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="primary"
                        sx={{mt: 3, mb: 2, fontFamily: 'Oswald, sans-serif', fontSize: { xs: '14px', sm: '16px' }}}
                        onClick={(e) => handleNextButtonClick()}
                    >
                        {(processStep === 1 && !editProfileRef.current) && t('Create Profile')}
                        {(processStep === 1 && editProfileRef.current && !editedInfo) && t('Next')}
                        {(processStep === 1 && editProfileRef.current && editedInfo) && t('Modify Profile')}
                        {processStep === 2 && t('Add Skills')}
                        {processStep === 3 && t('Complete')}
                    </Button>

                </Box>
            )}

      </Box>
    );

}
