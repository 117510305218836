import React from 'react';
import sanitizeHtml from 'sanitize-html';

interface ScrollableHtmlProps {
    htmlContent: string;
    width: string;
    height: string;
    marginTop: string;
    border?: boolean;
    font?: string;
    fontSize?: string;
    padding?: number;
}

const ScrollableHtml: React.FC<ScrollableHtmlProps> = ({ htmlContent, width, height,marginTop,border=true,font='inherit',padding=0,fontSize='inherit'}) => {
    const containerStyle: React.CSSProperties = {
        width: width,
        height: height,
        marginTop: marginTop,
        overflowY: 'auto', // Abilita lo scrolling verticale
        overflowX: 'auto', // Abilita lo scrolling orizzontale (opzionale)
        border: border?'1px solid #ccc':'', // Aggiunge un bordo per evidenziare l'area scrollabile (opzionale)
        padding: padding, // Padding interno (opzionale)
        boxSizing: 'border-box', // Assicura che width/height includano padding e bordi
        fontFamily: font,
        fontSize: fontSize,
    };

    return (
        <div style={containerStyle} dangerouslySetInnerHTML={{ __html: sanitizeHtml(htmlContent) }} />
    );
};

export default ScrollableHtml;
