import { api } from '../utils/api';
import { ProfileType } from "../types/ProfileType";
import { TypeRequest } from "../types/TypeRequest";

export const getProfileTypes = async (): Promise<Array<ProfileType>> => {
    const response = await api.get(`/api/profile-types`);
    return response.data;
};

export const createProfileType = async (request: TypeRequest): Promise<ProfileType> => {
    const response = await api.post(`/api/profile-types`, request);
    return response.data;
};

export const updateProfileType = async (id: string, request: TypeRequest): Promise<number> => {
    const response = await api.patch(`/api/profile-types/${id}`, request);
    return response.data;
};

export const deleteProfileType = async (id: string): Promise<void> => {
    await api.delete(`/api/profile-types/${id}`);
};
