import { api } from '../utils/api';
import { PagedRequest } from "../types/PagedRequest";
import { PagedResponse } from "../types/PagedResponse";
import { Subscription } from "../types/Subscription";
import { SubscriptionRequest } from "../types/SubscriptionRequest";

export const getSubscriptions = async (request: PagedRequest): Promise<PagedResponse<Subscription>> => {
    const response = await api.post(`/api/subscriptions/list`, request);
    return response.data;
};

export const getSubscription = async (id: string): Promise<Subscription> => {
    const response = await api.get(`/api/subscriptions/${id}`);
    return response.data;
};

export const getMySubscriptions = async (request: PagedRequest): Promise<PagedResponse<Subscription>> => {
    const response = await api.post(`/api/subscriptions/list/me`, request);
    return response.data;
};

export const createSubscription = async (request: SubscriptionRequest): Promise<Subscription> => {
    const response = await api.post(`/api/subscriptions`, request);
    return response.data;
};

export const deleteSubscription = async (id: string): Promise<void> => {
    await api.delete(`/api/subscriptions/${id}`);
};