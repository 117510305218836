import { api } from '../utils/api';
import { ListBySkillsRequest } from "../types/ListBySkillsRequest";
import { PagedRequest } from "../types/PagedRequest";
import { PagedResponse } from "../types/PagedResponse";
import { Project } from "../types/Project";
import { ProjectCreateRequest } from "../types/ProjectCreateRequest";
import { ProjectUpdateRequest } from "../types/ProjectUpdateRequest";
import { ProjectSkillRequest } from "../types/ProjectSkillRequest";
import { ProjectContentRequest } from "../types/ProjectContentRequest";
import { Profile } from '../types/Profile';

export const getProjects = async (request: PagedRequest): Promise<PagedResponse<Project>> => {
    const response = await api.post(`/api/projects/list`, request);
    return response.data;
};

export const getUserProjects = async (): Promise<Array<Project>> => {
    const response = await api.get(`/api/projects`);
    return response.data;
};

export const getProjectsBySkills = async (request: ListBySkillsRequest): Promise<Array<Project>> => {
    const response = await api.post(`/api/projects/list/skills`, request);
    return response.data;
};

export const getProjectById = async (id: string): Promise<Project> => {
    const response = await api.get(`/api/projects/${id}`);
    return response.data;
};

export const createProject = async (request: ProjectCreateRequest): Promise<Project> => {
    const response = await api.post(`/api/projects`, request);
    return response.data;
};

export const updateProject = async (id: string, request: ProjectUpdateRequest): Promise<Project> => {
    const response = await api.patch(`/api/projects/${id}`, request);
    return response.data;
};

export const deleteProject = async (id: string): Promise<void> => {
    await api.delete(`/api/projects/${id}`);
};

export const addSkillToProject = async (request: ProjectSkillRequest): Promise<Project> => {
    const response = await api.post(`/api/projects/${request.projectId}/skills/${request.skillId}`);
    return response.data;
};

export const removeSkillFromProject = async (request: ProjectSkillRequest): Promise<Project> => {
    const response = await api.delete(`/api/projects/${request.projectId}/skills/${request.skillId}`);
    return response.data;
};

export const addContentToProject = async (request: ProjectContentRequest): Promise<Project> => {
    const response = await api.post(`/api/projects/${request.projectId}/contents/${request.contentId}`);
    return response.data;
};

export const removeContentFromProject = async (request: ProjectContentRequest): Promise<Project> => {
    const response = await api.delete(`/api/projects/${request.projectId}/contents/${request.contentId}`);
    return response.data;
};

export const getProfilesByProject = async (projectId: string): Promise<Array<Profile>> => {
    const response = await api.get(`/api/projects/${projectId}/profiles`);
    return response.data;
};
