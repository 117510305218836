import React, {createContext, useContext, useReducer} from "react";
import {ProjectCreateRequest} from "../types/ProjectCreateRequest";
import {
    addContentToProject,
    addSkillToProject,
    createProject,
    deleteProject, getProjectById,
    getProjects,
    getProjectsBySkills, getUserProjects, removeContentFromProject, removeSkillFromProject,
    updateProject
} from "../services/projectService";
import {ProjectUpdateRequest} from "../types/ProjectUpdateRequest";
import {PagedRequest} from "../types/PagedRequest";
import {ListBySkillsRequest} from "../types/ListBySkillsRequest";
import {ProjectSkillRequest} from "../types/ProjectSkillRequest";
import {ProjectContentRequest} from "../types/ProjectContentRequest";
import {getProjectsByUser, getUsers} from "../services/userService";
import {Log} from "../utils/utils";
import {refreshTokenIfNeeded} from "../services/authService";
import {AxiosError} from "axios";

const ProjectContext = createContext(null);

const initialState = {
    projects: [],
    project: null,
    isLoaded: false,
    error: null,
    count: 0
}

function reducer(state, action) {
    switch (action.type) {
        case 'loading':
            return {
                ...state,
                isLoading: true,
                error: null
            }

        case 'deleted-project':
            return {
                ...state,
                project: null,
                isLoading: false,
            }

        case 'new-project-created':
        case 'got-project':
        case 'updated-project':
        case 'new-content-added':
        case 'content-removed':
        case 'new-skill-added':
        case 'skill-removed':
            return {
                ...state,
                project: action.payload,
                isLoading: false,
            }

        case 'got-projects-paged':
            return {
                ...state,
                projects: action.payload.items,
                isLoading: false,
            }

        case 'got-projects-user':
            return {
                ...state,
                projects: action.payload,
                isLoading: false,
                error: null,
            }
            
        case 'got-projects':
            return {
                ...state,
                projects: action.payload.items,
                count: action.payload.totalCount - action.payload.items.length,
                isLoading: false,
                error: null,
            }
            
        case 'got-projects-more':
            return {
                ...state,
                projects: [...state.projects, action.payload.items],
                count: state.count - action.payload.items.length,
                isLoading: false,
                error: null,
            }

        case 'rejected':
            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }

        default:
            throw new Error('Unknown action type')

    }
}

function ProjectProvider({children}: { children: React.ReactNode }) {
    const [{
        projects,
        project,
        isLoading,
        error,
        count
    }, dispatch] = useReducer(reducer, initialState);

    async function createNewProject(request: ProjectCreateRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await createProject(request);
            dispatch({type: 'new-project-created', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Create Project, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function modifyProjectById(id: string, request: ProjectUpdateRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await updateProject(id, request);
            dispatch({type: 'updated-project', payload:data});

        } catch (error) {
            const errmsg = "Backend Error: Can't Update Project with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function removeProjectById(id: string) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            await deleteProject(id);
            dispatch({type: 'deleted-project'});
        } catch (error) {
            const errmsg = "Backend Error: Can't Remove Project with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function fetchProjectById(id: string) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getProjectById(id);
            dispatch({type: 'got-project', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Project with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function fetchPagedProjects(request: PagedRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getProjects(request);
            dispatch({type: 'got-projects-paged', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Projects, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function fetchProjectsUser(){
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getUserProjects();
            dispatch({type: 'got-projects-user', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Projects, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }
    }

    async function fetchProjectsByUser(id:string){
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getProjectsByUser(id);
            dispatch({type: 'got-projects-user', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Projects, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }
    }

    async function fetchProjects(filter: string | null, sort: string | null, descending: boolean | null){

        dispatch({type:'loading'});
        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            let request : PagedRequest = {
                page: 0,
                size: 0,
                filter : filter,
                sort: sort,
                descending: descending
            };
            let data = await getProjects(request);
            dispatch({type:'got-projects',payload:data});
            Log.debug(data);
            while (count>0){
                request = {
                    ...request,
                    page: request.page+1
                };
                data = await getProjects(request);
                dispatch({type:'got-projects-more',payload:data});
                Log.debug(data);
            }

        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }

    }

    async function fetchPagedProjectsBySkills(request: ListBySkillsRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getProjectsBySkills(request);
            dispatch({type: 'got-projects-paged', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Projects, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function addASkillToProject(request: ProjectSkillRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await addSkillToProject(request);
            dispatch({type: 'new-skill-added', payload:data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Add Skill to Project, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function removeASkillFromProject(request: ProjectSkillRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await removeSkillFromProject(request);
            dispatch({type: 'skill-removed', payload:data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Remove Skill from Project, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function addAContentToProject(request: ProjectContentRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await addContentToProject(request);
            dispatch({type: 'new-content-added', payload:data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Add Content Project, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function removeAContentFromProject(request: ProjectContentRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await removeContentFromProject(request);
            dispatch({type: 'content-removed', payload:data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Remove Content from Project, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }


    return <ProjectContext.Provider value={{
        projects,
        project,
        isLoading,
        createNewProject,
        modifyProjectById,
        removeProjectById,
        fetchProjectById,
        fetchProjectsUser,
        fetchProjects,
        fetchPagedProjects,
        fetchPagedProjectsBySkills,
        addASkillToProject,
        removeASkillFromProject,
        addAContentToProject,
        removeAContentFromProject,
        fetchProjectsByUser,
        error
    }}>{children}</ProjectContext.Provider>

}

function useProject() {
    const context = useContext(ProjectContext);
    if (context === undefined) {
        throw new Error('useProject must be used within a ProjectProvider');
    }
    return context;
}

export {ProjectProvider, useProject};
