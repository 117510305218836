import { useState, useRef } from 'react';

import {
  Box,
  Menu,
  IconButton,
  Button,
  ListItemText,
  ListItem,
  List,
  Typography, ListItemButton
} from '@mui/material';
import { styled } from '@mui/material/styles';

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import {useDb} from "../../../../contexts/DbContext";
import {ApprovalTwoTone} from "@mui/icons-material";

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};
     font-family: 'Oswald';

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const ButtonAction = styled(Button)(
    ({ theme }) => `
     background: ${theme.colors.success.main};
     color: ${theme.palette.success.contrastText};
     font-family: 'Oswald';

     &:hover {
        background: ${theme.colors.success.dark};
     }
    `
);

function BulkActions() {
  const {t} = useDb();
  const [onMenuOpen, menuOpen] = useState<boolean>(false);
  const moreRef = useRef<HTMLButtonElement | null>(null);

  const openMenu = (): void => {
    menuOpen(true);
  };

  const closeMenu = (): void => {
    menuOpen(false);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" color="text.secondary" fontFamily={'Oswald'}>
            {t('Bulk actions')}:
          </Typography>
          <ButtonAction
            sx={{ ml: 1 }}
            startIcon={<ApprovalTwoTone />}
            variant="contained"
          >
            {t('Approve')}
          </ButtonAction>

          <ButtonError
              sx={{ ml: 1 }}
              startIcon={<DeleteTwoToneIcon />}
              variant="contained"
          >
            {t('Delete')}
          </ButtonError>
        </Box>
        <IconButton
          color="primary"
          onClick={openMenu}
          ref={moreRef}
          sx={{ ml: 2, p: 1 }}
        >
          <MoreVertTwoToneIcon />
        </IconButton>
      </Box>

      <Menu
        keepMounted
        anchorEl={moreRef.current}
        open={onMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <List sx={{ p: 1 }} component="nav">
          <ListItemButton>
            <ListItemText primary={t("Bulk delete selected")} />
          </ListItemButton>
          <ListItemButton>
            <ListItemText primary={t("Bulk edit selected")} />
          </ListItemButton>
        </List>
      </Menu>
    </>
  );
}

export default BulkActions;
