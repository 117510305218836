import React, {useState} from "react";
import {
    Card,
    CardContent,
    FormControlLabel,
    Radio,
    Typography, TypographyProps
} from "@mui/material";
import {useDb} from "../../contexts/DbContext";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

interface SubscriptionSelectProps {
    onChange?: (selected: string)=>void;
}

const SubscriptionSelect: React.FC<SubscriptionSelectProps> = ({onChange}) => {

    const {
        subscriptionTypes, t
    } = useDb();

    const subscriptions = subscriptionTypes
        ?subscriptionTypes.sort((a, b) => a.order - b.order)
        :[];

    const [checkedRadio,setCheckedRadio] = useState<Array<boolean>>([]);

    const arr = new Array<boolean>(subscriptions.length).fill(false);

    const getValidity = (months: number)=>{

        if (months===12){
            return t('Yearly');
        }

        if (months===1){
            return t('Monthly');
        }

        return '';

    }

    const getPicrtureBySubId = (id?:string)=>{

        switch (id){
            case "3bb81c2b-8075-4990-b093-5883a14df0b6":
                return '/static/images/home/silver-1month.png';
            case "2decc22f-b37e-4a2d-880c-0b17b96d5266":
                return '/static/images/home/gold-1year.png';
            case "e33ca1d6-327b-4ba4-b512-7098d3777c3d":
                return '/static/images/home/gold-1month.png';
            case "d9b08708-8461-479e-99ab-f67b40f3ad46":
                return '/static/images/home/silver-1year.png';
            case "3cd6da81-dbfd-4755-8aad-a78d582e6d7e":
                return '/static/images/home/platinum-1year.png';

            default:
                return null;
        }

    }

    const handleChangeValue = (e)=>{
        const index = Number(e.target.name);
        if (!isNaN(index)){
            setCheckedRadio(arr.map((_,i)=>i === index));
            if (onChange!=null){
                onChange(subscriptions.at(index).id);
            }
        }
    }

    return (

        <Box sx={{backgroundColor: '#FFFFFF00', // Colore bianco con trasparenza (80 in HEX è equivalente a 50%)
            padding: '2'}}>
            <Grid
                container
                justifyContent="center"
                spacing={2}
            >
                {subscriptions?.map((type,index)=>(
                    <Grid
                        key={index}
                        size={{xs:12,sm:6,md:4,lg:2}}
                        display='flex'
                        justifyContent='center'>
                    <Card sx={{ maxWidth: '300px' }}>
                        <CardMedia
                            sx={{ width: '300px',
                                height: '300px',
                                objectFit: 'cover',    // Adatta l'immagine alle dimensioni mantenendo le proporzioni
                                objectPosition: 'center',  // Centra l'immagine all'interno del contenitore
                            }}
                            image={getPicrtureBySubId(type.id)}
                            title={t(type.name)}
                        />
                        <CardContent>
                            <Typography variant="h2" color="text.primary" sx={{fontFamily: 'Oswald, sans-serif', textAlign: 'center'}}>
                                {t(type.name)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{mt:1, fontFamily: 'Oswald, sans-serif'}}>
                                {t('Validity')}: {getValidity(type.months)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{fontFamily: 'Oswald, sans-serif'}}>
                                {t('Number of daily researches')}
                            </Typography>
                            <Typography variant="h3" color="text.secondary" sx={{fontFamily: 'Oswald, sans-serif', textAlign: 'center'}}>
                                {type.dailyLimit === 0
                                ? t('unlimited')
                                : type.dailyLimit}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <FormControlLabel
                                componentsProps={
                                    { typography: {fontFamily: 'Oswald, sans-serif', fontSize: '20px'} }
                                }
                                labelPlacement="start"
                                name={index.toString()}
                                checked={checkedRadio?.at(index)===true}
                                control={<Radio/>}
                                label={`${t('Cost')}: ${type.cost}€`}
                                onClick={(e)=>handleChangeValue(e)}
                            />
                        </CardActions>
                    </Card>
                    </Grid>
                    ))}
            </Grid>

        </Box>

    );
}

export default SubscriptionSelect;
