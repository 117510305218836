import React, {createContext, useContext, useReducer} from "react";
import {getCountries} from "../services/countryService";
import {
    getBrowserLanguage,
    isGreaterByOneDay,
    isNullOrUndefined, Log,
} from "../utils/utils"
import {getDataFromDb, saveDataToDb} from "../utils/db";
import {getProfileRoles} from "../services/profileRoleService";
import {getContentTypes} from "../services/contentTypeService";
import {getProfileTypes} from "../services/profileTypeService";
import {getRoles} from "../services/roleService";
import {getSubscriptionTypes} from "../services/subscriptionTypeService";
import {getAllowedProperties, getEntitiesChecksums} from "../services/utilityService";
import {getSkills} from "../services/skillService";
import axios from "axios";
import {fake_news} from "../static/user";
import {getCurrentLanguage} from "../services/languageService";
import {ita_messages} from "../static/fallback_messages";

const DbContext = createContext(null);

const initialState = {
    checksums: null,
    countries: null,
    roles: null,
    profileRoles: null,
    profileTypes: null,
    contentTypes: null,
    subscriptionTypes: null,
    skills: null,
    availableProperties: null,
    messages: null,
    isLoading: false,
    news: [],
    error: null,
}

function reducer(state, action) {

    switch (action.type) {
        case 'loading':
            return {
                ...state,
                isLoading: true,
                error: null,
            }

        case 'got-news':
            return {
                ...state,
                isLoading: false,
                error: null,
                news: action.payload.news.articles,
            }

        case 'got-news-from-be':
            return {
                ...state,
                isLoading: false,
                error: null,
                news: action.payload.articles,
            }

        case 'got-checksums':
            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: null,
                checksums: action.payload,
            }

        case 'got-countries':
            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: null,
                countries: action.payload,
            }

        case 'got-roles':
            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: null,
                roles: action.payload,
            }

        case 'got-content-types':

            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: null,
                contentTypes: action.payload,
            }

        case 'got-profile-roles':

            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: null,
                profileRoles: action.payload,
            }

        case 'got-profile-types':

            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: null,
                profileTypes: action.payload,
            }

        case 'got-subscription-types':

            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: null,
                subscriptionTypes: action.payload,
            }

        case 'got-skills':
            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: null,
                skills: action.payload,
            }

        case 'got-available-properties':
            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: null,
                availableProperties: action.payload,
            }

        case 'got-messages':
            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: null,
                messages: action.payload,
            }

        case 'rejected':
            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }

        default:
            throw new Error('Unknown action type')
    }


}

const CHECKSUMS_KEY = 'specialist-checksums';
const COUNTRIES_KEY = 'specialist-countries';
const ROLE_KEY = 'specialist-roles';
const SKILL_KEY = 'specialist-skills';
const PROFILE_ROLE_KEY = 'specialist-profile-roles';
const PROFILE_TYPE_KEY = 'specialist-profile-types';
const CONTENT_TYPE_KEY = 'specialist-content-types';
const SUBSCRIPTION_TYPE_KEY = 'specialist-subscription-types';
const AVAILABLE_PROP_KEY = 'specialist-availability-properties';
const CACHED_NEWS_KEY = 'specialist-news-cache';
const TRANSLATIONS_KEY = 'specialist-translations';

const CINEMA_NEWS_KEY = '9361db24c6e04fcdbc80299a5d945da6';

function DbProvider({children}: { children: React.ReactNode }) {

    const [{
        checksums, countries, profileRoles, profileTypes, contentTypes, roles, subscriptionTypes, skills,
        availableProperties, messages, isLoading, news, error
    }, dispatch] = useReducer(reducer, initialState);

    const currentLanguage = getBrowserLanguage();

    async function fetchCinemaNews(language = currentLanguage?.split('-')[0]) {
        dispatch({type: 'loading'});

        const today = new Date();
        const lastWeek = new Date();
        lastWeek.setDate(today.getDate() - 7); // Data di 7 giorni fa
        try {
            const data = await axios.get(
                'https://newsapi.org/v2/everything', {
                    params: {
                        q: 'cinema',
                        from: lastWeek.toISOString().split('T')[0], // Notizie degli ultimi 7 giorni
                        to: today.toISOString().split('T')[0], // Fino alla data odierna
                        language: language.toLowerCase(),
                        apiKey: CINEMA_NEWS_KEY
                    }
                }
            );
            await saveDataToDb(CACHED_NEWS_KEY, {date: today, language: language, news: data.data});
            //await saveDataToDb(CACHED_NEWS_KEY, {date: today, language: language, news: fake_news});
            Log.debug('------DATA CINEMANEWS------');
            Log.debug(data);
            dispatch({type: 'got-news-from-be', payload: data.data});
        } catch (error) {
            const errmsg = "Backend Error: Can't retrieve Cinema News, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function fetchCheckSums() {
        dispatch({type: 'loading'});

        try {
            const data = await getEntitiesChecksums();
            await saveDataToDb(CHECKSUMS_KEY, data);
            dispatch({type: 'got-checksums', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't retrieve Checksums, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }
    }

    async function fetchSubscriptionTypes() {
        dispatch({type: 'loading'});

        try {
            const data = await getSubscriptionTypes();
            //setLocalSubscriptionTypes(data);
            await saveDataToDb(SUBSCRIPTION_TYPE_KEY, data);
            dispatch({type: 'got-subscription-types', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't retrieve SubscriptionTypes, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }
    }

    async function fetchContentTypes() {
        dispatch({type: 'loading'});

        try {
            const data = await getContentTypes();
            //setLocalContentTypes(data);
            await saveDataToDb(CONTENT_TYPE_KEY, data);
            dispatch({type: 'got-content-types', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't retrieve ContentTypes, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }
    }

    async function fetchProfileRoles() {
        dispatch({type: 'loading'});

        try {
            const data = await getProfileRoles();
            //setLocalProfileRoles(data);
            await saveDataToDb(PROFILE_ROLE_KEY, data);
            dispatch({type: 'got-profile-roles', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't retrieve ProfileRoles, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }
    }

    async function fetchProfileTypes() {
        dispatch({type: 'loading'});

        try {
            const data = await getProfileTypes();
            //setLocalProfileTypes(data);
            await saveDataToDb(PROFILE_TYPE_KEY, data);
            dispatch({type: 'got-profile-types', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't retrieve ProfileTypes, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }
    }

    async function fetchCountries() {

        dispatch({type: 'loading'});

        try {
            const data = await getCountries();
            //setLocalCountries(data);
            await saveDataToDb(COUNTRIES_KEY, data);
            dispatch({type: 'got-countries', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't retrieve Countries, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function fetchRoles() {

        dispatch({type: 'loading'});

        try {
            const data = await getRoles();
            //setLocalRoles(data);
            await saveDataToDb(ROLE_KEY, data);
            dispatch({type: 'got-roles', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't retrieve Roles, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function fetchSkills() {

        dispatch({type: 'loading'});

        try {
            const data = await getSkills();
            await saveDataToDb(SKILL_KEY, data);
            dispatch({type: 'got-skills', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't retrieve Skills, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function fetchAvailableProperties() {

        dispatch({type: 'loading'});

        try {
            const data = await getAllowedProperties();
            await saveDataToDb(AVAILABLE_PROP_KEY, data);
            dispatch({type: 'got-available-properties', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't retrieve Available Props, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function fetchMessages(){
        dispatch({type: 'loading'});

        try {
            const data = await getCurrentLanguage();
            await saveDataToDb(TRANSLATIONS_KEY, data);
            dispatch({type: 'got-messages', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't retrieve Messages, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }
    }

    async function initDbRepo() {

        dispatch({type: 'loading'});

        try {

            const lastError: Array<string> = [];

            let savedChecksums = null;
            let savedCountries = null;
            let savedRoles = null;
            let savedContentTypes = null;
            let savedProfileRoles = null;
            let savedProfileTypes = null;
            let savedSubscriptionType = null;
            let savedSkills = null;
            let savedAvailableProperties = null;
            let savedCinemaNews = null;
            let savedMessages = null;

            /* Db Query */

            try {
                savedChecksums = await getDataFromDb(CHECKSUMS_KEY);
            } catch (error) {
                const errmsg = "DB Error: Can't retrieve " + {CHECKSUMS_KEY} + ", reason: " + error.message;
                lastError.push(errmsg);
            }

            try {
                savedCountries = await getDataFromDb(COUNTRIES_KEY);
            } catch (error) {
                const errmsg = "DB Error: Can't retrieve " + {COUNTRIES_KEY} + ", reason: " + error.message;
                lastError.push(errmsg);
            }

            try {
                savedRoles = await getDataFromDb(ROLE_KEY);
            } catch (error) {
                const errmsg = "DB Error: Can't retrieve " + {ROLE_KEY} + ", reason: " + error.message;
                lastError.push(errmsg);
            }

            try {
                savedContentTypes = await getDataFromDb(CONTENT_TYPE_KEY);
            } catch (error) {
                const errmsg = "DB Error: Can't retrieve " + {CONTENT_TYPE_KEY} + ", reason: " + error.message;
                lastError.push(errmsg);
            }

            try {
                savedProfileRoles = await getDataFromDb(PROFILE_ROLE_KEY);
            } catch (error) {
                const errmsg = "DB Error: Can't retrieve " + {PROFILE_ROLE_KEY} + ", reason: " + error.message;
                lastError.push(errmsg);
            }

            try {
                savedProfileTypes = await getDataFromDb(PROFILE_TYPE_KEY);
            } catch (error) {
                const errmsg = "DB Error: Can't retrieve " + {PROFILE_TYPE_KEY} + ", reason: " + error.message;
                lastError.push(errmsg);
            }

            try {
                savedSubscriptionType = await getDataFromDb(SUBSCRIPTION_TYPE_KEY);
            } catch (error) {
                const errmsg = "DB Error: Can't retrieve " + {SUBSCRIPTION_TYPE_KEY} + ", reason: " + error.message;
                lastError.push(errmsg);
            }

            try {
                savedSkills = await getDataFromDb(SKILL_KEY);
            } catch (error) {
                const errmsg = "DB Error: Can't retrieve " + {SKILL_KEY} + ", reason: " + error.message;
                lastError.push(errmsg);
            }

            try {
                savedAvailableProperties = await getDataFromDb(AVAILABLE_PROP_KEY);
            } catch (error) {
                const errmsg = "DB Error: Can't retrieve " + {AVAILABLE_PROP_KEY} + ", reason: " + error.message;
                lastError.push(errmsg);
            }

            try {
                savedCinemaNews = await getDataFromDb(CACHED_NEWS_KEY);
            } catch (error) {
                const errmsg = "DB Error: Can't retrieve " + {CACHED_NEWS_KEY} + ", reason: " + error.message;
                lastError.push(errmsg);
            }

            try {
                savedMessages = await getDataFromDb(TRANSLATIONS_KEY);
            } catch (error) {
                const errmsg = "DB Error: Can't retrieve " + {TRANSLATIONS_KEY} + ", reason: " + error.message;
                lastError.push(errmsg);
            }


            /* Db Query */

            let forceLoadCountries = false;
            let forceLoadRoles = false;
            let forceLoadContentTypes = false;
            let forceLoadProfileRoles = false;
            let forceLoadProfileTypes = false;
            let forceLoadSubscriptionTypes = false;
            let forceLoadSkills = false;
            let forceLoadAvailableProperties = false;
            let forceLoadCinemaNews = false;
            let forceLoadMessages = false;

            Log.debug('Take checksums from backend');

            try {
                const data = await getEntitiesChecksums();

                if (isNullOrUndefined(savedCinemaNews)) {
                    forceLoadCinemaNews = true;
                } else {
                    if (savedCinemaNews.date) {

                        if (isGreaterByOneDay(new Date(), savedCinemaNews.date)) {
                            forceLoadCinemaNews = true;
                        }

                    } else {
                        forceLoadCinemaNews = true;
                    }
                }

                if (isNullOrUndefined(savedChecksums)) {
                    forceLoadCountries = true;
                    forceLoadRoles = true;
                    forceLoadContentTypes = true;
                    forceLoadProfileRoles = true;
                    forceLoadProfileTypes = true;
                    forceLoadSubscriptionTypes = true;
                    forceLoadSkills = true;
                    forceLoadAvailableProperties = true;
                    forceLoadMessages = true;
                    await saveDataToDb(CHECKSUMS_KEY, data);
                    dispatch({type: 'got-checksums', payload: data});
                } else {
                    forceLoadCountries = savedChecksums.Country !== data.Country;
                    forceLoadRoles = savedChecksums.Role !== data.Role;
                    forceLoadContentTypes = savedChecksums.ContentType !== data.ContentType;
                    forceLoadProfileTypes = savedChecksums.ProfileType !== data.ProfileType;
                    forceLoadProfileRoles = savedChecksums.ProfileRole !== data.ProfileRole;
                    forceLoadSubscriptionTypes = savedChecksums.SubscriptionType !== data.SubscriptionType;
                    forceLoadSkills = savedChecksums.Skill !== data.Skill;
                    forceLoadAvailableProperties = savedChecksums.AllowedProperty !== data.AllowedProperty;
                    forceLoadMessages = savedChecksums.Message !== data.Message;

                    if (forceLoadCountries ||
                        forceLoadRoles ||
                        forceLoadProfileRoles ||
                        forceLoadProfileTypes ||
                        forceLoadSubscriptionTypes ||
                        forceLoadSkills ||
                        forceLoadAvailableProperties ||
                        forceLoadMessages) {
                        await saveDataToDb(CHECKSUMS_KEY, data);
                        dispatch({type: 'got-checksums', payload: data});
                    } else {
                        Log.debug('No update on backend data');
                        dispatch({type: 'got-checksums', payload: savedChecksums});
                    }
                }
            } catch (error) {
                const errmsg = "Backend Error: Can't retrieve Checksums, reason: " + error.message +
                    (error.response ? (" status: " + error.response.status) : "");
                lastError.push(errmsg);
            }

            try {
                Log.debug(savedMessages)
                if (isNullOrUndefined(savedMessages) || forceLoadMessages || !savedMessages?.name.includes(currentLanguage)) {
                    Log.debug('Take messages from backend');
                    const data = await getCurrentLanguage();
                    await saveDataToDb(TRANSLATIONS_KEY, data);
                    dispatch({type: 'got-messages', payload: data});
                } else {
                    Log.debug('Take messages from db');
                    dispatch({type: 'got-messages', payload: savedMessages});
                }
            } catch (error) {
                const errmsg = "Backend Error: Can't retrieve Messages, reason: " + error.message +
                    (error.response ? (" status: " + error.response.status) : "");
                lastError.push(errmsg);
            }

            try {
                if (isNullOrUndefined(savedCountries) || forceLoadCountries) {
                    Log.debug('Take countries from backend');
                    const data = await getCountries();
                    await saveDataToDb(COUNTRIES_KEY, data);
                    dispatch({type: 'got-countries', payload: data});
                } else {
                    Log.debug('Take countries from db');
                    dispatch({type: 'got-countries', payload: savedCountries});
                }
            } catch (error) {
                const errmsg = "Backend Error: Can't retrieve Countries, reason: " + error.message +
                    (error.response ? (" status: " + error.response.status) : "");
                lastError.push(errmsg);
            }

            try {
                if (isNullOrUndefined(savedRoles) || forceLoadRoles) {
                    Log.debug('Take roles from backend');
                    const data = await getRoles();
                    await saveDataToDb(ROLE_KEY, data);
                    dispatch({type: 'got-roles', payload: data});
                } else {
                    Log.debug('Take roles from db');
                    dispatch({type: 'got-roles', payload: savedRoles});
                }
            } catch (error) {
                const errmsg = "Backend Error: Can't retrieve Roles, reason: " + error.message +
                    (error.response ? (" status: " + error.response.status) : "");
                lastError.push(errmsg);
            }

            try {

                if (isNullOrUndefined(savedContentTypes) || forceLoadContentTypes) {
                    Log.debug('Take content types from backend');
                    const data = await getContentTypes();
                    await saveDataToDb(CONTENT_TYPE_KEY, data);
                    dispatch({type: 'got-content-types', payload: data});
                } else {
                    Log.debug('Take content types from db');
                    dispatch({type: 'got-content-types', payload: savedContentTypes});
                }

            } catch (error) {
                const errmsg = "Backend Error: Can't retrieve ContentTypes, reason: " + error.message +
                    (error.response ? (" status: " + error.response.status) : "");
                lastError.push(errmsg);
            }

            try {

                if (isNullOrUndefined(savedProfileRoles) || forceLoadProfileTypes) {
                    Log.debug('Take profile roles from backend');
                    const data = await getProfileRoles();
                    await saveDataToDb(PROFILE_ROLE_KEY, data);
                    dispatch({type: 'got-profile-roles', payload: data});
                } else {
                    Log.debug('Take profile roles from db');
                    dispatch({type: 'got-profile-roles', payload: savedProfileRoles});
                }

            } catch (error) {
                const errmsg = "Backend Error: Can't retrieve ProfileRoles, reason: " + error.message +
                    (error.response ? (" status: " + error.response.status) : "");
                lastError.push(errmsg);
            }

            try {

                if (isNullOrUndefined(savedProfileTypes) || forceLoadProfileTypes) {
                    Log.debug('Take profile types from backend');
                    const data = await getProfileTypes();
                    await saveDataToDb(PROFILE_TYPE_KEY, data);
                    dispatch({type: 'got-profile-types', payload: data});
                } else {
                    Log.debug('Take profile types from db');
                    dispatch({type: 'got-profile-types', payload: savedProfileTypes});
                }

            } catch (error) {
                const errmsg = "Backend Error: Can't retrieve ProfileTypes, reason: " + error.message +
                    (error.response ? (" status: " + error.response.status) : "");
                lastError.push(errmsg);
            }

            try {

                if (isNullOrUndefined(savedSubscriptionType) || forceLoadSubscriptionTypes) {
                    Log.debug('Take subscription types from backend');
                    const data = await getSubscriptionTypes();
                    await saveDataToDb(SUBSCRIPTION_TYPE_KEY, data);
                    dispatch({type: 'got-subscription-types', payload: data});
                } else {
                    Log.debug('Take subscription types from db');
                    dispatch({type: 'got-subscription-types', payload: savedSubscriptionType});
                }

            } catch (error) {
                const errmsg = "Backend Error: Can't retrieve SubscriptionTypes, reason: " + error.message +
                    (error.response ? (" status: " + error.response.status) : "");
                lastError.push(errmsg);
            }

            try {

                if (isNullOrUndefined(savedSkills) || forceLoadSkills) {
                    Log.debug('Take Skills from backend');
                    const data = await getSkills();
                    await saveDataToDb(SKILL_KEY, data);
                    dispatch({type: 'got-skills', payload: data});
                } else {
                    Log.debug('Take Skills from db');
                    dispatch({type: 'got-skills', payload: savedSkills});
                }

            } catch (error) {
                const errmsg = "Backend Error: Can't retrieve Skills, reason: " + error.message +
                    (error.response ? (" status: " + error.response.status) : "");
                lastError.push(errmsg);
            }

            try {

                if (isNullOrUndefined(savedAvailableProperties) || forceLoadAvailableProperties) {
                    Log.debug('Take Available Properties from backend');
                    const data = await getAllowedProperties();
                    await saveDataToDb(AVAILABLE_PROP_KEY, data);
                    dispatch({type: 'got-available-properties', payload: data});
                } else {
                    Log.debug('Take Available Properties from db');
                    dispatch({type: 'got-available-properties', payload: savedAvailableProperties});
                }

            } catch (error) {
                const errmsg = "Backend Error: Can't retrieve Available Properties, reason: " + error.message +
                    (error.response ? (" status: " + error.response.status) : "");
                lastError.push(errmsg);
            }

            try {

                if (isNullOrUndefined(savedCinemaNews) || forceLoadCinemaNews || savedCinemaNews?.language!==currentLanguage) {
                    Log.debug('Take Cinema News from backend');
                    await fetchCinemaNews();
                } else {
                    Log.debug('Take Cinema News from db');
                    dispatch({type: 'got-news', payload: savedCinemaNews});
                }

            } catch (error) {
                console.warn("Can't load cinema news: " + error.message);
            }

            if (lastError.length > 0) {
                dispatch({type: 'rejected', payload: lastError.join("\n")});
            }

        } catch (error) {
            dispatch({type: 'rejected', payload: error.message});
        }

    }

    function t(message:string) : string{
        if (isNullOrUndefined(messages)){
            return message;
        }
        if (isNullOrUndefined(currentLanguage)){
            return message;
        }
        if (isNullOrUndefined(message)){
            return "";
        }

        const savedMessage = messages.messages?.find((item)=>item.original===message)?.translation;

        //const savedMessage = ita_messages?.find((item)=>item.original===message)?.translation;

        return isNullOrUndefined(savedMessage)?message:savedMessage;

    }

    return <DbContext.Provider value={{
        checksums,
        countries,
        roles,
        skills,
        profileRoles,
        profileTypes,
        contentTypes,
        subscriptionTypes,
        availableProperties,
        isLoading,
        news,
        messages,
        error,
        fetchCinemaNews,
        fetchCountries,
        fetchRoles,
        fetchSubscriptionTypes,
        fetchContentTypes,
        fetchProfileRoles,
        fetchProfileTypes,
        fetchCheckSums,
        fetchSkills,
        fetchAvailableProperties,
        fetchMessages,
        initDbRepo,
        t
    }}>{children}</DbContext.Provider>;
}

function useDb() {
    const context = useContext(DbContext);
    if (context === undefined) {
        throw new Error('useDb must be used within a DbProvider');
    }
    return context;
}

export {DbProvider, useDb};
