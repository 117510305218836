import {SignIn} from "../types/SignIn";
import {UserSignInRequest} from "../types/UserSignInRequest";
import {api} from "../utils/api";
import {SignInVerify} from "../types/SignInVerify";
import {UserLoginResponse} from "../types/UserLoginResponse";

export const signIn = async (request: SignIn): Promise<void> => {
    await api.post(`/api/sign-in`, request);
};

export const signInUser = async (token: string, request: UserSignInRequest): Promise<UserLoginResponse> => {
    const response = await api.patch(`/api/sign-in/${token}`, request);
    const _bearer = { ...response.data?.bearer };
    const csrfToken = response.headers['x-csrf-token'];
    sessionStorage.setItem('bearer', JSON.stringify(_bearer));
    sessionStorage.setItem('login', Date.now().toString());
    sessionStorage.setItem('csrfToken', csrfToken);
    return response.data;
};

export const signTokenIsValid = async (token: string): Promise<SignInVerify> => {
    const response = await api.get(`/api/sign-in/${token}/verify`);
    return response.data;
};
