import React, {useEffect, useState} from "react";
import {Avatar, Button, Card, CardHeader, Divider, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import {Skill} from "../../types/Skill";
import {Member} from "../../types/Member";
import {getProfilePictureById, getUserById, getUsers} from "../../services/userService";
import {extractAllSkills, Log} from "../../utils/utils";
import {useAuth} from "../../contexts/AuthContext";
import {useDb} from "../../contexts/DbContext";

interface MembersProps {
    members: Member[];
    sx?:object;
}


async function getSkill(member: Member) {

    try{
        const user = await getUserById(member.userId);
        if (!user) {
            return null;
        }
        const skills = extractAllSkills(user);
        if (!skills || skills.length === 0) {
            return null;
        }
        return skills.at(0);
    }
    catch(error){
        Log.error(error.message);
        return null;
    }

}

const fetchImage = async (id: string): Promise<string> => {
    try {
        // Esempio di richiesta API per ottenere l'immagine per l'oggetto
        const response = await getProfilePictureById(id);
        // Converte il blob in un URL utilizzabile
        return URL.createObjectURL(response);
    } catch (error) {
        Log.error('Errore nel caricamento dell\'immagine', error);
        return '/static/images/avatars/male-2.png'; // In caso di errore, restituisci una stringa vuota o un'immagine di fallback
    }
};

export const Members: React.FC<MembersProps> = ({members,sx={}}) => {

    const {me} = useAuth();
    const {t} = useDb();

    const [skills, setSkills] = React.useState<{ [key: string]: Skill }>({});
    const [images, setImages] = React.useState<{ [key: string]: string }>({});

    useEffect(() => {
        // Funzione per caricare le immagini per tutti gli oggetti
        const loadImages = async () => {
            const imageMap: { [key: string]: string } = {};

            if (members){

                // Effettua una chiamata API per ogni oggetto per ottenere l'immagine corrispondente
                for (const member of members) {
                    imageMap[member.userId] = await fetchImage(member.userId);
                }

                setImages(imageMap); // Aggiorna lo stato delle immagini
            }

        };

        loadImages();
    }, [members]);

    useEffect(() => {
        // Funzione per caricare le skill per tutti i membri
        const loadSkills = async () => {
            const skillMap: { [key: string]: Skill } = {};

            if (members) {

                // Effettua una chiamata API per ogni oggetto per ottenere l'immagine corrispondente
                for (const member of members) {
                    skillMap[member.userId] = await getSkill(member);
                }

                setSkills(skillMap); // Aggiorna lo stato delle immagini
            }
        };

        loadSkills();
    }, [members]);

    return (

        <Box sx={{sx}}>
            <Card sx={{height: '100%'}}>
                <CardHeader
                    title={t('Members')}
                    titleTypographyProps={{fontFamily: 'Oswald,sans-serif', fontSize: { xs: '1.25rem', sm: '1.5rem' }}}/>
                <Divider/>
                <Box p={{ xs: 1, sm: 2 }}>
                    <Grid container spacing={1}>
                        {members?.map((member) => (
                            <Grid key={member.userId} size={{xs:12,sm:6}}>
                                <Box p={1} display="flex" alignItems="flex-start">
                                    <Avatar src={images[member.userId]} sx={{width: 56, height: 56}}/>
                                    <Box pl={1}>
                                        <Typography gutterBottom
                                                    variant="h5"
                                                    sx={{fontFamily: 'Oswald,sans-serif', fontSize: { xs: '1rem', sm: '1.25rem' }}}>
                                            {member.firstname + " " + member.lastname}
                                        </Typography>
                                        <Typography variant="subtitle2"
                                                    gutterBottom
                                                    sx={{ fontFamily: 'Oswald, sans-serif', fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                                            {t(member.role.name)}
                                        </Typography>
                                        <Typography color="text.primary"
                                                    sx={{ pb: 1, fontFamily: 'Oswald, sans-serif', fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                                            {t(skills[member.userId]?.name)}
                                        </Typography>
                                        {/*{(member.userId !== me?.id) && (<Button*/}
                                        {/*    variant="outlined"*/}
                                        {/*    size="small"*/}
                                        {/*    startIcon={<AddTwoToneIcon/>}*/}
                                        {/*    sx={{fontFamily: 'Oswald,sans-serif', fontSize: '16px'}}*/}
                                        {/*>*/}
                                        {/*    Follow*/}
                                        {/*</Button>)}*/}
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Card>

        </Box>

    );

}
