import React, {createContext, useContext, useReducer} from "react";
import {PagedRequest} from "../types/PagedRequest";
import {createSkill, deleteSkill, getSkillById, getSkillsPaged, searchSkills, updateSkill} from "../services/skillService";
import {TypeRequest} from "../types/TypeRequest";
import {Log} from "../utils/utils";
import {refreshTokenIfNeeded} from "../services/authService";
import {AxiosError} from "axios";

const SkillContext = createContext(null);

const initialState = {
    skills: [],
    skill: null,
    isLoaded: false,
    error: null
}

function reducer(state, action) {
    switch (action.type) {
        case 'loading':
            return {
                ...state,
                isLoading: true,
                error: null,
            }

        case 'deleted-skill':
            return {
                ...state,
                isLoading: false,
                skill: null,
            }

        case 'new-skill-created':
        case 'got-skill':
        case 'updated-skill':
            return {
                ...state,
                skill: action.payload,
                isLoading: false,
            }

        case 'got-skills-paged':
            return {
                ...state,
                skills: action.payload,
                isLoading: false,
            }

        case 'got-skills':
            return {
                ...state,
                skills: action.payload,
                isLoading: false,
            }

        case 'rejected':
            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }

        default:
            throw new Error('Unknown action type')

    }
}

function SkillProvider({children}: { children: React.ReactNode }) {
    const [{
        skills,
        skill,
        isLoading,
        error
    }, dispatch] = useReducer(reducer, initialState);

    async function createNewSkill(request: TypeRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await createSkill(request);
            dispatch({type: 'new-skill-created', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Create Skill, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function modifySkillById(id: string, request: TypeRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await updateSkill(id, request);
            dispatch({type: 'updated-skill', payload: data});

        } catch (error) {
            const errmsg = "Backend Error: Can't Update Skill with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function removeSkillById(id: string) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            await deleteSkill(id);
            dispatch({type: 'deleted-skill'});
        } catch (error) {
            const errmsg = "Backend Error: Can't Remove Skill with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function fetchSkillById(id: string) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getSkillById(id);
            dispatch({type: 'got-skill', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Skill with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    // async function fetchSkills(id: string) {
    //     dispatch({type: 'loading'});
    //
    //     try {
    //         const data = await getSkills();
    //         dispatch({type: 'got-skills',payload:
    //                 {
    //                     items: data,
    //                     totalCount: data.length,
    //                 }});
    //     } catch (error) {
    //         const errmsg = "Backend Error: Can't Get Skill with id: " + id + ", reason: " + error.message +
    //             (error.response ? (" status: " + error.response.status) : "");
    //         dispatch({type: 'rejected', payload: errmsg});
    //     }
    //
    // }

    async function fetchPagedSkills(request: PagedRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getSkillsPaged(request);
            dispatch({type: 'got-skills-paged', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Skills, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function fetchFilteredSkills(filter: string) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await searchSkills(filter);
            dispatch({type: 'got-skills',payload:
                    {
                        items: data,
                        totalCount: data.length,
                    }});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Filtered Skills, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }



    return <SkillContext.Provider value={{
        skills,
        skill,
        isLoading,
        createNewSkill,
        modifySkillById,
        removeSkillById,
        fetchPagedSkills,
        fetchFilteredSkills,
        fetchSkillById,
        error
    }}>{children}</SkillContext.Provider>

}

function useSkill() {
    const context = useContext(SkillContext);
    if (context === undefined) {
        throw new Error('useSkill must be used within a SkillProvider');
    }
    return context;
}

export {SkillProvider, useSkill};
