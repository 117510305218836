import {useParams} from "react-router";
import {useAuth} from "../../contexts/AuthContext";
import React, {forwardRef, ReactElement, Ref, useEffect, useState} from "react";
import {User} from "../../types/User";
import {Profile} from "../../types/Profile";
import {Helmet} from "react-helmet-async";
import Footer from "../../components/Footer";
import Box from "@mui/material/Box";
import {Members} from "./Members";
import {Chip, Dialog, DialogTitle, IconButton, Slide, Theme, Typography, useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Container from "@mui/material/Container";
import {ProjectStatsCard} from "../../components/ProjectStatsCard";
import {ContentStats, ProjectStats} from "../users/profile/OverviewPage";
import {getContentMetadata, getContentStats, getProjectStats, Log} from "../../utils/utils";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import {EditProfilePage} from "../users/settings/EditProfilePage";
import {styled} from "@mui/material/styles";
import {TransitionProps} from "@mui/material/transitions";
import ModalDialog from "../../components/ModalDialog";
import {PagedRequest} from "../../types/PagedRequest";
import {ContentMetadata} from "../../types/ContentMetadata";
import {ContentStatsCard} from "../../components/ContentStatsCard";
import {refreshTokenIfNeeded} from "../../services/authService";
import {getProfileById, getProjectsByProfile} from "../../services/profileService";
import {getContentsByProfileId} from "../../services/contentService";
import {useDb} from "../../contexts/DbContext";
import PageFooter from "../../components/PageFooter";

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
    () => `
    .MuiDialog-container {
        height: auto;
        display: flex;
        align-items: center; 
        justify-content: center;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const DialogTitleWrapper = styled(DialogTitle)(
    ({theme}) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(3)}
`
);

function isTheOwner(user: User, profile: Profile) {

    const profileOwner = profile.members.filter((member) => member.role.name === 'Owner');
    if (!profileOwner || profileOwner.length === 0) return false;
    const isOwner = profile?.members.filter((p) => p.userId === user?.id);
    return (isOwner && isOwner.length > 0);
}

function ProfileDetails() {

    const {id} = useParams();
    const {me} = useAuth();
    const {t} = useDb();

    const [isOwner, setIsOwner] = useState<boolean>(false);
    const [currentProfile, setCurrentProfile] = useState<Profile>(null);
    const [projectStats, setProjectStats] = useState<ProjectStats>(null);
    const [contentStats, setContentStats] = useState<ContentStats>(null);
    const [showModal, setShowModal] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [open, setOpen] = useState(false);
    const [media,setMedia] = useState<Array<ContentMetadata>>([])

    function useIsMobile() {
        // Verifica se la larghezza è inferiore al breakpoint `sm`
        const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
        return isMobile;
    }

    const handleClose = () => {
        setShowModal(false);
        setErrorMessage("");
    };

    const handleOpenAddProfileDialog = () => {
        setOpen(true);
    };

    useEffect(() => {

        const loadBackendData = async (id:string)=>{

            try{

                await refreshTokenIfNeeded();

                const profile = await getProfileById(id);
                if (profile){
                    setCurrentProfile(profile);
                    setIsOwner(isTheOwner(me, profile));

                    const arg: PagedRequest = {
                        page: 0,
                        size: 0,
                    }

                    const contents = await getContentsByProfileId(id,arg);

                    if (contents){
                        setContentStats(getContentStats(contents.items));
                        if (contents.items.length>0){
                            const newMedia = contents.items.map((content)=>{
                                return getContentMetadata(content);
                            });
                            setMedia(newMedia);
                        }
                    }

                    const projects = await getProjectsByProfile(id);

                    if (projects){
                        setProjectStats(getProjectStats(projects));
                    }
                }
            }
            catch (error){
                setShowModal(true);
                setErrorMessage(error.message);
            }
        }

        loadBackendData(id);

    }, [id,me]);


    return (
        <>
            <Helmet>
                <title> {currentProfile
                    ? currentProfile.name
                    : ''} </title>
            </Helmet>
            {showModal && (
                <ModalDialog open={showModal} onClose={handleClose} label={errorMessage} id={'error'} title={'Errore'}/>
            )}
            <DialogWrapper
                open={open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth="sm"
                fullWidth
                scroll="paper"
                onClose={handleClose}
            >
                <DialogTitleWrapper>
                    <Box>
                        <Box sx={{
                            flexGrow: 1,
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            {open && (<EditProfilePage profileId={currentProfile?.id} OnAbort={()=>setOpen(false)} OnSuccess={()=>setOpen(false)} />)}
                        </Box>
                    </Box>
                </DialogTitleWrapper>
            </DialogWrapper>
            <Container sx={{ mt: 2, px: { xs: 2, md: 0 } }} maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="stretch"
                    spacing={{ xs: 2, md: 3 }}
                >
                    <Grid size={{xs:12, md:12}}>
                        <Box
                            p={{ xs: 2, md: 3 }}
                            display="flex"
                            alignItems="start"
                            flexDirection="column"
                            justifyContent="space-between"
                        >

                            <Box display="flex" width="100%">
                                <Typography variant="h1"
                                            sx={{
                                                fontFamily: 'Oswald',
                                                fontSize: { xs: 24, md: 'h1.fontSize' }
                                            }}
                                >
                                    {currentProfile?.name}
                                </Typography>
                                <Box display="flex"
                                     justifyContent="start"
                                     sx={{display: { xs: 'flex', sm: 'flex' } }}
                                >
                                    {isOwner && (<IconButton sx={{mt: {xs:- 0.7, md: -0.1}}} onClick={() => {
                                        setOpen(true);
                                    }
                                    }>
                                        <EditTwoToneIcon fontSize={'large'}/>
                                    </IconButton>)}
                                </Box>
                            </Box>

                            <Typography variant="subtitle2"
                                        sx={{ fontFamily: 'Oswald', fontSize: { xs: 20, md: 24 } }}
                            >
                                {t(currentProfile?.profileType.name)}
                            </Typography>

                            <Box
                                sx={{
                                    py: 2,
                                    mb: 4
                                }}
                            >
                                <Box display="flex" justifyContent="start">

                                    <Typography variant="h4"
                                                color="text.primary"
                                                sx={{
                                                    fontFamily: 'Oswald, serif',
                                                    fontSize: { xs: 16, md: 18 }
                                                }}
                                    >
                                        {t('Skills')}:
                                    </Typography>

                                    {currentProfile?.skills?.map((option) => (
                                        <Chip
                                            key={option.id}
                                            sx={{
                                                mt: {xs: -0.3, md: -0.1},
                                                mr: 0.5,
                                                fontFamily: 'Oswald, serif',
                                                fontSize: { xs: 14, md: 16 }
                                            }}
                                            size="small"
                                            label={t(option.name)}
                                            color="secondary"
                                            onClick={() => Log.debug('Click')}
                                        />
                                    ))}

                                </Box>

                            </Box>
                        </Box>
                    </Grid>
                    <Grid size={{xs:12, md:12}}>
                        <Members members={currentProfile?.members}
                                 sx={{width: '100%',
                                     maxWidth: { xs: '100%', md: 400 },
                                     mx: 'auto' }}/>
                    </Grid>
                    <Grid size={{xs:12, md:4}} sx={{display: "flex", justifyContent: { xs: 'center', md: 'flex-start' } }}>
                        <ProjectStatsCard projectStats={projectStats}
                                          sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}/>
                    </Grid>
                    <Grid size={{xs:12, md:8}} sx={{display: "flex", justifyContent: 'center'}}>
                        <ContentStatsCard contentStats={contentStats}
                                          sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}/>
                    </Grid>
                </Grid>
            </Container>
            <PageFooter/>

        </>
    );
}

export default ProfileDetails;
