import React, {createContext, useContext, useReducer} from "react";
import {
    isNullOrUndefined, Log,
} from "../utils/utils"
import {ProfileCreateRequest} from "../types/ProfileCreateRequest";
import {
    addMemberToProfile,
    addSkillToProfile,
    createProfile,
    deleteProfile, disableProfile, enableProfile,
    getProfileById,
    getProfiles,
    getProfilesBySkills, removeMemberFromProfile, removeSkillFromProfile,
    updateProfile
} from "../services/profileService";
import {ProfileUpdateRequest} from "../types/ProfileUpdateRequest";
import {PagedRequest} from "../types/PagedRequest";
import {ListBySkillsRequest} from "../types/ListBySkillsRequest";
import {ProfileEnableRequest} from "../types/ProfileEnableRequest";
import {ProfileAuthorizeRequest} from "../types/ProfileAuthorizeRequest";
import {MemberRequest} from "../types/MemberRequest";
import {refreshTokenIfNeeded} from "../services/authService";
import {AxiosError} from "axios";

const ProfileContext = createContext(null);

const initialState = {
    profiles: [],
    profile: null,
    isLoaded: false,
    error: null,
    count: 0
}

function reducer(state, action) {
    switch (action.type) {
        case 'loading':
            return {
                ...state,
                isLoading: true,
                error: null,
            }

        case 'deleted-profile':
            return {
                ...state,
                isLoading: false,
                profile: null,
                error: null,
            }

        case 'got-profiles':
            return {
                ...state,
                profiles: action.payload.items,
                count: action.payload.totalCount - action.payload.items.length,
                isLoading: true,
                error: null,
            }

        case 'got-profiles-more':
            return {
                ...state,
                profiles: [...state.profiles, action.payload.items],
                count: state.count - action.payload.items.length,
                isLoading: false,
                error: null,
            }

        case 'got-profiles-paged':
            return {
                ...state,
                isLoading: false,
                profiles: action.payload,
                error: null,
            }

        case 'disabled-profile':
        case 'enabled-profile':
            return {
                ...state,
                isLoading: false,
                error: null,
            }

        case 'new-profile-created':
        case 'got-profile':
        case 'updated-profile':
        case 'added-skill-profile':
        case 'removed-skill-profile':
        case 'added-member-profile':
        case 'removed-member-profile':
            return {
                ...state,
                profile: action.payload,
                isLoading: false,
                error: null,
            }

        case 'rejected':
            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }

        default:
            throw new Error('Unknown action type')
    }
}

function ProfileProvider({children}: { children: React.ReactNode }) {
    const [{
        profiles,
        profile,
        count,
        isLoading,
        error
    }, dispatch] = useReducer(reducer, initialState);

    async function fetchPagedProfiles(request: PagedRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getProfiles(request);
            dispatch({type: 'got-profiles-paged', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Profiles, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function fetchProfiles(filter: string | null, sort: string | null, descending: boolean | null){

        dispatch({type:'loading'});
        try{
            let request : PagedRequest = {
                page: 0,
                size: 0,
                filter : filter,
                sort: sort,
                descending: descending
            };
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            let data = await getProfiles(request);
            dispatch({type:'got-profiles',payload:data});
            Log.debug(data);
            while (count>0){
                request = {
                    ...request,
                    page: request.page+1
                };
                data = await getProfiles(request);
                dispatch({type:'got-profiles-more',payload:data});
                Log.debug(data);
            }
            //const data = sample_profiles;
            //dispatch({type:'got-profiles',payload:data});

        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }

    }

    async function createNewProfile(request: ProfileCreateRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await createProfile(request);
            dispatch({type: 'new-profile-created', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Create Profile, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function fetchProfileById(id: string) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getProfileById(id);
            dispatch({type: 'got-profile', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Profile with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }
        //const data = sample_profile;
        //dispatch({type: 'got-profile', payload: data});

    }

    async function modifyProfileById(id: string, request: ProfileUpdateRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await updateProfile(id, request);
            dispatch({type: 'updated-profile',payload: data});

        } catch (error) {
            const errmsg = "Backend Error: Can't Update Profile with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function removeProfileById(id: string) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            await deleteProfile(id);
            dispatch({type: 'deleted-profile', payload: id});

        } catch (error) {
            const errmsg = "Backend Error: Can't Update Profile with id: " + profile?.id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function fetchPagedSkillRelatedProfiles(request: ListBySkillsRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getProfilesBySkills(request);
            dispatch({type: 'got-profiles-paged', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Profiles by Skills, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function enableProfileById(id: string, request: ProfileEnableRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await enableProfile(id,request);
            dispatch({type: 'enabled-profile', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Enable Profile with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function disableProfileById(id: string, request: ProfileEnableRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await disableProfile(id,request);
            dispatch({type: 'disabled-profile', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Disable Profile with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function pushSkillToProfile(request: ProfileAuthorizeRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await addSkillToProfile(request);
            dispatch({type: 'added-skill-profile', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Add Skill with id: " + request.skillId +" to Profile with id: " + request.profileId + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function popSkillFromProfile(request: ProfileAuthorizeRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await removeSkillFromProfile(request);
            dispatch({type: 'removed-skill-profile', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Remove Skill with id: " + request.skillId +" from Profile with id: " + request.profileId + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function addUserMemberToProfile(id: string, request: MemberRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await addMemberToProfile(id, request);
            dispatch({type: 'added-member-profile', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Add Member with id: " + request.userId + " with role id: " + request.profileRoleId +" to Profile with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function removeUserMemberFromProfile(id: string, request: MemberRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await removeMemberFromProfile(id, request);
            dispatch({type: 'added-member-profile', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Remove Member with id: " + request.userId + " with role id: " + request.profileRoleId +" from Profile with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }

    }

    return <ProfileContext.Provider value={{
        profiles,
        profile,
        isLoading,
        fetchProfiles,
        fetchPagedProfiles,
        createNewProfile,
        fetchProfileById,
        modifyProfileById,
        removeProfileById,
        fetchPagedSkillRelatedProfiles,
        enableProfileById,
        disableProfileById,
        pushSkillToProfile,
        popSkillFromProfile,
        addUserMemberToProfile,
        removeUserMemberFromProfile,
        error
    }}>{children}</ProfileContext.Provider>;

}

function useProfile() {
    const context = useContext(ProfileContext);
    if (context === undefined) {
        throw new Error('useProfile must be used within a ProfileProvider');
    }
    return context;
}

export {ProfileProvider, useProfile};
