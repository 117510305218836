import { BearerToken } from '../types/BearerToken';
import { PasswordResetRequest } from '../types/PasswordResetRequest';
import { api } from '../utils/api';
import { UserLoginResponse } from "../types/UserLoginResponse";
import { Log } from "../utils/utils";
import { AuthSuccessResponse } from '../types/AuthSuccessResponse';

export const isAuthenticated = () => {
    const token = JSON.parse(sessionStorage.getItem('bearer')) as BearerToken;
    const login = Number(sessionStorage.getItem('login'));
    if (token && token.expiresInMinutes && login) {
        return (login + (token.expiresInMinutes * 60 * 1000) >= Date.now());
    } else
        return false;
};

export const login = async (email: string, password: string): Promise<UserLoginResponse | null> => {
    const response = await api.post(`/api/auth/login`, { email: email, password: password }, { withCredentials: true });
    Log.debug(response.data);
    const _bearer = { ...response.data?.bearer };
    const csrfToken = response.headers['x-csrf-token'];
    sessionStorage.setItem('bearer', JSON.stringify(_bearer));
    sessionStorage.setItem('login', Date.now().toString());
    sessionStorage.setItem('csrfToken', csrfToken);
    return response.data;
};

export const getPasswordResetToken = async (email: string): Promise<void> => {
    await api.get(`/api/auth/password/reset/${email}`);
};

export const passwordReset = async (email: string, request: PasswordResetRequest): Promise<void> => {
    await api.patch(`/api/auth/password/reset/${email}`, request);
};

export const getBearerFromRefreshToken = async (oldToken: BearerToken): Promise<AuthSuccessResponse | null> => {

    if (!oldToken && !oldToken.bearer) {
        return null;
    }

    const response = await api.get(`/api/auth/bearer/refresh`, {
        headers: {
            Authorization: 'Bearer: ' + oldToken.bearer
        },
        withCredentials: true
    });
    return {
        bearerToken: response.data,
        csrfToken: response.headers['x-csrf-token']
    };
};

export const refreshTokenIfNeeded = async () => {
    try {
        const token = JSON.parse(sessionStorage.getItem('bearer')) as BearerToken;
        const login = Number(sessionStorage.getItem('login'));
        if (token && token.expiresInMinutes && login) {
            if (login + (token.expiresInMinutes * 60 * 1000) < Date.now()) {
                const data = await getBearerFromRefreshToken(token);
                Log.debug(data);
                const _token = data.bearerToken;
                const csrfToken = data.csrfToken;

                sessionStorage.setItem('bearer', JSON.stringify(_token));
                sessionStorage.setItem('csrfToken', csrfToken);
                sessionStorage.setItem('login', Date.now().toString());
            }
            return true;
        } else {
            Log.debug('No need to refresh token');
            return false;
        }
    } catch (error) {
        Log.debug('Error :' + error.message);

        return null;
    }
}

export const refreshTokenForced = async () => {
    try {
        const token = JSON.parse(sessionStorage.getItem('bearer')) as BearerToken;
        const login = Number(sessionStorage.getItem('login'));
        const data = await getBearerFromRefreshToken(token);
        Log.debug(data);
        const _token = data.bearerToken;
        const csrfToken = data.csrfToken;
        sessionStorage.setItem('bearer', JSON.stringify(_token));
        sessionStorage.setItem('login', Date.now().toString());
        sessionStorage.setItem('csrfToken', csrfToken);
        return true;
    } catch (error) {
        Log.debug('Error :' + error.message);
        try {
            await logout();
        }
        catch (errLogout) {
            Log.debug('Error :' + errLogout.message);
        }
        return null;
    }
}


export const logout = async (): Promise<void> => {
    await api.delete(`/api/auth/logout`);
    sessionStorage.removeItem('bearer');
    sessionStorage.removeItem("csrfToken");
    sessionStorage.removeItem('login');
};
