import axios, { AxiosInstance } from 'axios';
import { API_URL } from '../config';
import { ProblemDetails } from '../types/ProblemDetails';
import {BearerToken} from "../types/BearerToken";
import {Log} from "./utils";

const api: AxiosInstance = axios.create({
    baseURL: API_URL,
});

api.interceptors.request.use(
    (config) => {
        const _token = sessionStorage.getItem('bearer');
        const bearer = _token
            ?JSON.parse(_token) as BearerToken
            : null;
        if (bearer && bearer.bearer) {
            config.headers['Authorization'] = `Bearer ${bearer.bearer}`;
        }

        const csrfToken = sessionStorage.getItem('csrfToken');

        if (csrfToken) {
            config.headers['X-CSRF-Token'] = csrfToken;
        }

        config.withCredentials = true;
        
        Log.debug('used: ');
        Log.debug(bearer);

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const setupInterceptors = (setError: (error: ProblemDetails | null) => void) => {
    api.interceptors.request.use(
        (config) => {
            const _token = sessionStorage.getItem('bearer');
            const bearer = _token
                ?JSON.parse(_token) as BearerToken
                : null;
            if (bearer && bearer.bearer) {
                config.headers['Authorization'] = `Bearer ${bearer.bearer}`;
            }

            Log.debug('used: ');
            Log.debug(bearer);

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        response => response,
        error => {
            const responseData: ProblemDetails | null = error.response && error.response.data ? error.response.data : {
                type: 'https://httpstatuses.io/500', detail: 'An error has occurred', status: 500, title: 'Internal Server Error', instance: '/'
            };

            setError(responseData);

            return Promise.reject(error);
        }
    );
};

export { api, setupInterceptors };
