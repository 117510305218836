import {Typography} from "@mui/material";
import { styled } from '@mui/material/styles';
import {ProblemDetails} from "../../types/ProblemDetails";
import {Log} from "../../utils/utils";

const CountryItemWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
}));

const CountryName = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontFamily: 'Oswald, sans-serif',
    fontSize: '16px'
});

const DeleteButton = styled('button')(({ theme }) => ({
    marginLeft: theme.spacing(1),
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1.2rem',
    color: theme.palette.error.main,
}));
export interface CountryType {
    id: string,
    name : string,
    emoji : string
}

interface CountryProps{
    country: CountryType,
    isEditing: boolean,
    language: string,
    onDelete: (e:any) => void;
}

export const CountryItem: React.FC<CountryProps>=({country,isEditing,language, onDelete})=> {

    function handleClick(e){
        e.preventDefault();
        Log.debug('CountryItem handleClick');
        onDelete(e);
    }

    return (
        // <li>
        //     <Typography variant="h3" gutterBottom>
        //         <span>{country.emoji} {country.name}</span>
        //         {isEditing &&  (<button onClick={handleClick}>&times;</button>)}
        //     </Typography>
        // </li>
        <CountryItemWrapper>
            <CountryName variant="h3">
                <span>{country.emoji}</span>
                <span>{country.name}</span>
            </CountryName>
            {isEditing && (
                <DeleteButton onClick={handleClick}>&times;</DeleteButton>
            )}
        </CountryItemWrapper>
    );

}
