import Cookies from 'js-cookie';
import {User} from "../types/User";
import {Profile} from "../types/Profile";
import {Project} from "../types/Project";
import {ContentType} from "../types/ContentType";
import {Content} from "../types/Content";
import {ContentMetadata} from "../types/ContentMetadata";
import {API_URL} from "../config";
import {Theme, useMediaQuery} from "@mui/material";
import {getDataFromDb} from "./db";
import {Skill} from "../types/Skill";

export const LEVEL_LOG = 'info';

const mapLevel = new Map([['none', 0],['error', 1],['warning', 2],['info', 3],['debug', 4]]);
const mapType = new Map([['e', 1],['w', 2],['i', 3],['d', 4]]);

export interface IIndexable {
    [key: string]: unknown;
}

export function isNullOrUndefined(value: unknown | null | undefined) {
    return value === undefined || value === null;
}

export function capitalizeFirstLetter(string: string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function updateCommonProperty<T, K extends keyof T>(target: T, source: Partial<T>, key: K): void {
    if (key in source) {
        target[key] = source[key] as T[K];
    }
}

export function updateSameProperties<T>(target: T, source: Partial<T>): void {
    for (const key in source) {

        if (Object.prototype.hasOwnProperty.call(source, key) && key in target) {
            target[key as keyof T] = source[key as keyof T];
        }

        if (key.startsWith('_') && Object.prototype.hasOwnProperty.call(source, key)
            && Object.prototype.hasOwnProperty.call(target, 'properties')) {
            (target['properties'])[key.slice(1)] = source[key as keyof T];
        }

    }
}

export function getProperty(properties: { [key: string]: string; }, key: string, defaultResponse: string) {
    return isNullOrUndefined(properties[key]) ? defaultResponse : properties[key];
}

export function getPropertyOrEmpty(properties: { [key: string]: string; }, key: string) {
    return getProperty(properties, key, "");
}

export function isGreaterByOneDay(date1: Date, date2: Date): boolean {
    const millisecondsInOneDay = 24 * 60 * 60 * 1000; // Millisecondi in un giorno

    // Ottieni il timestamp di entrambe le date
    const time1 = date1.getTime();
    const time2 = date2.getTime();

    // Calcola la differenza in millisecondi
    const diffInMilliseconds = time1 - time2;

    // Confronta se la differenza è maggiore di un giorno (arrotondando per sicurezza)
    return diffInMilliseconds >= millisecondsInOneDay;
}

export function convertToEmoji(countryCode) {

    // Converti il phoneCode in stringa se è un numero
    const code = countryCode.toString();

    // Caso speciale per il codice 0 (Nazioni Unite)
    if (code === '0') {
        return '🇺🇳'; // Emoji delle Nazioni Unite
    }

    // Trova il codice alpha-2 corrispondente al prefisso telefonico
    const alpha2 = phoneCodeToAlpha2[code];

    if (!alpha2) {
        //console.warn(`Codice paese non trovato per il prefisso telefonico: ${code}`);
        return '🇺🇳'; // Bandiera bianca come fallback
    }

    // Converti il codice alpha-2 in emoji della bandiera
    return alpha2
        .toUpperCase()
        .replace(/./g, char =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
        );
}

// Funzione per salvare i dati in un file JSON
export function saveQueryResultToFile(data: any, fileName = 'query_result.json'): void {
    try {
        // Converti i dati in una stringa JSON
        const jsonString = JSON.stringify(data, null, 2);

        // Crea un oggetto Blob con il contenuto JSON
        const blob = new Blob([jsonString], {type: 'application/json'});

        // Crea un URL per il Blob
        const url = URL.createObjectURL(blob);

        // Crea un elemento <a> per il download
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        // Aggiungi l'elemento al DOM, clicca e rimuovi
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Rilascia l'URL creato
        URL.revokeObjectURL(url);

        Log.debug('File salvato con successo!');
    } catch (error) {
        Log.error('Errore durante il salvataggio del file: ' + error.message);
    }
}

export function readQueryResultFromFile(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
        if (!file) {
            reject(new Error('Nessun file fornito'));
            return;
        }

        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            try {
                const contents = e.target?.result as string;
                const data = JSON.parse(contents);
                Log.debug('File letto con successo!');
                resolve(data);
            } catch (error) {
                reject(new Error('Errore durante la lettura del file JSON'));
            }
        };
        reader.onerror = () => reject(new Error('Errore durante la lettura del file'));
        reader.readAsText(file);
    });
}

const phoneCodeToAlpha2: { [key: string]: string } = {
    '1': 'US',   // Stati Uniti
    '7': 'RU',   // Russia
    '20': 'EG',  // Egitto
    '27': 'ZA',  // Sudafrica
    '30': 'GR',  // Grecia
    '31': 'NL',  // Paesi Bassi
    '32': 'BE',  // Belgio
    '33': 'FR',  // Francia
    '34': 'ES',  // Spagna
    '36': 'HU',  // Ungheria
    '39': 'IT',  // Italia
    '40': 'RO',  // Romania
    '41': 'CH',  // Svizzera
    '43': 'AT',  // Austria
    '44': 'GB',  // Regno Unito
    '45': 'DK',  // Danimarca
    '46': 'SE',  // Svezia
    '47': 'NO',  // Norvegia
    '48': 'PL',  // Polonia
    '49': 'DE',  // Germania
    '51': 'PE',  // Perù
    '52': 'MX',  // Messico
    '54': 'AR',  // Argentina
    '55': 'BR',  // Brasile
    '56': 'CL',  // Cile
    '57': 'CO',  // Colombia
    '58': 'VE',  // Venezuela
    '60': 'MY',  // Malesia
    '61': 'AU',  // Australia
    '62': 'ID',  // Indonesia
    '63': 'PH',  // Filippine
    '64': 'NZ',  // Nuova Zelanda
    '65': 'SG',  // Singapore
    '66': 'TH',  // Thailandia
    '81': 'JP',  // Giappone
    '82': 'KR',  // Corea del Sud
    '84': 'VN',  // Vietnam
    '86': 'CN',  // Cina
    '90': 'TR',  // Turchia
    '91': 'IN',  // India
    '92': 'PK',  // Pakistan
    '93': 'AF',  // Afghanistan
    '94': 'LK',  // Sri Lanka
    '95': 'MM',  // Myanmar
    '98': 'IR',  // Iran
    '212': 'MA', // Marocco
    '213': 'DZ', // Algeria
    '216': 'TN', // Tunisia
    '218': 'LY', // Libia
    '220': 'GM', // Gambia
    '221': 'SN', // Senegal
    '222': 'MR', // Mauritania
    '223': 'ML', // Mali
    '224': 'GN', // Guinea
    '225': 'CI', // Costa d'Avorio
    '226': 'BF', // Burkina Faso
    '227': 'NE', // Niger
    '228': 'TG', // Togo
    '229': 'BJ', // Benin
    '230': 'MU', // Mauritius
    '231': 'LR', // Liberia
    '232': 'SL', // Sierra Leone
    '233': 'GH', // Ghana
    '234': 'NG', // Nigeria
    '235': 'TD', // Chad
    '236': 'CF', // Repubblica Centrafricana
    '237': 'CM', // Camerun
    '238': 'CV', // Capo Verde
    '239': 'ST', // São Tomé e Príncipe
    '240': 'GQ', // Guinea Equatoriale
    '241': 'GA', // Gabon
    '242': 'CG', // Congo
    '243': 'CD', // Repubblica Democratica del Congo
    '244': 'AO', // Angola
    '245': 'GW', // Guinea-Bissau
    '246': 'IO', // Territorio britannico dell'Oceano Indiano
    '247': 'AC', // Isola di Ascensione
    '248': 'SC', // Seychelles
    '249': 'SD', // Sudan
    '250': 'RW', // Ruanda
    '251': 'ET', // Etiopia
    '252': 'SO', // Somalia
    '253': 'DJ', // Gibuti
    '254': 'KE', // Kenya
    '255': 'TZ', // Tanzania
    '256': 'UG', // Uganda
    '257': 'BI', // Burundi
    '258': 'MZ', // Mozambico
    '260': 'ZM', // Zambia
    '261': 'MG', // Madagascar
    '262': 'RE', // Réunion
    '263': 'ZW', // Zimbabwe
    '264': 'NA', // Namibia
    '265': 'MW', // Malawi
    '266': 'LS', // Lesotho
    '267': 'BW', // Botswana
    '268': 'SZ', // Swaziland
    '269': 'KM', // Comore
    '290': 'SH', // Sant'Elena
    '291': 'ER', // Eritrea
    '297': 'AW', // Aruba
    '298': 'FO', // Isole Faroe
    '299': 'GL', // Groenlandia
    '350': 'GI', // Gibilterra
    '351': 'PT', // Portogallo
    '352': 'LU', // Lussemburgo
    '353': 'IE', // Irlanda
    '354': 'IS', // Islanda
    '355': 'AL', // Albania
    '356': 'MT', // Malta
    '357': 'CY', // Cipro
    '358': 'FI', // Finlandia
    '359': 'BG', // Bulgaria
    '370': 'LT', // Lituania
    '371': 'LV', // Lettonia
    '372': 'EE', // Estonia
    '373': 'MD', // Moldavia
    '374': 'AM', // Armenia
    '375': 'BY', // Bielorussia
    '376': 'AD', // Andorra
    '377': 'MC', // Monaco
    '378': 'SM', // San Marino
    '379': 'VA', // Città del Vaticano
    '380': 'UA', // Ucraina
    '381': 'RS', // Serbia
    '382': 'ME', // Montenegro
    '383': 'XK', // Kosovo
    '385': 'HR', // Croazia
    '386': 'SI', // Slovenia
    '387': 'BA', // Bosnia ed Erzegovina
    '389': 'MK', // Macedonia del Nord
    '420': 'CZ', // Repubblica Ceca
    '421': 'SK', // Slovacchia
    '423': 'LI', // Liechtenstein
    '500': 'FK', // Isole Falkland
    '501': 'BZ', // Belize
    '502': 'GT', // Guatemala
    '503': 'SV', // El Salvador
    '504': 'HN', // Honduras
    '505': 'NI', // Nicaragua
    '506': 'CR', // Costa Rica
    '507': 'PA', // Panama
    '508': 'PM', // Saint Pierre e Miquelon
    '509': 'HT', // Haiti
    '590': 'GP', // Guadalupa
    '591': 'BO', // Bolivia
    '592': 'GY', // Guyana
    '593': 'EC', // Ecuador
    '594': 'GF', // Guyana francese
    '595': 'PY', // Paraguay
    '596': 'MQ', // Martinica
    '597': 'SR', // Suriname
    '598': 'UY', // Uruguay
    '599': 'CW', // Curaçao
    '670': 'TL', // Timor Est
    '672': 'NF', // Isola Norfolk
    '673': 'BN', // Brunei
    '674': 'NR', // Nauru
    '675': 'PG', // Papua Nuova Guinea
    '676': 'TO', // Tonga
    '677': 'SB', // Isole Salomone
    '678': 'VU', // Vanuatu
    '679': 'FJ', // Figi
    '680': 'PW', // Palau
    '681': 'WF', // Wallis e Futuna
    '682': 'CK', // Isole Cook
    '683': 'NU', // Niue
    '685': 'WS', // Samoa
    '686': 'KI', // Kiribati
    '687': 'NC', // Nuova Caledonia
    '688': 'TV', // Tuvalu
    '689': 'PF', // Polinesia francese
    '690': 'TK', // Tokelau
    '691': 'FM', // Micronesia
    '692': 'MH', // Isole Marshall
    '850': 'KP', // Corea del Nord
    '852': 'HK', // Hong Kong
    '853': 'MO', // Macao
    '855': 'KH', // Cambogia
    '856': 'LA', // Laos
    '880': 'BD', // Bangladesh
    '886': 'TW', // Taiwan
    '960': 'MV', // Maldive
    '961': 'LB', // Libano
    '962': 'JO', // Giordania
    '963': 'SY', // Siria
    '964': 'IQ', // Iraq
    '965': 'KW', // Kuwait
    '966': 'SA', // Arabia Saudita
    '967': 'YE', // Yemen
    '968': 'OM', // Oman
    '970': 'PS', // Palestina
    '971': 'AE', // Emirati Arabi Uniti
    '972': 'IL', // Israele
    '973': 'BH', // Bahrein
    '974': 'QA', // Qatar
    '975': 'BT', // Bhutan
    '976': 'MN', // Mongolia
    '977': 'NP', // Nepal
    '992': 'TJ', // Tagikistan
    '993': 'TM', // Turkmenistan
    '994': 'AZ', // Azerbaigian
    '995': 'GE', // Georgia
    '996': 'KG', // Kirghizistan
    '998': 'UZ', // Uzbekistan
};

export function saveDataToCookie(cookieName: string, value: unknown, expiresInDay = 7) {
    try {
        const data = [{
            "id": "beec8540-49f5-46b2-aaec-6efdf0db7bbf",
            "name": "Afghanistan",
            "code": 93
        }]

        Cookies.set(cookieName, JSON.stringify(data), {expires: expiresInDay});
        return true;
    } catch (err) {
        Log.error('Failed to save data to cookie ' + cookieName + ' reason:' + err.message);
        return false;
    }

}

export function loadDataFromCookie(cookieName: string) {
    try {
        return JSON.parse(Cookies.get(cookieName));
    } catch (err) {
        Log.error('Failed to read data from cookie ' + cookieName + ' reason:' + err.message);
        return null;
    }
}

export function extractAllSkills(user: User) {
    return user.profiles.flatMap(profile => profile.skills || []);
}

export function getProfileStats(profiles: Array<Profile>) {

    if (!profiles || profiles.length === 0) {
        return {
            count: 0,
            members: 0,
            skills: [],
        };
    }

    const uniqueMembers = new Set<string>(); // Per tenere traccia degli ID dei membri unici
    const uniqueSkills = new Set<string>();  // Per tenere traccia dei nomi delle skill uniche

    profiles?.forEach(obj => {
        // Aggiungi tutti i membri all'insieme
        obj.members.forEach(member => uniqueMembers.add(member.userId));

        // Aggiungi tutte le skill all'insieme
        obj.skills.forEach(skill => uniqueSkills.add(skill.name));
    });

    return {
        count: profiles.length, // Numero di oggetti nell'array
        members: uniqueMembers.size, // Numero di membri unici
        skills: Array.from(uniqueSkills) // Tutti i nomi delle skill uniche
    };
}

export function getProjectStats(projects: Array<Project>) {

    if (!projects) {
        return {
            count: 0,
            contents: 0,
            skills: [],
        };
    }

    const uniqueContents = new Set<string>(); // Per tenere traccia degli ID dei contenuti unici
    const uniqueSkills = new Set<string>();  // Per tenere traccia dei nomi delle skill uniche

    projects?.forEach(obj => {
        // Aggiungi tutti i membri all'insieme
        obj.contents.forEach(content => uniqueContents.add(content.id));

        // Aggiungi tutte le skill all'insieme
        obj.skills.forEach(skill => uniqueSkills.add(skill.name));
    });

    return {
        count: projects.length, // Numero di oggetti nell'array
        contents: uniqueContents.size, // Numero di contenuti unici
        skills: Array.from(uniqueSkills) // Tutti i nomi delle skill uniche
    };
}

export function getContentStats(contents: Array<Content>) {

    if (!contents) {
        return {
            count: 0,
            audio: 0,
            video: 0,
            image: 0,
            doc: 0,
        }
    }

    const audio = contents.filter((content)=>{
        return content.fileInfo && (getMimeTypeFromExtension(content.fileInfo.contentType.substring(1)) as string).includes('audio');
    })

    const video = contents.filter((content)=>{
        return content.fileInfo && (getMimeTypeFromExtension(content.fileInfo.contentType.substring(1)) as string).includes('video');
    })

    const images = contents.filter((content)=>{
        return content.fileInfo && (getMimeTypeFromExtension(content.fileInfo.contentType.substring(1)) as string).includes('image');
    })

    return {
        count: contents.length,
        audio: audio.length,
        video: video.length,
        image: images.length,
        doc: contents.length-audio.length-video.length-images.length,
    }

}

export function getContentMetadata(data: Content){

    if (!data){
        return null;
    }

    if (!data.text){
        return null;
    }
    Log.debug(data);

    if (data.download){
        //const meta = getTempAddressForFile(data.id);
        return {
            name: data.text,
            id: data.id,
            address: {
                uri: API_URL + `/api/contents/${data.id}/download`,
                fileType : getMimeTypeFromExtension(data.fileInfo.contentType.substring(1)),
                filename : data.fileInfo.filename,
                origin: "internal",
            },
            html:'',
            note:''
        }
    }

    if (data.text.includes('~T~')){
        const arr = data.text.split('~T~');
        if (arr.length == 1){
            const m : ContentMetadata = {
                name: arr.at(0),
                id:data.id,
                address:{},
                html: '',
                note: ''
            }
            Log.debug('==text1==');
            Log.debug(m);

            return m;
        }
        if (arr.length == 2){
            const m : ContentMetadata = {
                name: arr.at(0),
                id:data.id,
                address:{},
                html: '',
                note: arr.at(1)
            }
            Log.debug('==text2==');
            Log.debug(m);

            return m;
        }
    }

    if (data.text.includes('~F~')){
        const arr = data.text.split('~F~');
        if (arr.length == 1){
            const m : ContentMetadata = {
                name: arr.at(0),
                id:data.id,
                address:{},
                html: '',
                note: ''
            }
            Log.debug('==link1==');
            Log.debug(m);

            return m;
        }
        if (arr.length == 2){
            const m : ContentMetadata = {
                name: arr.at(0),
                id:data.id,
                address:{
                    uri: arr.at(1),
                    fileType :  arr.at(1).includes('www.youtube.com')?"video":"generic",
                    origin: arr.at(1).includes('www.youtube.com')?"youtube":"generic",
                },
                html: '',
                note: ''
            }

            Log.debug('==link2==');
            Log.debug(m);

            return m;
        }
    }

    if (data.text.includes('~H~')){
        const arr = data.text.split('~H~');
        if (arr.length == 1){
            const m : ContentMetadata = {
                name: arr.at(0),
                id:data.id,
                address:{},
                html: '',
                note: ''
            }
            Log.debug('==html1==');
            Log.debug(m);

            return m;
        }
        if (arr.length == 2){
            const m : ContentMetadata = {
                name: arr.at(0),
                id:data.id,
                address:{},
                html: arr.at(1),
                note: ''
            }

            Log.debug('==html2==');
            Log.debug(m);

            return m;
        }
    }

    return {};
}

export function getFileTypeFromContentType(type: ContentType) {
    if (!type) {
        return 'application/json';
    }
    switch (type.name) {
        case 'Text':
            return 'text/plain';
        case 'ProfilePicture':
        case 'Image':
        case 'CoverPicture':
            return "image/*";
        case 'Html':
            return 'text/html';
        case 'Document':
            return 'application/pdf';
        default:
            return 'application/json';
    }
}

export function getMimeTypeFromExtension(extension: string): string {
    const mimeTypes: { [key: string]: string } = {
        'html': 'text/html',
        'css': 'text/css',
        'js': 'application/javascript',
        'json': 'application/json',
        'png': 'image/png',
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'gif': 'image/gif',
        'svg': 'image/svg+xml',
        'pdf': 'application/pdf',
        'txt': 'text/plain',
        'xml': 'application/xml',
        'zip': 'application/zip',
        'mp3': 'audio/mpeg',
        'mp4': 'video/mp4',
        // Add other
    };
    const ext = extension.toLowerCase();
    return mimeTypes[ext] || 'application/octet-stream'; // Valore predefinito per file sconosciuti
}

export class Log {

    static error (message: any, args: any[]=null): void {
        if (mapType.get('e') <= mapLevel.get(LEVEL_LOG)){
            console.error(message, args);
        }
    }

    static debug (message: any, args: any[]=null): void {
        if (mapType.get('d') <= mapLevel.get(LEVEL_LOG)){
            console.debug(message,args);
        }
    }

    static warn (message: any, args: any[]=null): void {
        if (mapType.get('w') <= mapLevel.get(LEVEL_LOG)){
            console.warn(message, args);
        }
    }

    static info (message: any, args: any[]=null): void {
        if (mapType.get('i') <= mapLevel.get(LEVEL_LOG)){
            if (args){
                console.info(message,args);
            }
            else{
                console.info(message);
            }

        }
    }

}

export async function T(message: string) : Promise<string>{

    const TRANSLATIONS_KEY = 'specialist-translations';

    try {

        const storedValue: string | null = localStorage.getItem('local');
        const currentLanguage = (storedValue ? JSON.parse(storedValue ?? "{}")
            : "en-EN");

        const messages = await getDataFromDb(TRANSLATIONS_KEY);

        if (isNullOrUndefined(messages)){
            return message;
        }
        if (isNullOrUndefined(currentLanguage)){
            return message;
        }
        if (isNullOrUndefined(message)){
            return "";
        }

        const savedMessage = messages.messages?.find((item)=>item.original===message)?.translation;

        return isNullOrUndefined(savedMessage)?message:savedMessage;

    } catch (error) {
        const errmsg = "DB Error: Can't retrieve " + {TRANSLATIONS_KEY} + ", reason: " + error.message;
        Log.error(errmsg);
        return message;
    }
}

export function getUniqueSkills(skills:Skill[]){
    const uniqueSkills = new Set<string>();  // Per tenere traccia dei nomi delle skill uniche

    skills.forEach(skill => uniqueSkills.add(skill.name));
    return Array.from(uniqueSkills);
}

export function getBrowserLanguage() {
    const storedValue: string | null = localStorage.getItem('local');
    return (storedValue ? JSON.parse(storedValue ?? "{}")
        : "en-EN");
}

export const isOwner = (profile: Profile, me: User)=>{
    if (!profile){
        return false;
    }
    const members = profile?.members;
    if (members && members.length>0){
        const me_owner = members.find((member) => member.userId === me?.id &&
            member.role.name==='Owner');
        if (me_owner){
            return true;
        }
    }
    return false;
}
