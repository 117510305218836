import { get, set } from 'idb-keyval';

async function saveDataToDb(key,val){
    await set(key, val);
}

async function getDataFromDb(key){
    return await get(key);
}

export {saveDataToDb,getDataFromDb}
