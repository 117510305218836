import React from 'react';
import {
    styled,
    Box,
    alpha,
} from '@mui/material';
import { Link } from 'react-router-dom';
import HeaderSearch from "../../layouts/SidebarLayout/Header/Buttons/Search";
import Container from "@mui/material/Container";
import Text from '../Text'
import HeaderLogin from "../../layouts/SidebarLayout/Header/Buttons/Login";

interface HeaderProps {
    isLoggedIn: boolean;
    userName?: string;
    logoSrc?: string;
}

const HeaderWrapper = styled(Box)(
    ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 1)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.0)};
        align-items: center;
        display: flex;
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: 0;
            width: auto;
        }
        @media (max-width: ${theme.breakpoints.values.sm}px) {
            padding: ${theme.spacing(0, 0.5)};
        }
`
);

const NewsWrapper = styled(Container)(
    ({ theme }) => `
        position: absolute;
        bottom: 0;
        width: 100%;
`
);

const Logo = styled("img")(
    ({ theme }) => `
        height: 40px;
        @media (max-width: ${theme.breakpoints.values.sm}px) {
            height: 30px;
        }
`
);

const HeaderContent = styled(Box)(
    ({ theme }) => `
        display: flex;
        align-items: center;

        @media (max-width: ${theme.breakpoints.values.sm}px) {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }
`
);

const Header: React.FC<HeaderProps> = ({ isLoggedIn, userName, logoSrc }) => {

    return (
        <HeaderWrapper
            display="flex"
            alignItems="center"
            >

            <Link to="/">
                <Logo src={logoSrc} alt="The Specialist Logo" />
            </Link>

            <Box sx={{ display: { xs: 'flex', sm: 'block' } }}>
                <HeaderSearch fontSize="large" />
                <HeaderLogin />
            </Box>

        </HeaderWrapper>

    );

};

export default Header;
