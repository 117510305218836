import React from 'react';
import {Button, Typography, Box, Stack} from '@mui/material';
import { Link } from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet-async";
import {useDb} from "../contexts/DbContext";

interface HomePageProps {
    isLoggedIn: boolean;
}

const HomePage: React.FC<HomePageProps> = ({ isLoggedIn }) => {

    const {t} = useDb();

    return (
        <>
            <Helmet>
                <title>The Specialist - Home</title>
            </Helmet>

            {/* Header */}
            <Header isLoggedIn={isLoggedIn} logoSrc={'/static/images/logo/thespecialist1.png'} />

            {/* Hero Section */}
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    minHeight: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    textAlign: 'center',
                    backgroundImage: {
                        xs: 'url(/static/images/home/home-background-dark.png)',  // Immagine per mobile
                        md: 'url(/static/images/home/home-background-dark.png)', // Immagine per desktop
                    },
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: {
                        xs: 'contain',
                        md: 'cover',
                    },
                    backgroundPosition: {
                        xs: 'center 80%',
                        md: 'center top',
                    },
                }}
            >
                {/* Contenuto sopra l'immagine */}
                <Stack
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    sx={{
                        flexGrow: 1,
                        mt: { xs: -10, md: -20 },
                        ml: { xs: 0, md: 12 },
                        textAlign: 'center',
                        px: { xs: 2, md: 0 },
                    }}
                >
                    <Typography
                        variant="h4"
                        component="h1"
                        gutterBottom
                        sx={{
                            fontFamily: 'Oswald, sans-serif',
                            fontSize: { xs: '1.75rem', md: '2.5rem' },
                        }}
                    >
                        {t('Find the best Movie Industry Professionals')}{/*Trova i migliori professionisti del cinema*/}
                    </Typography>
                    <Typography
                        variant="h6"
                        component="p"
                        gutterBottom
                        sx={{
                            fontFamily: 'Oswald, sans-serif',
                            mt: 2,
                            fontSize: { xs: '1.2rem', md: '1.5rem' },
                        }}
                    >
                        {t('Your platform to connect with best talents outside')}
                        {/*La tua piattaforma per connetterti con i migliori talenti del cinema.*/}
                    </Typography>
                    <Button
                        variant="text"
                        color="inherit"
                        size="large"
                        component={Link}
                        to={"/register"}
                        sx={{
                            fontFamily: 'Oswald, sans-serif',
                            padding: 2,
                            mt: 2,
                        }}
                    >
                        <Typography
                            variant="h5"
                            component="p"
                            sx={{
                                fontFamily: 'Oswald, sans-serif',
                                fontSize: { xs: '1.5rem', md: '2rem' },
                            }}
                        >
                            {t('Start Now')}{/*Inizia Ora*/}
                        </Typography>
                    </Button>
                </Stack>
            </Box>

            {/* Footer */}
            <Footer/>
        </>
    );
};

export default HomePage;
