import React, { useState, useEffect } from 'react';
import Ticker from 'react-ticker'
import axios from 'axios';
import icon from './news-icon.png';
import {Typography} from "@mui/material";
import {fake_news} from "../../static/user";
import {useDb} from "../../contexts/DbContext";
import {isNullOrUndefined} from "../../utils/utils";
import Box from "@mui/material/Box";

const CinemaNews = () => {

    const GetNewsFromAPI = ()=>{

        const {news} = useDb();
        const [mNews, setMNews] = useState([]);

        useEffect(() => {

            if (isNullOrUndefined(news))
                return;

            setMNews(()=>news.map((article)=>{

                const articleDate = new Date(article.publishedAt);
                const dateString = articleDate.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
                const timeString = articleDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

                return (
                    {
                        imageUrl: article.urlToImage,
                        text: `[${dateString} - ${timeString}] ${article.title} (${article.source.name})`,
                        url: article.url
                    }
                );

            }));
        }, [news]);

        return (mNews.length>0) ? (
            <p style={{ fontSize:22 ,fontFamily: 'Oswald, sans-serif'}}>
                {mNews.map((article, index) => (
                    <React.Fragment key={index}>
                        <a href={article.url} target="_blank" rel="noopener noreferrer" style={{ margin: '0 5px', display: 'inline-flex', alignItems: 'center', verticalAlign: 'middle' }}>
                            <img
                                src={article.imageUrl}
                                alt={icon}
                                style={{ width: '40px', height: '40px', marginRight: '5px', objectFit: 'contain' }}
                            />
                            <span>{article.text}</span>
                        </a>
                        {index < mNews.length - 1 && ' +++ '}
                    </React.Fragment>
                ))}
                +++
            </p>
        ) : (
            <p style={{ visibility: "hidden" }}>Placeholder</p>
        );

    }

    return (
        <div className="newsticker">
            <Ticker offset={'run-in'} speed={12}>
                {()=><GetNewsFromAPI/>}
            </Ticker>
        </div>
)
    ;

};

export default CinemaNews;
