import React, {useState} from "react";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useLocation} from "react-router-dom";
import {StripePaymentElementOptions} from "@stripe/stripe-js";
import {Button, Card, CardContent, CardHeader, Typography} from "@mui/material";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import {useDb} from "../../../contexts/DbContext";
import {SubscriptionType} from "../../../types/SubscriptionType";

interface CheckoutFormProps {
    subscriptionId?: string;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({subscriptionId = ''}) => {
    const {t, subscriptionTypes,} = useDb();
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();

    const getAbsoluteBaseUrl = () => {
        return `${window.location.protocol}//${window.location.host}`;
    };

    const subscription : SubscriptionType|null = subscriptionTypes
        ?subscriptionTypes.find((item : SubscriptionType) => item.id === subscriptionId)
        :null;

    const ret_url = getAbsoluteBaseUrl() + '/subscribe/complete';

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: ret_url,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred."); //TODO : place here translation
        }

        setIsLoading(false);
    };

    const getValidity = (months: number)=>{

        if (months===12){
            return t('Yearly');
        }

        if (months===1){
            return t('Monthly');
        }

        return '';

    }

    const paymentElementOptions: StripePaymentElementOptions = {
        layout: {
            type: "accordion",
            defaultCollapsed: false,
            radios: false,
            spacedAccordionItems: false
        }
    }

    if (!subscription ){
        return <div>
            Error!
        </div>;
    }

    return (

        <Box component="form"
             noValidate
             sx={{width: {xs: '100%', sm: '100%'}, mt: 1, p: 0, fontFamily: 'Oswald, sans-serif'}}
             onSubmit={handleSubmit}>

            <Card sx={{width: "100%"}}>

                <CardHeader
                    title={
                        <Typography gutterBottom variant="h3" fontFamily={'Oswald,sans-serif'} textAlign={'center'}>
                            {t('You chose the subscription')}
                        </Typography>
                    }
                />

                <CardContent>
                    <Box sx={{flexGrow: 1}}>
                        <Card sx={{mb: 2}}>
                            <CardContent sx={{flexGrow: 1}}>
                                <Typography variant="h2" color="text.primary" sx={{fontFamily: 'Oswald, sans-serif', textAlign: 'left'}}>
                                    {t(subscription.name)}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{mt:1, fontFamily: 'Oswald, sans-serif'}}>
                                    {t('Validity')}: {getValidity(subscription.months)}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{fontFamily: 'Oswald, sans-serif'}}>
                                    {t('Number of daily researches')}: {subscription.dailyLimit === 0
                                    ? t('unlimited')
                                    : subscription.dailyLimit}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{fontFamily: 'Oswald, sans-serif'}}>
                                    {t('Cost')}: € {subscription.cost}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box sx={{width: '100%'}}>
                        <form id="payment-form" onSubmit={handleSubmit}>
                            <PaymentElement id="payment-element" options={paymentElementOptions}/>
                        </form>
                    </Box>
                </CardContent>

                <CardActions>
                    <Box sx={{width: '100%'}}>
                        <Button
                            size="large"
                            id="submit"
                            variant="contained"
                            type="submit"
                            disabled={isLoading || !stripe || !elements}
                            fullWidth
                            color="primary"
                            sx={{mt: 3, mb: 2, fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}
                        >
                            {isLoading ? <div className="spinner" id="spinner"></div> : t("Pay Now")}
                        </Button>
                        {message && <div id="payment-message">{message}</div>}
                    </Box>
                </CardActions>

            </Card>

        </Box>
    );
}

export default CheckoutForm;
