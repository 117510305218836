import { Skill } from "../types/Skill";
import { api } from '../utils/api';
import { TypeRequest } from "../types/TypeRequest";
import { PagedResponse } from "../types/PagedResponse";
import { PagedRequest } from "../types/PagedRequest";

export const getSkillById = async (id: string): Promise<Skill> => {
    const response = await api.get(`/api/skills/${id}`);
    return response.data;
};

export const createSkill = async (request: TypeRequest): Promise<Skill> => {
    const response = await api.post(`/api/skills`, request);
    return response.data;
};

export const updateSkill = async (id: string, request: TypeRequest): Promise<number> => {
    const response = await api.patch(`/api/skills/${id}`, request);
    return response.data;
};

export const deleteSkill = async (id: string): Promise<void> => {
    await api.delete(`/api/skills/${id}`);
};

export const getSkillsPaged = async (request: PagedRequest): Promise<PagedResponse<Skill>> => {
    const response = await api.post(`/api/skills/list`, request);
    return response.data;
};

export const getSkills = async (): Promise<Array<Skill>> => {
    const response = await api.get(`/api/skills`);
    return response.data;
};

export const searchSkills = async (filter: string): Promise<Array<Skill>> => {
    const response = await api.get(`/api/skills/search/${filter}`);
    return response.data;
};
