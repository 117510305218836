import { ContentType } from "../types/ContentType";
import { TypeRequest } from "../types/TypeRequest";
import { api } from '../utils/api';

export const getContentTypes = async (): Promise<Array<ContentType>> => {
    const response = await api.get(`/api/content-types`);
    return response.data;
};

export const createContentType = async (request: TypeRequest): Promise<ContentType> => {
    const response = await api.post(`/api/content-types`, request);
    return response.data;
};

export const updateContentType = async (id: string, request: TypeRequest): Promise<number> => {
    const response = await api.patch(`/api/content-types/${id}`, request);
    return response.data;
};

export const deleteContentType = async (id: string): Promise<void> => {
    await api.delete(`/api/content-types/${id}`);
};