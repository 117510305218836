import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useDb} from "../../contexts/DbContext";
import ModalDialog from "../../components/ModalDialog";
import {Content} from "../../types/Content";
import {refreshTokenIfNeeded} from "../../services/authService";
import {getContentById} from "../../services/contentService";
import {getContentMetadata, Log} from "../../utils/utils";
import {Helmet} from "react-helmet-async";
import ScrollableHtml from "../../components/ScrollableHtml";
import Button from "@mui/material/Button";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {ContentMediaCard} from "../../components/ContentMediaCard";
import {useContent} from "../../contexts/ContentContext";
import {Preview} from "../../components/Preview";

const ContentDetails: React.FC=()=>{

    const {id} = useParams();
    const {media} = useContent();
    const {t} = useDb();
    const navigate = useNavigate();

    const [content, setContent] = useState<Content>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [messageModal, setMessageModal] = useState<string>('');

    useEffect(() => {

        const loadBackendData = async(id: string)=>{
            try{

                await refreshTokenIfNeeded();
                const data = await getContentById(id);
                setContent(data);

            }
            catch (error){
                setShowModal(true);
                setMessageModal(error.message);
            }
        }

        loadBackendData(id);

    }, [id]);

    const previewMode = (data: Content)=>{

        if (!data){
            return ('empty');
        }

        if (data.contentType) {
            switch (data.contentType.name) {
                case 'Image':
                    return('image');
                case 'Document':
                    return('doc');
                case 'Html':
                    return('html');
                case 'Link':
                    return('link');
                case 'Text':
                    return('text');
                default:
                    return('unknown');
            }
        }

    }

    const mode = previewMode(content);
    const meta = media?.get(content?.id);
    const doc = {uri:getContentMetadata(content)?.address?.uri}

    return (
        <Box display="flex" flexDirection="column" width={'100%'} height={'100%'}>
            <Helmet>
                <title>{meta?meta.name:''} </title>
            </Helmet>

            {showModal && (
                <ModalDialog open={showModal} onClose={() => {
                    setShowModal(false);
                    setMessageModal('');
                }} label={messageModal} id={'error'} title={t('Error')}/>
            )}

            <Box display="flex" width={'100%'} justifyContent="center" alignItems="center">
                <Typography variant="h4" color="text.primary" sx={{fontFamily: 'Oswald, serif', fontSize:'16px', mt: 4}}>
                    {meta?meta.name:''}
                </Typography>

            </Box>

            {(mode==='empty') && <></>}
            {(mode==='unknown') && <></>}
            {(mode==='image') &&
                <ContentMediaCard key={'cmc' + meta.id} media={meta} contentType={content?.contentType} sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: "var(--surface-100)",
                    p: 2,
                    mt: 3,
                    ml: 0
                }} onClick={
                    (content) => Log.debug(content)
                }/>}
            {(mode==='doc') &&

                <Box component="div" sx={{mt:5, mb:5, p:2}}>

                    <Preview
                        style={{width: '100%', height: '100%'}}
                        docs={[doc]}
                        doc = {doc}
                    />

                </Box>

            }
            {(mode==='html' ||
                mode==='text') && <ScrollableHtml key={'cmc' + meta.id} border={false} htmlContent={meta.html!==''?meta.html:meta.note} width={'100%'} height={'100%'}
                                                  marginTop={'20px'} padding={10} fontSize={'20px'}/>}
            {(mode==='link') && <></>}

            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    sx={{
                        fontFamily: 'Oswald, sans-serif',
                        fontSize: '16px',
                        pl: 10,
                        pr: 10,
                        mb : 2
                    }}
                    onClick={(e) => navigate(-1)}
                >
                    {t('Back')}

                </Button>
            </Box>

        </Box>
    );
}

export default ContentDetails;
