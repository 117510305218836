import {useRoutes} from 'react-router-dom';
import router from 'src/router';

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import {CssBaseline} from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import {UsersProvider} from "./contexts/UsersContext";
import {useDb} from "./contexts/DbContext";
import {useEffect} from "react";
import {isNullOrUndefined, Log} from "./utils/utils";
import {ProfileProvider} from "./contexts/ProfileContext";
import {SkillProvider} from "./contexts/SkillContext";
import {ContentProvider} from "./contexts/ContentContext";
import {TraceProvider} from "./contexts/TraceContext";
import {ProjectProvider} from "./contexts/ProjectContext";

function App() {
    const content = useRoutes(router);
    const {countries, initDbRepo, error} = useDb();

    useEffect(() => {
        Log.debug('initDbRepo effect');
        if (isNullOrUndefined(countries) && !error) {
            Log.debug('initDbRepo');
            initDbRepo();
        }
    }, []);

    return (

        <TraceProvider>
            <SkillProvider>
                <ContentProvider>
                    <ProfileProvider>
                        <UsersProvider>
                            <ProjectProvider>
                                <ThemeProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <CssBaseline/>
                                        {content}
                                    </LocalizationProvider>
                                </ThemeProvider>
                            </ProjectProvider>
                        </UsersProvider>
                    </ProfileProvider>
                </ContentProvider>
            </SkillProvider>
        </TraceProvider>
    );
}

export default App;
