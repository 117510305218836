import {api} from "../utils/api";
import {DataChecksums} from "../types/DataChecksums";

export const getEntitiesChecksums = async (): Promise<DataChecksums> => {
    const response = await api.get(`/api/utilities/checksums`);
    return response.data;
};

export const getAllowedProperties = async (): Promise<{ [key: string]: number; }> => {
    const response = await api.get(`/api/utilities/properties/allowed`);
    return response.data;
};
