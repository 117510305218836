import {User} from "../../types/User";
import React, {useRef, useState} from "react";
import {CountryItem, CountryType} from "../CountryItem";
import {convertToEmoji, isNullOrUndefined, Log} from "../../utils/utils";
import {useDb} from "../../contexts/DbContext";
import {Button} from "@mui/material";
import {styled} from '@mui/material/styles';
import ModalInput from "../ModalInput";
import Box from "@mui/material/Box";

interface CountriesProps {
    user: User,

    onUpdateCountriesData(arg: { userId: string, countries: string[] }): void,

    isEditing: boolean,
    language?: string,
    label?: string,

}

const CountryList = styled('div')(({theme}) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    listStyle: 'none',
    padding: 0,
}));

export const Countries: React.FC<CountriesProps> = ({user, onUpdateCountriesData, isEditing, language='en-EN', label='Edit',}) => {

    // Modal input

    const [open, setOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>(user.countries.map(
        country => country.id
    ));
    const {countries,t} = useDb();
    const userCountries = useRef<Array<CountryType>>();

    function handleAddCountry() {
        Log.debug('Add Country');
        setOpen(true);
    }

    const handleClose = () => setOpen(false);

    function handleRemoveCountry(id) {
        Log.debug('Remove Country: ' + id);
        if (userCountries.current.length < 1) {
            return; // We need at least a country
        } else {
            userCountries.current = userCountries.current.filter(
                (country) => country.id !== id
            );
            onUpdateCountriesData({
                userId: user.id,
                countries: userCountries.current.map(country => country.id)
            })
            setSelectedItems(userCountries.current.map(country => country.id));
        }
    }

    function handleCountryModification(items: Array<string>) {
        Log.debug('handleCountryModification:' + JSON.stringify(items));
        const newItems = items.filter((c) => !isNullOrUndefined(c));
        setSelectedItems(newItems);
        onUpdateCountriesData({
            userId: user.id,
            countries: newItems
        })
    }


    Log.debug('SelectedItems: ->');
    Log.debug(selectedItems);

    // userCountries.current = selectedItems.map((id) => {
    //     const countryInfo = countries.filter((c) => {
    //         return c.id === id;
    //     });
    //
    //     if (!isNullOrUndefined(countryInfo) && countryInfo.length > 0) {
    //         //Log.debug('countryInfo:' + JSON.stringify(countryInfo));
    //         const res: CountryType = {
    //             id: id,
    //             name: countryInfo[0].name,
    //             emoji: convertToEmoji(countryInfo[0].code)
    //         }
    //         return res;
    //     } else {
    //         return null;
    //     }
    //
    // });

    Log.debug('countries ->');
    Log.debug(countries);

    userCountries.current = [...selectedItems.map((id) => {

        if (isNullOrUndefined(countries))
            return null;

        const countryInfo = countries.find((c) => c.id === id);

        if (countryInfo) {
            return {
                id: id,
                name: countryInfo.name,
                emoji: convertToEmoji(countryInfo.code)
            };
        } else {
            return null;
        }
    }).filter(Boolean)]; // Rimuove eventuali elementi null

    Log.debug('userCountries ->');
    Log.debug(userCountries.current);

    const availableCountries = isNullOrUndefined(countries)?[]:countries.map(
        (country) => {
            return {
                id: country.id,
                name: country.name,
                emoji: convertToEmoji(country.code)
            }
        }
    );

    //Log.debug(availableCountries);

    return (
        <>
            <ModalInput
                open={open}
                onClose={handleClose}
                onConfirm={handleCountryModification}
                title={t('Choose a Country')}
                multiSelect={true}
                initialValue={userCountries.current?.map(country => {
                    if (!isNullOrUndefined(country)) {
                        return country.id;
                    }
                })}>

                {availableCountries.map((country) =>
                    (
                        !isNullOrUndefined(country) &&
                        <div key={country.id}>
                            <CountryItem
                                country={country}
                                isEditing={false}
                                language={language}
                                onDelete={() => handleRemoveCountry(country.id)}
                                key={country.id}
                            />
                        </div>

                    ))
                }
            </ModalInput>

            <CountryList sx={{mt:1,mb:1, width:'100%'}}>
                {userCountries.current.map(country => (
                    !isNullOrUndefined(country) &&
                    <CountryItem
                        country={country}
                        isEditing={isEditing}
                        language={language}
                        onDelete={() => handleRemoveCountry(country.id)}
                        key={country.id}
                    />
                ))}

                {isEditing && (

                    <Box>
                        <Button
                            size="medium"
                            variant="contained"
                            type="submit"
                            color="primary"
                            sx={{mt: 0, ml:2, mb: 0, fontFamily: 'Oswald, sans-serif', fontSize: {xs: '14px', md:'16px'}}}
                            onClick={handleAddCountry}
                        >
                            {label}
                        </Button>
                    </Box>

                )}
            </CountryList>
        </>
    )


}
