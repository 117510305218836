import React, {createContext, useContext, useReducer} from "react";
import {
    getContentMetadata,
    isNullOrUndefined, Log,
} from "../utils/utils"
import {ContentRequest} from "../types/ContentRequest";
import {
    createContent,
    deleteContent,
    downloadContentById, downloadContentsFromMetadata,
    getContentById,
    getContents,
    getContentsByProfileId,
    getMyContents,
    updateAndUploadContent,
    updateContent,
    uploadContent
} from "../services/contentService";
import {Content} from "../types/Content";
import {PagedRequest} from "../types/PagedRequest";
import {getContentsByUser, getProjectsByUser} from "../services/userService";
import {ContentMetadata} from "../types/ContentMetadata";
import {refreshTokenIfNeeded} from "../services/authService";
import {AxiosError} from "axios";

const ContentContext = createContext(null);

const initialState = {
    contents: [],
    media: null,
    content: null,
    totalCount: 0,
    isLoaded: false,
    error: null,
    count: 0
}

function reducer(state, action) {
    switch (action.type) {
        case 'loading':
            return {
                ...state,
                isLoading: true,
                error: null,
            }

        case 'deleted-content':
            return {
                ...state,
                isLoading: false,
                content: null,
            }

        case 'got-contents-paged':
            return {
                ...state,
                isLoading: false,
                contents: action.payload.items,
                totalCount: action.payload.totalCount,
            }

        case 'got-media-user':
            return {
                ...state,
                isLoading: false,
                media: action.payload,
                totalCount: action.payload.size,
                error: null,
            }

        case 'updated-content':
        case 'new-content-created':
        case 'got-content':
            return {
                ...state,
                content: action.payload,
                isLoading: false,
            }

        case 'rejected':
            Log.debug(action.payload);
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }

        case 'got-contents':
            return {
                ...state,
                contents: action.payload.items,
                totalCount: action.payload.totalCount,
                count: action.payload.totalCount - action.payload.items.length,
                isLoading: false,
                error: null,
            }

        case 'got-contents-user':
            return {
                ...state,
                contents: action.payload,
                totalCount: action.payload.length,
                isLoading: false,
                error: null,
            }

        case 'got-contents-more':
            return {
                ...state,
                contents: [...state.projects, action.payload.items],
                count: state.count - action.payload.items.length,
                isLoading: false,
                error: null,
            }

        default:
            throw new Error('Unknown action type')
    }
}

function ContentProvider({children}: { children: React.ReactNode }) {
    const [{
        contents,
        content,
        media,
        isLoading,
        error,
        count,
        totalCount,
    }, dispatch] = useReducer(reducer, initialState);


    async function getMediaByContentId(id: string, downloadFile = false){

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getContentById(id);
            const metadata = getContentMetadata(data);
            if (downloadFile && metadata.address.origin==='internal') {
                const file = downloadContentById(id)
                    .then((file)=>{
                        const newMeta = {
                            ...metadata,
                            address: {
                                ...metadata.address,
                                uri: URL.createObjectURL(file),
                            }
                        }
                        return newMeta;
                    })
                    .catch((error)=>{
                        return error;
                    })
            }
            else {
                return metadata;
            }

        } catch (error) {
            const errmsg = "Backend Error: Can't Get Media, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            return error;
        }
    }

    async function getMediaByContentsId(items: Content[], downloadFiles = false) {
        dispatch({type: 'loading'});

        if (!items || !items.length) {
            return;
        }

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const mediaMap = new Map<string,ContentMetadata>();
            const metadata = items.map((item) => getContentMetadata(item));
            const downloads = metadata.filter((item) => {
                return item.address?.origin !== "youtube" && downloadFiles;
            });
            const links = metadata.filter((item) => {
                return item.address?.origin === "youtube" || !downloadFiles;
            });
            downloadContentsFromMetadata(downloads)
                .then(
                    (files) => {
                        files.forEach((item) => {
                            const meta = metadata.find((data) => data.id === item.name);
                            Log.debug(metadata);
                            const newMeta = {
                                ...meta,
                                address: {
                                    ...meta.address,
                                    uri: URL.createObjectURL(item),
                                }
                            }
                            mediaMap.set(item.name, newMeta);
                        })
                        links.forEach(link => {
                            mediaMap.set(link.id, link);
                        })
                        dispatch({type: 'got-media-user', payload: mediaMap});
                    }
                )
                .catch(error => {
                    const errmsg = "Backend Error: Can't Get Media, reason: " + error.message +
                        (error.response ? (" status: " + error.response.status) : "");
                    Log.error(errmsg);
                    dispatch({type: 'rejected', payload: error});
                })

        } catch (error) {
            const errmsg = "Backend Error: Can't Get Media, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);

        }

    }

    async function getMediaForUser(id: string, downloadFiles = false) {

        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getContentsByUser(id);
            const mediaMap = new Map<string,ContentMetadata>();
            const metadata = data.map((item) => getContentMetadata(item));
            const downloads = metadata.filter((item) => {
                return (item.address?.origin === "internal"
                    && item.address !== null
                    && item.address.uri !== undefined
                    && downloadFiles);
            });
            const links = metadata.filter((item) => {
                return (item.address?.origin !== "internal" || !downloadFiles);
            });
            downloadContentsFromMetadata(downloads)
                .then(
                    (files) => {
                        files.forEach((item) => {
                            const meta = metadata.find((data) => data.id === item.name);
                            Log.debug(metadata);
                            const newMeta = {
                                ...meta,
                                address: {
                                    ...meta.address,
                                    uri: URL.createObjectURL(item),
                                }
                            }
                            mediaMap.set(item.name, newMeta);
                        })
                        links.forEach(link => {
                            mediaMap.set(link.id, link);
                        })
                        dispatch({type: 'got-media-user', payload: mediaMap});
                    }
                )
                .catch(error => {
                    const errmsg = "Backend Error: Can't Get Media, reason: " + error.message +
                        (error.response ? (" status: " + error.response.status) : "");
                    Log.error(errmsg);
                    dispatch({type: 'rejected', payload: error});
                })

        } catch (error) {
            const errmsg = "Backend Error: Can't Get Media, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);

        }

    }

    async function createNewContent(request: ContentRequest, file?: File) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            let attach = null;
            const cont = file
                ? await uploadContent(request, file)
                : await createContent(request);
            if (cont.download) {
                attach = await downloadContentById(cont.id);
            }
            const data: Content = {
                id: cont.id,
                profile: cont.profile,
                contentType: cont.contentType,
                text: cont.text,
                created: cont.created,
                updated: cont.updated,
                download: cont.download,
                nsfw: cont.nsfw,
                file: attach
            }
            dispatch({type: 'new-content-created', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Create Content, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function fetchContentById(id: string) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const def = await getContentById(id);
            let attach = null;
            if (def.download) {
                attach = await downloadContentById(id);
            }
            const data: Content = {
                id: def.id,
                profile: def.profile,
                contentType: def.contentType,
                text: def.text,
                created: def.created,
                updated: def.updated,
                download: def.download,
                nsfw: def.nsfw,
                file: attach
            }
            dispatch({type: 'got-content', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Attachment Content with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function modifyContentById(id: string, request: ContentRequest, file?: File) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const cont = file
                ? await updateAndUploadContent(id, request, file)
                : await updateContent(id, request);
            const data: Content = {
                id: cont.id,
                profile: cont.profile,
                contentType: cont.contentType,
                text: cont.text,
                created: cont.created,
                updated: cont.updated,
                download: cont.download,
                nsfw: cont.nsfw,
                file: file
            }
            dispatch({type: 'updated-content', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Modify Content with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function removeContentById(id: string) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            await deleteContent(id);
            dispatch({type: 'deleted-content'});
        } catch (error) {
            const errmsg = "Backend Error: Can't Remove Content with id: " + id + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function fetchPagedContents(request: PagedRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getContents(request);
            dispatch({type: 'got-contents-paged', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Contents, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function fetchPagedProfileRelatedContents(profileId: string, request: PagedRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getContentsByProfileId(profileId, request);
            dispatch({type: 'got-contents-paged', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Contents by Profile id: " + profileId + ", reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function fetchPagedCurrentUserContents(request: PagedRequest) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getMyContents(request);
            dispatch({type: 'got-contents-paged', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Contents by Current User, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            dispatch({type: 'rejected', payload: errmsg});
        }

    }

    async function fetchCurrentUserContents(filter: string | null, sort: string | null, descending: boolean | null) {
        dispatch({type: 'loading'});
        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            let request: PagedRequest = {
                page: 0,
                size: 0,
                filter: filter,
                sort: sort,
                descending: descending
            };
            let data = await getMyContents(request);
            dispatch({type: 'got-contents', payload: data});
            Log.debug(data);
            while (count > 0) {
                request = {
                    ...request,
                    page: request.page + 1
                };
                data = await getMyContents(request);
                dispatch({type: 'got-contents-more', payload: data});
                Log.debug(data);
            }

        } catch (error) {
            dispatch({type: 'rejected', payload: error});
        }

    }

    async function fetchContentsByUser(id: string) {
        dispatch({type: 'loading'});

        try {
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getContentsByUser(id);
            dispatch({type: 'got-contents-user', payload: data});
        } catch (error) {
            const errmsg = "Backend Error: Can't Get Contents, reason: " + error.message +
                (error.response ? (" status: " + error.response.status) : "");
            Log.error(errmsg);
            dispatch({type: 'rejected', payload: error});
        }
    }

    return <ContentContext.Provider value={{
        contents,
        content,
        media,
        isLoading,
        getMediaForUser,
        getMediaByContentId,
        getMediaByContentsId,
        createNewContent,
        fetchContentById,
        modifyContentById,
        removeContentById,
        fetchPagedContents,
        fetchPagedProfileRelatedContents,
        fetchPagedCurrentUserContents,
        fetchCurrentUserContents,
        fetchContentsByUser,
        error,
        totalCount
    }}>{children}</ContentContext.Provider>;

}

function useContent() {
    const context = useContext(ContentContext);
    if (context === undefined) {
        throw new Error('useContent must be used within a ContentProvider');
    }
    return context;
}

export {ContentProvider, useContent};
