import { ProfileRole } from "../types/ProfileRole";
import { TypeRequest } from "../types/TypeRequest";
import { api } from '../utils/api';

export const getProfileRoles = async (): Promise<Array<ProfileRole>> => {
    const response = await api.get(`/api/profile-roles`);
    return response.data;
};

export const createProfileRole = async (request: TypeRequest): Promise<ProfileRole> => {
    const response = await api.post(`/api/profile-roles`, request);
    return response.data;
};

export const updateProfileRole = async (id: string, request: TypeRequest): Promise<ProfileRole> => {
    const response = await api.patch(`/api/profile-roles/${id}`, request);
    return response.data;
};

export const deleteProfileRole = async (id: string): Promise<void> => {
    await api.delete(`/api/profile-roles/${id}`);
};
