import React, {useState} from "react";
import {Card, CardContent, Theme, Typography, useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Footer from "../components/Footer";
import OnBoardingSignIn from "../components/OnBoardingSignIn";
import {Helmet} from "react-helmet-async";
import {useLocation, useNavigate} from "react-router-dom";
import ModalItem from "../components/ModalItem";
import {useDb} from "../contexts/DbContext";
import PageFooter from "../components/PageFooter";

const OnBoarding: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const {t} = useDb();

    const queryParams = new URLSearchParams(location.search);
    const openDialog = queryParams.get("popup");
    const [popupOpen, setPopupOpen] = useState<boolean>(openDialog ? (openDialog === 'true') : false);
    const [onboardingProcess, setOnboardingProcess] = useState<number>(1);

    function useIsMobile() {
        // Verifica se la larghezza è inferiore al breakpoint `sm`
        const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
        return isMobile;
    }

    return (
        <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <Box
                sx={{
                    backgroundImage: {
                        xs: 'url(/static/images/logo/thespecialist.png)', // Sfondo per mobile
                        md: 'url(/static/images/home/signup-background-dark.png)'     // Sfondo per desktop
                    },
                    backgroundPosition: {
                        xs: 'center 2%',
                        md: 'center center',
                    },
                    flexGrow:1,
                    backgroundSize: { xs: '70%', md: 'contain' },
                    backgroundRepeat: 'no-repeat',
                    height: "100vh",
                    width: '100%'
                }}
            >
                <Helmet>
                    <title>{t('The Specialist - Sign up')}</title>
                </Helmet>

                {popupOpen && (
                    <ModalItem open={popupOpen} onClose={() => { setPopupOpen(false); navigate('/register') }}>
                        <CardContent>
                            <Box sx={{ flexGrow: 1 }} display="flex" flexDirection="column">
                                <Typography variant="h2" sx={{ mt: 2, fontFamily: 'Oswald, sans-serif', textAlign: 'center' }}>
                                    <span>{t('Sign up to The Specialist')}</span>
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 5, fontFamily: 'Oswald, sans-serif' }}>
                                    <span>{t('Did you find an interesting profile?')}</span>
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 2, fontFamily: 'Oswald, sans-serif' }}>
                                    <span>{t('Sign up to The Specialist to search contents and people')}</span>
                                </Typography>
                            </Box>
                        </CardContent>
                    </ModalItem>
                )}

                <Box
                    component="div"
                    sx={{
                        paddingLeft: '3%',
                        paddingRight: '1%',
                        display: 'flex',
                        justifyContent: { xs: 'center', md: 'flex-end' },
                        alignItems: { xs: 'center', md: 'center' },
                        color: 'white',
                        flexGrow: 1,
                        textAlign: 'center',
                        fontFamily: 'Oswald, sans-serif',
                    }}
                >
                    <Card variant="outlined" sx={{ width: { xs: '90%', md: '40%' }, mt: { xs: '20%', md: '15%' } }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 'inherit' }}>
                                <OnBoardingSignIn OnChangeOnboardingStep={(procnum)=>setOnboardingProcess(procnum)} />
                            </Box>
                        </CardContent>
                    </Card>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexGrow: 1,
                        position: { xs: 'relative', md: 'fixed' },
                        bottom: { xs: 'unset', md: '180px' },
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        p: { xs: 2, md: 0 },
                    }}
                >
                    <Grid container spacing={{md:4, xs:2}}>
                        <Grid size={{xs:12, md:4}}>
                            <Box textAlign="center">
                                <Typography variant="h3" sx={{ fontFamily: 'Oswald, sans-serif' }}>{t('Search Professionals')}</Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Oswald, sans-serif' }}>{t('Find experts for every film production sectors')}</Typography>
                            </Box>
                        </Grid>
                        <Grid size={{xs:12, md:4}}>
                            <Box textAlign="center">
                                <Typography variant="h3" sx={{ fontFamily: 'Oswald, sans-serif' }}>{t('Publish your projects')}</Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Oswald, sans-serif' }}>{t('Connect with professionals for your projects')}</Typography>
                            </Box>
                        </Grid>
                        <Grid size={{xs:12, md:4}}>
                            <Box textAlign="center">
                                <Typography variant="h3" sx={{ fontFamily: 'Oswald, sans-serif' }}>{t('Collaborate')}</Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Oswald, sans-serif' }}>{t('Work together the best talents in the sector')}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <PageFooter />

        </Box>
    );
};

export default OnBoarding;
