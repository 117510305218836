import React, {useEffect, useState} from 'react';
import {List, ListItem, ListItemText, Button, Paper, Typography, ListItemButton} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Box from "@mui/material/Box";
import {User} from "../../types/User";
import {Log} from "../../utils/utils";
import {useDb} from "../../contexts/DbContext";

export interface TransferListItem {
    id: string;
    name: string;
}

interface TransferListProps {
    title?: string;
    leftItems: TransferListItem[];
    rightItems: TransferListItem[];
    onSelectedItems: (selectedItems: TransferListItem[]) => void;
    owner?: User;

}

const TransferList: React.FC<TransferListProps> = ({
                                                       title = '',
                                                       leftItems,
                                                       rightItems,
                                                       onSelectedItems,
                                                       owner = null
                                                   }) => {

    const {t} = useDb();

    const [left, setLeft] = useState<TransferListItem[]>([]);

    const [right, setRight] = useState<TransferListItem[]>([]);

    useEffect(() => {
        const filteredLeftData = leftItems?.reduce((acc, item) => {
            if (item && !acc.find(x => x.id === item.id)) {
                if (owner) {
                    if (owner.id !== item.id) {
                        acc.push(item);
                    }
                } else {
                    acc.push(item);
                }
            }
            return acc;
        }, []);
        setLeft(filteredLeftData);
        Log.debug(filteredLeftData);
    }, [leftItems, owner]);

    useEffect(() => {
        let filteredRightData = rightItems?.reduce((acc, item) => {
            if (item && !acc.find(x => x.id === item.id)) {
                acc.push(item);
            }
            return acc;
        }, []);
        if (owner) {
            const ownerItem: TransferListItem = {id: owner.id, name: (owner.firstname + ' ' + owner.lastname)}
            const arrayOfRightId = filteredRightData.map((item) => item.id);
            if (!arrayOfRightId.includes(ownerItem.id)) {
                filteredRightData = [...filteredRightData, ownerItem];
            }
        }
        setRight(filteredRightData);
        Log.debug(filteredRightData);
    }, [rightItems, owner]);

    const handleMoveRight = (item: TransferListItem) => {
        setLeft(left.filter((i) => i.id !== item.id));
        setRight([...right, item]);
        onSelectedItems([...right, item]);
    };

    const handleMoveLeft = (item: TransferListItem) => {
        if (owner) {
            if (item.id === owner.id) {
                return;
            }
        }
        setRight(right.filter((i) => i.id !== item.id));
        setLeft([...left, item]);
        onSelectedItems(right.filter((i) => i.id !== item.id));
    };

    function moveUniqueItems(isForward: boolean): void {

        const arr1: string[] = isForward
            ? [...left.map(item => item.id)]
            : [...right.map(item => item.id)];
        const arr2: string[] = isForward
            ? [...right.map(item => item.id)]
            : [...left.map(item => item.id)];

        // Filtra gli elementi di arr1 che non sono presenti in arr2
        const uniqueItems = arr1.filter(item => !arr2.includes(item) && (owner && owner.id !== item));

        // Aggiungi gli elementi unici a arr2
        arr2.push(...uniqueItems);

        // Rimuovi gli elementi unici da arr1
        for (const item of uniqueItems) {
            const index = arr1.indexOf(item);
            if (index !== -1) {
                arr1.splice(index, 1);
            }
        }

        const arr: TransferListItem[] = [];
        // Crea un nuovo array con i dati di arr2
        for (const item of arr2) {
            const _a1 = left.find(s => s.id === item);
            const _a2 = right.find(s => s.id === item);
            if (_a1) {
                arr.push(_a1);
            }
            if (_a2) {
                arr.push(_a2);
            }
        }

        if (isForward) {
            setLeft([]);
            setRight([...arr]);
            onSelectedItems([...arr]);
        } else {
            setLeft([...arr]);
            setRight([]);
            onSelectedItems([]);
        }

    }

    const listStyle = {
        minHeight: 200,
        minWidth: 200,
        maxHeight: 200,
        maxWidth: 400,
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Oswald, sans-serif',
    };


    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '500px', mb: 4}}>
                <Typography variant="h4" sx={{fontFamily: 'Oswald', textAlign: 'center'}}>
                    {title}
                </Typography>
                {/*<span>{JSON.stringify(rightItems, null, 2)} </span>*/}
                {/*<span>{rightItems.length} </span>*/}
                {/*<span>{JSON.stringify(right, null, 2)} </span>*/}
                {/*<span>{right.length} </span>*/}
            </Box>
            <Grid container spacing={2} justifyContent="left" alignItems="center" sx={{mt: 1}}>
                <Grid>
                    <Paper style={listStyle}>
                        {!left || left?.length === 0 ? (
                            <Typography sx={{fontFamily: 'Oswald, sans-serif', fontSize: '16px'}} variant="body1">
                                {t('Empty List')}
                            </Typography>
                        ) : (
                            <List dense>
                                {left.map((item) => (
                                    <ListItemButton key={item.id} onClick={() => handleMoveRight(item)}>
                                        <ListItemText primary={t(item.name)} primaryTypographyProps={{
                                            fontFamily: 'Oswald, sans-serif',
                                            fontSize: '16px'
                                        }}/>
                                    </ListItemButton>
                                ))}
                            </List>
                        )}
                    </Paper>
                </Grid>
                <Grid>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => moveUniqueItems(true)}
                            disabled={!left || left?.length === 0}
                            style={{marginBottom: 10}}
                        >
                            ≫
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => moveUniqueItems(false)}
                            disabled={!right || right?.length === 0}
                        >
                            ≪
                        </Button>
                    </Grid>
                </Grid>
                <Grid>
                    <Paper style={listStyle}>
                        {right.length === 0 ? (
                            <Typography sx={{fontFamily: 'Oswald, sans-serif', fontSize: '16px'}} variant="body1">
                                {t('Empty List')}
                            </Typography>
                        ) : (
                            <List dense>
                                {right.map((item) => (
                                    <ListItemButton key={item.id} onClick={() => handleMoveLeft(item)}>
                                        <ListItemText primary={t(item.name)} primaryTypographyProps={{
                                            fontFamily: 'Oswald, sans-serif',
                                            fontSize: '16px'
                                        }}/>
                                    </ListItemButton>
                                ))}
                            </List>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default TransferList;
