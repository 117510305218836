import React, {forwardRef, ReactElement, Ref, useState} from "react";
import {TransitionProps} from "@mui/material/transitions";
import {Button, Card, Dialog, DialogTitle, IconButton, Slide, Tooltip, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {OverridableStringUnion} from "@mui/types";
import {SvgIconPropsSizeOverrides} from "@mui/material/SvgIcon/SvgIcon";
import {PersonTwoTone} from "@mui/icons-material";
import Login from "../../../../../components/Login";
import Box from "@mui/material/Box";
import RecoverLogin from "../../../../../components/RecoverLogin";
import {useNavigate} from "react-router-dom";
import {Log} from "../../../../../utils/utils";
import {useDb} from "../../../../../contexts/DbContext";

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
    () => `
    .MuiDialog-container {
        height: auto;
        display: flex;
        align-items: center; 
        justify-content: center;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const DialogTitleWrapper = styled(DialogTitle)(
    ({theme}) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(3)}
`
);

function HeaderLogin({fontSize = 'large'}: { fontSize?: string }) {

    const {t} = useDb();

    const [open, setOpen] = useState(false);
    const [login, setLogin] = useState<boolean>(true);
    const [recover, setRecover] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickForgot = () => {
        Log.debug('Forgot password');
        setLogin(false);
        setRecover(true);
    };

    const handleClickSignUp = () => {
        if (login) {
            Log.debug('Sign Up');
            navigate('/register');
        } else {
            Log.debug('Back to login');
            setLogin(true);
            setRecover(false);
        }
    };

    return (
        <>
            <Tooltip arrow title="User" sx={{fontFamily: 'Oswald, sans-serif'}}>
                <IconButton color="primary" onClick={handleClickOpen}>
                    <PersonTwoTone fontSize={fontSize as OverridableStringUnion<
                        'inherit' | 'large' | 'medium' | 'small',
                        SvgIconPropsSizeOverrides
                    >}/>
                </IconButton>
            </Tooltip>
            <DialogWrapper
                open={open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth="sm"
                fullWidth
                scroll="paper"
                onClose={handleClose}
            >
                <DialogTitleWrapper>
                    <Box sx={{width:'100%'}}>
                        <img src={'/static/images/logo/thespecialist1.png'} alt="The Specialist Logo"
                             style={{height: '50px', display: 'block', margin: '0 auto'}}/>
                        {login && (<Login/>)}
                        {recover && (<RecoverLogin onClose={() => {
                            setOpen(false);
                            setLogin(true);
                            setRecover(false);
                        }
                        }/>)}
                        <Box sx={{
                            flexGrow: 1,
                            display: "flex",
                            justifyContent: "space-between"
                        }}>

                            <Button onClick={handleClickSignUp}
                                    sx={{margin: 0, mt: 1, mb: 2}}
                                    color="secondary"
                            >
                                <Typography variant={"h4"} sx={{fontFamily: 'Oswald, sans-serif'}}>
                                    {login && t('Sign up')} {recover && t('Back to Login')}
                                </Typography>
                            </Button>

                            {login && (<Button onClick={handleClickForgot}
                                               sx={{margin: 0, mt: 1, mb: 2}}
                                               color="secondary"
                            >
                                <Typography variant={"h4"} sx={{fontFamily: 'Oswald, sans-serif'}}>
                                    {t('Lost Password?')}
                                </Typography>
                            </Button>)}

                        </Box>
                    </Box>
                </DialogTitleWrapper>
            </DialogWrapper>
        </>
    )
}

export default HeaderLogin;
