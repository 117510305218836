// import React from 'react';
// import { useLocation, Navigate } from 'react-router-dom';
// import {useAuth} from "../../contexts/AuthContext";
//
// interface ProtectedRouteProps {
//     component: React.ElementType;
// }
//
// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component }) => {
//     const location = useLocation();
//     const {isAuthenticated} = useAuth();
//     return isAuthenticated ? (
//         <Component />
//     ) : (
//         <Navigate to="/login" state={{ from: location }} />
//     );
// };
//
// export default ProtectedRoute;

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {useAuth} from "../../contexts/AuthContext";
import {Log} from "../../utils/utils";

function ProtectedRoute({children}) {
    const navigate = useNavigate();
    const {isAuthenticated} = useAuth();

    useEffect(function(){

        Log.debug('isAuthenticated:' + isAuthenticated)

        if (!isAuthenticated) navigate('/');
    },[isAuthenticated,navigate]);

    return (
        isAuthenticated? children : null
    )
}

export default ProtectedRoute
