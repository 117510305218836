import React, {useState, ReactNode, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    Checkbox, Select, ListItemButton,
} from '@mui/material';
import {Log} from "../../utils/utils";
import {useDb} from "../../contexts/DbContext";
import value from "*.jpg";

interface SelectListProps {
    onChanged?: (selectedItems: Array<string>) => void;
    onItemSelected?: (selectedItem: string) => void;
    onItemHover?: (selectedItem: string) => void;
    children?: ReactNode;
    multiSelect?: boolean;
    initialValue?: string[];
}

const SelectList: React.FC<SelectListProps> = ({
                                                   onChanged=null,
                                                   onItemSelected=null,
                                                   onItemHover=null,
                                                   children,
                                                   multiSelect = false,
                                                   initialValue = []
                                               }) => {

    const {t}=useDb();

    const [selectedItems, setSelectedItems] = useState<Array<string>>([]);

    const handleHover = (value: string) => () =>{
        Log.debug('Hover');
        Log.debug('value');
        if (onItemHover){
            onItemHover(value);
        }
    }

    const handleToggle = (value: string) => () => {

        // const test_1 = selectedItems.indexOf(value) !== -1;
        // Log.debug('==test in==');
        // Log.debug(selectedItems);
        // Log.debug(value);
        // Log.debug(test_1);

        const currentIndex = selectedItems.indexOf(value);
        const newChecked = [...selectedItems];

        if (currentIndex === -1) {
            if (multiSelect) {
                newChecked.push(value);
            } else {
                newChecked.splice(0, newChecked.length, value);
            }
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedItems(newChecked);

        if (onChanged){
            onChanged(newChecked);
        }
        if (onItemSelected){
            onItemSelected(value);
        }

        // const test_2 = newChecked.indexOf(value) !== -1;
        // Log.debug('==test out==');
        // Log.debug(newChecked);
        // Log.debug(value);
        // Log.debug(test_2);
    };

    useEffect(() => {
        Log.debug('initialValue');
        Log.debug(initialValue);
        setSelectedItems(initialValue);
    }, [initialValue]);

    return (
        <List>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return (
                        <ListItemButton
                            onMouseEnter={handleHover(child.key as string)}
                            onClick={handleToggle(child.key as string)}
                            dense
                        >
                            <Checkbox
                                edge="start"
                                checked={selectedItems.indexOf(child.key as string) !== -1}
                                tabIndex={-1}
                                disableRipple
                            />
                            <ListItemText primary={child.props.children}/>
                        </ListItemButton>
                    );
                }
                return null;
            })}
        </List>
    );
};

export default SelectList;
