import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

interface FieldDataPickerProps{
    label?: string;
    value: Date | null;
    OnChange?: (date: Date) => void;
    sx?:object;
}

// Definisci un tema personalizzato con Oswald come font principale

export const FieldDataPicker: React.FC<FieldDataPickerProps>=({label='',OnChange=null, sx={},value})=>{

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    sx={sx}
                    label={label}
                    format="dd/MM/yyyy"
                    value={value}
                    onChange={(date: Date | null) => {if (OnChange) OnChange(date)}}/>
        </LocalizationProvider>
    );
}
