import {createContext, useContext, useReducer} from "react";
import {
    authorizeUser,
    createUser, createUserCountry,
    deleteUser, deleteUserCountry,
    getMe, getProfilePicture,
    getUserById,
    getUsers,
    getUsersWaitingApprovals, setUserProperty, unauthorizeUser, updateCurrentUser,
    updateUser,
} from "../services/userService";
import {PagedRequest} from "../types/PagedRequest";
import {UserCreateRequest} from "../types/UserCreateRequest";
import {UserUpdateRequest} from "../types/UserUpdateRequest";
import {User} from "../types/User";
import {Role} from "../types/Role";
import {UserAuthorizeRequest} from "../types/UserAuthorizeRequest";
import {UserCountryRequest} from "../types/UserCountryRequest";
import {UserPropertyRequest} from "../types/UserPropertyRequest";
import {isNullOrUndefined, Log} from "../utils/utils";
import {MeUpdateRequest} from "../types/MeUpdateRequest";
import {logout, refreshTokenIfNeeded} from "../services/authService";
import {AxiosError} from "axios";

const UsersContext = createContext(null);

const initialState = {
    user: null,
    users : [],
    me_cover: null,
    me_image: null,
    cover: null,
    image: null,
    me: null,
    isLoading: false,
    error: null,
    count: 0
}

function reducer(state,action){
    switch(action.type) {
        case 'loading':
            return {
                ...state,
                isLoading: true,
                error: null,
            }
        case 'users/got-owner':
            return {
                ...state,
                me: action.payload,
                isLoading: false,
                error: null,
            }
        case 'users/owner-updated':
            return {
                ...state,
                me: action.payload,
                isLoading: false,
                error: null,
            }
        case 'users/got-user':
            return {
                ...state,
                user: action.payload,
                me: (action.payload as User).id===state.me?.id?action.payload:state.me,
                isLoading: false,
                error: null,
            }
        case 'users/user-created':
            return {
                ...state,
                users: [...state.users, action.payload],
                user: action.payload,
                isLoading: false,
                error: null,
            }
        case 'users/user-modified':
            return {
                ...state,
                users: state.users.map((user : User) => {
                    if (user.id === action.payload) {
                        return {
                            ...user,
                            ...action.payload
                        };
                    }
                    return user;
                }),
                user: action.payload,
                me: (action.payload as User).id===state.me.id?action.payload:state.me,
                isLoading: false,
                error: null,
            }

        case 'users/user-deleted':
            return {
                ...state,
                users: state.users.filter((user : User) => user.id !==action.payload),
                isLoading: false,
                error: null,
            }

        case 'users/user-role-removed':
        case 'users/user-role-added':
        case 'users/user-country-removed':
        case 'users/user-country-added':
        case 'users/user-property-modified':
            return {
                ...state,
                users: state.users.map((user : User) => {
                    if (user.id === action.payload) {
                        return {
                            ...user,
                            ...action.payload
                        };
                    }
                    return user;
                }),
                me: (action.payload as User).id===state.me.id?action.payload:state.me,
                user: action.payload,
                isLoading: false,
                error: null,
            }

        case 'users/got-users':
            return {
                ...state,
                users: action.payload.items,
                count: action.payload.totalCount - action.payload.items.length,
                isLoading: false,
                error: null,
            }
        case 'users/got-users-more':
            return {
                ...state,
                users: [...state.users, action.payload.items],
                count: state.count - action.payload.items.length,
                isLoading: false,
                error: null,
            }
        case 'users/got-image':
            break;
        case 'users/got-cover':
            break;
        case 'me/got-image':
            return {
                ...state,
                me_image: action.payload,
                isLoading: false,
                error: null,
            }
        case 'me/got-cover':
            break;
        case 'rejected':
            if (action.payload.message) {
                if (action.payload.message.includes('401')) {
                    logout();
                }
            }
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }

        default:
            throw new Error('Unknown action type')
    }
}

function UsersProvider({ children }: { children: React.ReactNode }) {

    const [{user, me, users, count, isLoading, error, me_image, me_cover, image, cover}, dispatch] = useReducer(reducer,initialState);

    async function fetchUsers(filter: string | null, sort: string | null, descending: boolean | null){

        dispatch({type:'loading'});
        const res = await refreshTokenIfNeeded();
        if (!res){
            dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
            return;
        }
        try{
            let request : PagedRequest = {
                page: 0,
                size: 0,
                filter : filter,
                sort: sort,
                descending: descending
            };
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
            }
            let data = await getUsers(request);
            dispatch({type:'users/got-users',payload:data});
            Log.debug(data);
            while (count>0){
                request = {
                  ...request,
                  page: request.page+1
                };
                data = await getUsers(request);
                dispatch({type:'users/got-users-more',payload:data});
                Log.debug(data);
            }

        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }

    }

    async function fetchUser(id:string){

        if (user && user.id === id) return;

        dispatch({type:'loading'});
        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getUserById(id);
            Log.debug(data);
            dispatch({type:'users/got-user',payload:data});
        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }
    }

    async function fetchOwner(){
        dispatch({type:'loading'});
        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getMe();
            Log.debug(data);
            dispatch({type:'users/got-owner',payload:data});
        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }
    }

    async function updateOwner(user: MeUpdateRequest){
        dispatch({type:'loading'});
        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await updateCurrentUser(user);
            Log.debug(data);
            dispatch({type:'users/owner-updated',payload:user});
        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }
    }

    async function fetchUsersWaitingApprovals(filter: string | null, sort: string | null, descending: boolean | null){

        dispatch({type:'loading'});
        try{
            let request : PagedRequest = {
                page: 0,
                size: 0,
                filter : filter,
                sort: sort,
                descending: descending
            };
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            let data = await getUsersWaitingApprovals(request);
            dispatch({type:'users/got-users',payload:data});
            Log.debug(data);
            while (data && data.items.length){
                request = {
                    ...request,
                    page: request.page+1
                };
                data = await getUsers(request);
                dispatch({type:'users/got-users-more',payload:data});
                Log.debug(data);
            }

        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }

    }

    async function createNewUser(user : UserCreateRequest){

        dispatch({type:'loading'});
        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await createUser(user);
            Log.debug(data);
            dispatch({type:'users/user-created',payload:data});
        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }
    }

    async function modifyUser(user : User){
        dispatch({type:'loading'});
        try{
            Log.debug('modifyUser ->');
            Log.debug(user);

            const info : MeUpdateRequest = {
                firstname: user.firstname,
                lastname: user.lastname,
                mobile: user.mobile,
                properties: user.properties,
                countries: user.countries.map(
                    country=>country.id
                ),
            }

            Log.debug("updated user -> ")
            Log.debug(info);
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            await updateCurrentUser(info);

            dispatch({type:'users/user-modified',payload:user});
        }
        catch(error){
            Log.debug('ERROR: modifyUser, reason : ' + error.message)
            dispatch({type:'rejected',payload:error});
        }
    }


    async function removeUser(id: string){
        dispatch({type:'loading'});
        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            await deleteUser(id);
            dispatch({type:'users/user-deleted', payload: id});
        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }
    }

    async function addRoleToUser(id: string, role: Role){

        dispatch({type:'loading'});

        const request: UserAuthorizeRequest = {
            userId: id,
            roleId : role.id
        }
        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await authorizeUser(request);
            Log.debug(data);
            dispatch({type:'users/user-role-added',payload:data});
        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }
    }

    async function removeRoleFromUser(id: string, role: Role){

        dispatch({type:'loading'});

        const request: UserAuthorizeRequest = {
            userId: id,
            roleId : role.id
        }
        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await unauthorizeUser(request);
            Log.debug(data);
            dispatch({type:'users/user-role-removed',payload:data});
        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }
    }

    function setOwner(user : User | null){
        dispatch({type:'users/got-owner',payload:user});
    }

    function setUser(user : User | null){
        dispatch({type:'users/got-user',payload:user});
    }

    async function _addCountryToUser(id: string, countryId: string){

        const request: UserCountryRequest = {
            userId: id,
            countryId : countryId
        }
        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await createUserCountry(request);
            Log.debug('addCountryToUser : response data is :' + (isNullOrUndefined(data)
                ? 'null'
                : JSON.stringify(data)));
        }
        catch(error){
            Log.debug('ERROR: _addCountryToUser, reason : ' + error.message)
        }
    }

    async function addCountryToUser(id: string, countryId: string){

        dispatch({type:'loading'});

        const request: UserCountryRequest = {
            userId: id,
            countryId : countryId
        }
        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await createUserCountry(request);
            Log.debug('addCountryToUser : response data is :' + (isNullOrUndefined(data)
                ? 'null'
                : JSON.stringify(data)));
            dispatch({type:'users/user-country-added',payload:data});
        }
        catch(error){
            Log.debug('ERROR: addCountryToUser, reason : ' + error.message)
            dispatch({type:'rejected',payload:error});
        }
    }

    async function _removeCountryFromUser(id: string, countryId: string){

        const request: UserCountryRequest = {
            userId: id,
            countryId : countryId
        }
        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await deleteUserCountry(request);
            Log.debug('removeCountryFromUser : response data is :' + (isNullOrUndefined(data)
                ? 'null'
                : JSON.stringify(data)));
        }
        catch(error){
            Log.debug('ERROR: _removeCountryFromUser, reason : ' + error.message)
        }
    }

    async function removeCountryFromUser(id: string, countryId: string){

        dispatch({type:'loading'});

        const request: UserCountryRequest = {
            userId: id,
            countryId : countryId
        }
        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await deleteUserCountry(request);
            Log.debug('removeCountryFromUser : response data is :' + (isNullOrUndefined(data)
                ? 'null'
                : JSON.stringify(data)));
            dispatch({type:'users/user-country-removed',payload:data});
        }
        catch(error){
            Log.debug('ERROR: removeCountryFromUser, reason : ' + error.message)
            dispatch({type:'rejected',payload:error});
        }
    }

    async function _setPropertyForUser(id: string, key: string, value: string){

        const request: UserPropertyRequest = {
            userId: id,
            key: key,
            value: value
        }

        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await setUserProperty(request);
        }
        catch(error){
            Log.debug('ERROR: _setPropertyForUser, reason : ' + error.message)
        }

    }

    async function setPropertyForUser(id: string, key: string, value: string){

        dispatch({type:'loading'});

        const request: UserPropertyRequest = {
            userId: id,
            key: key,
            value: value
        }

        //Log.debug(request);

        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await setUserProperty(request);
            //Log.debug(data);
            dispatch({type:'users/user-property-modified',payload:data});
        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }


    }

    async function fetchOwnerProfilePicture(){
        Log.debug('fetching owner profile picture');
        dispatch({type:'loading'});

        try{
            const res = await refreshTokenIfNeeded();
            if (!res){
                dispatch({type:'rejected',payload:new AxiosError('Refresh Token Failed','401')});
                return;
            }
            const data = await getProfilePicture();
            //Log.debug(data);
            dispatch({type:'me/got-image',payload:data});
        }
        catch(error){
            dispatch({type:'rejected',payload:error});
        }
    }

    async function fetchOwnerCoverPicture(){
        Log.debug('fetching owner cover picture');
    }

    async function fetchProfilePictureById(id: string){
        Log.debug('fetching profile picture');
    }

    async function fetchCoverPictureById(id: string){
        Log.debug('fetching cover picture');
    }


    return <UsersContext.Provider value={
        {user, me,users,isLoading,error, me_image, me_cover,
            cover,image,
            fetchUsers,fetchUser,fetchOwner,
            fetchUsersWaitingApprovals, createNewUser,
            modifyUser, removeUser, addRoleToUser,
            removeRoleFromUser, setUser, setOwner, addCountryToUser,
            removeCountryFromUser, updateOwner, setPropertyForUser,
            fetchOwnerProfilePicture,

        }
    }>{children}</UsersContext.Provider>;
}

function useUsers(){
    const context = useContext(UsersContext);
    if (context == undefined) {
        throw new Error('useUsers must be used within a AuthProvider');
    }
    return context;
}

export {UsersProvider,useUsers};
