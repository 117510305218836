import {Project} from "../../../types/Project";
import {AxiosError} from "axios";
import React, {useEffect, useRef, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import {useDb} from "../../../contexts/DbContext";
import {ProjectSkillRequest} from "../../../types/ProjectSkillRequest";
import {
    addContentToProject,
    addSkillToProject, createProject, getProjectById,
    removeContentFromProject,
    removeSkillFromProject, updateProject
} from "../../../services/projectService";
import {ProjectContentRequest} from "../../../types/ProjectContentRequest";
import {ProjectUpdateRequest} from "../../../types/ProjectUpdateRequest";
import {ProjectCreateRequest} from "../../../types/ProjectCreateRequest";
import ModalDialog from "../../../components/ModalDialog";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid2';
import {Card, MenuItem, TextField, Typography} from "@mui/material";
import {Skills} from "../../../components/Skills";
import {Skill} from "../../../types/Skill";
import {FieldDataPicker} from "../../../components/FieldDatePicker";
import Editor from "react-simple-wysiwyg";
import {ContentSelection} from "../../../components/ContentSelection";
import Button from "@mui/material/Button";
import {Content} from "../../../types/Content";
import {refreshTokenIfNeeded} from "../../../services/authService";
import {getBrowserLanguage, isOwner, Log} from "../../../utils/utils";

interface ProjectPageProps {
    projectId?: string;
    OnAbort?: () => void;
    OnSuccess?: () => void;
}

const emptyProject: Project = {
    id: '',
    title: '',
    description: '',
    profile: null,
    start: new Date(),
    end: null,
    created: new Date(),
    updated: new Date(),
    contents: [],
    skills: []
}

export const EditProjectPage: React.FC<ProjectPageProps> = ({projectId = '', OnAbort = null, OnSuccess = null}) => {

    const isEdit = (projectId && projectId !== '');

    Log.debug(projectId);

    const {me, refreshMe} = useAuth();
    const {t} = useDb();

    useEffect(() => {
        refreshMe();
    },[]);

    useEffect(() => {

        const loadBackendData = async (id: string)=>{

            try{

                await refreshTokenIfNeeded();

                const project = await getProjectById(id);

                if (project){
                    if (!oldProjectRef.current) {
                        oldProjectRef.current = project;
                    }
                    editProjectRef.current = (projectId && project.id === projectId);
                    setCurrentProject({
                        ...project,
                        start: new Date(project.start),
                        end: new Date(project.end),
                    })
                    setSelectedSkills(project.skills);
                    setCurrentContents(project.contents);

                }
                else{
                    editProjectRef.current = false;
                }

                Log.debug('===== EDIT PROJECT======');
                Log.debug(project);
                Log.debug(new Date(project.start));

            }
            catch (error){
                Log.debug('=====ERROR=====');
                Log.debug(error.message);
                setShowModal(true);
                setErrorMessage(getErrorMessage(error));
            }
        }

        if (isEdit){
            loadBackendData(projectId);
        }

    }, [projectId]);

    const [currentProject, setCurrentProject] = useState<Project>(emptyProject);
    const [currentContents, setCurrentContents] = useState<Content[]>([]);
    // Array per il secondo Select (array fisso)
    const profileArray = me?.profiles ? me.profiles : [];
    const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const oldProjectRef = useRef<Project>(null);
    const editProjectRef = useRef<boolean>(false);
    const modifiedRef = useRef<boolean>(false);

    const getErrorMessage = (error: AxiosError) => {
        let errorMessage: string | null = null;
        const errorNum = error.response?.status;
        const simpleData = error.response?.data as {
            status: number;
            detail: string;
        }
        const detailErr = simpleData?.detail;

        if (detailErr) {
            return detailErr;
        }

        switch (errorNum) {
            case 403:
                errorMessage = t('Project does not exist');
                break;
            case 401:
                errorMessage =  t('The user is unknown or the password is incorrect.');
                break;
            default:
                errorMessage =  t("An issue happened at backend") +" (" + errorNum + ")";
                break;
        }

        return errorMessage;
    }

    //Helper Campi
    const [profileError, setProfileError] = useState<string>('');

    const restoreOldProject = async () => {

        try {

            // Restore old skills

            const currentSkills = currentProject?.skills;
            const old = oldProjectRef.current.skills;

            const old_skills: string[] = [...old.map(item => item.id)];
            const new_skills: string[] = currentSkills
                ? [...currentSkills.map(item => item.id)]
                : [];

            // calcola le skill da eliminare
            const deleted = new_skills.filter(item => !old_skills.includes(item));
            // calcola le skill da aggiungere
            const added = old_skills.filter(item => !new_skills.includes(item));

            for (const item of deleted) {
                const arg: ProjectSkillRequest = {
                    projectId: currentProject?.id,
                    skillId: item,
                }

                await removeSkillFromProject(arg);
            }

            for (const item of added) {
                const arg: ProjectSkillRequest = {
                    projectId: currentProject?.id,
                    skillId: item,
                }
                await addSkillToProject(arg);
            }

            // Restore old Contents

            const currentContents = currentProject?.contents;
            const oldContents = oldProjectRef.current.contents;

            const old_contents: string[] = [...oldContents.map(item => item.id)];
            const new_contents: string[] = currentContents
                ? [...currentContents.map(item => item.id)]
                : [];

            // calcola i Contenuti da eliminare
            const deletedContents = new_contents.filter(item => !old_contents.includes(item));
            // calcola i Contenuti da aggiungere
            const addedContents = old_contents.filter(item => !new_contents.includes(item));

            for (const item of deletedContents) {
                const arg: ProjectContentRequest = {
                    contentId: item,
                    projectId: currentProject.id,
                }

                await removeContentFromProject(arg);
            }

            for (const item of addedContents) {

                const arg: ProjectContentRequest = {
                    contentId: item,
                    projectId: currentProject.id,
                }

                await addContentToProject(arg);
            }

            // Restore old info
            await updateProject(oldProjectRef.current.id, {
                title: oldProjectRef.current.title,
                description: oldProjectRef.current.description,
                profileId: oldProjectRef.current.profile.id,
                start: oldProjectRef.current.start,
                end: oldProjectRef.current.end
            });
        } catch (error) {
            Log.error(error);
        }

    }

    const handleSaveProject = async () => {
        Log.debug("handleSaveProject");

        setProfileError('')

        if (!currentProject.profile){
            setProfileError(t('Insert at least a profile'))
            return;
        }

        const title = currentProject.title && currentProject.title.length>0
            ? currentProject.title
            : t('Untitled');

        const description = currentProject.description && currentProject.description.length>0
            ? currentProject.description
            : t('No description');

        if (currentProject.start?.getTime() > currentProject.end?.getTime()) {
            setShowModal(true);
            setErrorMessage(t('End date cannot be before the start date'));
            return;
        }

        if (isEdit){

            if (modifiedRef.current){

                const arg : ProjectUpdateRequest = {
                    profileId: currentProject.profile.id,
                    title: title,
                    description: description,
                    start: currentProject.start,
                    end: currentProject.end,
                }

                try{

                    // Update Skills

                    const currentSkills = currentProject?.skills;
                    const old = oldProjectRef.current.skills;

                    const old_skills: string[] = [...old.map(item => item.id)];
                    const new_skills: string[] = currentSkills
                        ? [...currentSkills.map(item => item.id)]
                        : [];

                    // calcola le skill da aggiungere
                    const added = new_skills.filter(item => !old_skills.includes(item));
                    // calcola le skill da eliminare
                    const deleted = old_skills.filter(item => !new_skills.includes(item));

                    if (!currentProject.skills || currentProject.skills.length + added.length - deleted.length === 0){
                        setShowModal(true);
                        setErrorMessage(t('Choose at least a skill'));
                        return;
                    }

                    for (const item of deleted) {
                        const arg: ProjectSkillRequest = {
                            projectId: currentProject?.id,
                            skillId: item,
                        }

                        await removeSkillFromProject(arg);
                    }

                    for (const item of added) {
                        const arg: ProjectSkillRequest = {
                            projectId: currentProject?.id,
                            skillId: item,
                        }
                        await addSkillToProject(arg);
                    }

                    // Update Contents

                    const currentContents = currentProject?.contents;
                    const oldContents = oldProjectRef.current.contents;

                    const old_contents: string[] = [...oldContents.map(item => item.id)];
                    const new_contents: string[] = currentContents
                        ? [...currentContents.map(item => item.id)]
                        : [];

                    // calcola i Contenuti da aggiungere
                    const addedContents = new_contents.filter(item => !old_contents.includes(item));
                    // calcola i Contenuti da eliminare
                    const deletedContents = old_contents.filter(item => !new_contents.includes(item));

                    // if (!currentProject.contents || currentProject.contents.length + addedContents.length - deletedContents.length === 0){
                    //     setShowModal(true);
                    //     setErrorMessage(t('Include at least a content'))
                    //     return;
                    // }


                    for (const item of deletedContents) {
                        const arg: ProjectContentRequest = {
                            contentId: item,
                            projectId: currentProject.id,
                        }

                        await removeContentFromProject(arg);
                    }

                    for (const item of addedContents) {

                        const arg: ProjectContentRequest = {
                            contentId: item,
                            projectId: currentProject.id,
                        }

                        await addContentToProject(arg);
                    }

                    const data = await updateProject(projectId,arg);
                    setCurrentProject({
                        ...data,
                        start: new Date(data.start),
                        end: new Date(data.end),
                    });
                    if (OnSuccess){
                        OnSuccess();
                    }

                }
                catch (error){
                    setShowModal(true);
                    setErrorMessage(error.message);
                    if (isEdit){
                        await restoreOldProject();
                    }
                }
            }
        }
        else{

            if (!currentProject.skills || currentProject.skills.length===0){
                setShowModal(true);
                setErrorMessage(t('Choose at least a skill'))
                return;
            }

            // if (!currentProject.contents || currentProject.contents.length===0){
            //     setShowModal(true);
            //     setErrorMessage(t('Include at least a content'))
            //     return;
            // }

            const arg: ProjectCreateRequest = {
                profileId: currentProject.profile.id,
                title: title,
                description: description,
                start: currentProject.start,
                end: currentProject.end,
                contents: currentProject.contents?.map(c => c.id),
                skills: currentProject.skills?.map(s => s.id),
            }
            try{
                const data = await createProject(arg);
                setCurrentProject({
                    ...data,
                    start: new Date(data.start),
                    end: new Date(data.end),
                });
                if (OnSuccess){
                    OnSuccess();
                }
            }
            catch (error){
                setShowModal(true);
                setErrorMessage(error.message);
            }

        }
    }

    const handleChangeProfile = (name) => {
        setCurrentProject({
            ...currentProject,
            profile: profileArray.find(item => item.name === name)
        })
        modifiedRef.current = true;
    }

    const handleChangeProjectName = (title) => {
        setCurrentProject({
            ...currentProject,
            title: title
        });
        modifiedRef.current = true;
    }

    const handleUpdateSkill = (arg: { skills: Skill[] }) => {
        setCurrentProject({
            ...currentProject,
            skills: arg.skills,
        });
        setSelectedSkills(arg?.skills);
        modifiedRef.current = true;
    }

    const handleUpdateContent = (arg: { userId: string, contents: Content[] }) => {
        Log.debug(arg.contents);
        const t = {
            ...currentProject,
            contents: arg.contents,
        }
        Log.debug(t);
        setCurrentProject({
            ...currentProject,
            contents: [...arg.contents],
        });
        setCurrentContents(arg?.contents);
        modifiedRef.current = true;
    }

    const handleChangeStartDate = (date: Date) => {
        setCurrentProject({
            ...currentProject,
            start: date,
        });
        modifiedRef.current = true;
    }

    const handleChangeEndDate = (date: Date) => {
        setCurrentProject({
            ...currentProject,
            end: date,
        });
        modifiedRef.current = true;
    }

    const handleChangeDescription = (html) => {
        setCurrentProject({
            ...currentProject,
            description: html,
        });
        modifiedRef.current = true;
    }

    Log.debug('======CURRENT PROJECT======');
    Log.debug(currentProject);

    return (
        <Box sx={{ flexGrow: 1, p: { xs: 0, md: 0 } }}>
            {showModal && (
                <ModalDialog open={showModal} onClose={() => {
                    setShowModal(false);
                    setErrorMessage('');
                }} label={errorMessage} id={'error'} title={t('Error')}/>
            )}

            <Grid container spacing={2}>
                <Grid size={{xs:12}}>
                    <Typography variant="h4" gutterBottom sx={{fontFamily: 'Oswald, serif', mb: 2, fontSize: { xs: '1rem', md: '1.5rem' }}}>
                        {isEdit ? t('Modify the project') : t('Create a new project')}, {me?.firstname}
                    </Typography>
                </Grid>

                {/* Scelta del profilo */}
                <Grid size={{xs: 12}}>
                    <TextField
                        InputProps={{
                            style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                        }}
                        InputLabelProps={{
                            style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                        }}
                        FormHelperTextProps={{
                            style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                        }}
                        sx={{top: 10}}
                        fullWidth
                        id="prop-profile"
                        select
                        required
                        label={t("Select a Profile")}
                        defaultValue=""
                        value={currentProject?.profile?.name ? currentProject.profile.name : ''}
                        onChange={(e) => handleChangeProfile(e.target.value)}
                        helperText={profileError===''
                            ?t("Select a Profile")
                            :profileError}
                        error={profileError!==''}
                    >
                        <MenuItem sx={{fontFamily: 'Oswald, sans-serif', fontSize: '16px'}} value="" disabled>
                            {t("Select the Profile")}
                        </MenuItem>
                        {profileArray.map((profile) => (isOwner(profile,me) &&
                            <MenuItem key={profile.id} value={profile.name}
                                      sx={{fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}>
                                {profile.name}
                            </MenuItem>))}
                    </TextField>
                </Grid>

                <Grid size={{xs: 12}}>
                    <TextField
                        InputProps={{
                            style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                        }}
                        InputLabelProps={{
                            style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                        }}
                        FormHelperTextProps={{
                            style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                        }}
                        sx={{top: {md:10,xs:4}}}
                        fullWidth
                        label={t('Project Name')}
                        value={currentProject ? currentProject.title : t('Empty Project')}
                        onChange={(e) => handleChangeProjectName(e.target.value)}
                        variant="outlined"
                    />

                    <Card sx={{ flexGrow: 1, mt: 2 }}>
                        <Box
                            pl={2} pt={2}>
                            <Typography variant="h4" gutterBottom sx={{fontFamily: 'Oswald, serif', fontSize: { xs: '1rem', md: '1.5rem' }}}>
                                {t('Skills')}
                            </Typography>
                        </Box>
                        <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Skills skills={selectedSkills} onUpdateSkillData={handleUpdateSkill} isEditing={true}
                                    language={getBrowserLanguage()} label={isEdit ? t('Update') : t('Add')}/>
                        </Box>
                    </Card>
                </Grid>

                <Grid size={{xs: 12}}>
                    <Box sx={{display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, justifyContent: {sm: 'space-between' } }}>
                        <FieldDataPicker sx={{mt: 1, mr: {md :2 }}} value={new Date(currentProject?.start)} label={t('Start')} OnChange={handleChangeStartDate}/>
                        <FieldDataPicker sx={{mt: 1}} value={currentProject?.end? new Date(currentProject?.end):null} label={t('End')} OnChange={handleChangeEndDate}/>
                    </Box>
                </Grid>

                <Grid size={{xs: 12}}>
                    <Typography variant="h4" gutterBottom sx={{fontFamily: 'Oswald, serif', mb: 2, fontSize: { xs: '1rem', md: '1.5rem' }}}>
                        {t('Description')}
                    </Typography>
                    <Editor style={{fontFamily: 'Oswald', fontSize: '16px', minHeight: '200px'}}
                            value={currentProject.description} onChange={
                        (e) => {
                            handleChangeDescription(e.target.value);
                        }
                    }/>
                </Grid>

                <Grid size={{xs: 12}}>
                    <Typography variant="h4" gutterBottom sx={{fontFamily: 'Oswald, serif', mb: 2, fontSize: { xs: '1rem', md: '1.5rem' }}}>
                        {t('Attach Project Media')}
                    </Typography>
                </Grid>

                <Grid size={{xs: 12}}>
                    <ContentSelection userId={me?.id} onUpdateContentData={handleUpdateContent} initialValues={currentContents}/>
                </Grid>

                <Grid size={{xs: 12}}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'row', sm: 'row' },
                        justifyContent: 'center',
                        width: '100%',
                        ml:5}}>

                        <Button
                            size='medium'
                            variant="contained"
                            type="button"
                            fullWidth
                            color="primary"
                            sx={{mt: 3,
                                mb: 2,
                                mr: 10,
                                fontFamily: 'Oswald, sans-serif',
                                fontSize: {xs:'14px',md:'16px'},
                                width: { xs: 'auto', sm: 'auto' },
                                minWidth: { xs: '80px', md:'100px' }}}
                            onClick={handleSaveProject}
                        >
                            {isEdit && t('Update')} {!isEdit && t('Create')}
                        </Button>

                        <Button
                            size='medium'
                            variant="contained"
                            type="button"
                            fullWidth
                            color="primary"
                            sx={{mt: 3,
                                mb: 2,
                                mr: 10,
                                fontFamily: 'Oswald, sans-serif',
                                fontSize: {xs:'14px',md:'16px'},
                                width: { xs: 'auto', sm: 'auto' },
                                minWidth: { xs: '80px', md:'100px' }}}
                            onClick={(e) => {
                                setCurrentProject(emptyProject);
                                if (OnAbort) {
                                    OnAbort();
                                }
                            }}
                        >
                            {t('Cancel')}
                        </Button>

                    </Box>

                </Grid>

            </Grid>

        </Box>
    );

}
