import {Avatar, Box, Hidden, lighten, Link, ListItemAvatar, ListItemButton, Theme, Typography} from "@mui/material";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import React from "react";

interface SearchItemProps {
    title: string;
    subtitle: string;
    avatar: string;
}


const SearchItem : React.FC<SearchItemProps> = ({title,subtitle,avatar}) => {

    return(
        <ListItemButton>
            <Hidden smDown>
                <ListItemAvatar>
                    <Avatar
                        sx={{
                            background: (theme: Theme) =>
                                theme.palette.secondary.main
                        }}
                        src={avatar}
                    />
                </ListItemAvatar>
            </Hidden>
            <Box flex="1">
                <Box display="flex" justifyContent="space-between">
                    <Link
                        href="#"
                        underline="hover"
                        sx={{ fontWeight: 'bold' , fontFamily:'Oswald, sans-serif'}}
                        variant="body2"
                    >
                        {title}
                    </Link>
                </Box>
                <Typography
                    component="span"
                    variant="body2"
                    sx={{
                        fontFamily:'Oswald, sans-serif',
                        color: (theme: Theme) =>
                            lighten(theme.palette.secondary.main, 0.5)
                    }}
                >
                    {subtitle}
                </Typography>
            </Box>
            <ChevronRightTwoToneIcon />
        </ListItemButton>

    );

}

export default SearchItem;
