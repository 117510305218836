import {ContentMetadata} from "../../types/ContentMetadata";
import {Card, CardMedia} from "@mui/material";
import {Log} from "../../utils/utils";
import {ContentType} from "../../types/ContentType";

interface ContentMediaCardProps {
    media?: ContentMetadata;
    contentType?: ContentType;
    onClick?: (item: ContentMetadata) => void;
    sx?: object;
}

export const ContentMediaCard: React.FC<ContentMediaCardProps> = ({media,contentType,sx,onClick}) => {

    Log.debug('====ContentMediaCard : MEDIA===');
    Log.debug(media);
    Log.debug('==========');

    if (!media || media.address.fileType === undefined || media.address.fileType === null) {
        return (
            <></>
        );
    }

    if (media.address){

        const mediaType = media.address.fileType;

        if (mediaType.includes('image')){

            return (
                <Card sx={sx}>
                    <CardMedia
                        component="img"
                        sx={{ width: '100%', height:'100%', borderRadius: '8px', backgroundColor:'#fff' }}
                        src={media.address.uri}
                        alt="Image"
                        onClick={(e) => {if (onClick) onClick(media);}}
                    />
                </Card>
            );

        }
        else if (mediaType.includes('video') && media.address.origin ==="youtube"){
            return (
                <Card sx={sx}>
                    <CardMedia
                        component="iframe"
                        sx={{ width: '100%', height:'100%', borderRadius: '8px'}}
                        src={media.address.uri}
                    />
                </Card>
            );
        }
        else if (mediaType.includes('video') && media.address.origin ==="internal"){
            return (
                <Card sx={sx}>
                    <CardMedia
                        component="video"
                        sx={{ width: '100%', height:'100%', borderRadius: '8px', padding:'32px'}}
                        src={media.address.uri}
                    />
                </Card>
            );
        }
        else if (mediaType.includes('video') && media.address.origin ==="generic"){
            return (
                <Card sx={sx}>
                    <CardMedia
                        component="img"
                        sx={{ width: '100%', height:'100%', borderRadius: '8px', padding:'32px', backgroundColor:'#fff'}}
                        src={require('./assets/video-file.png')}
                        alt="Video File"
                        onClick={(e) => {if (onClick) onClick(media);}}
                    />
                </Card>
            );
        }
        else if (mediaType.includes('audio') && media.address.origin ==="internal"){
            return (
                <Card sx={sx}>
                    <CardMedia
                        component="audio"
                        sx={{ width: '100%', height:'100%', borderRadius: '8px', padding:'32px', backgroundColor:'#fff'}}
                        src={media.address.uri}
                        onClick={(e) => {if (onClick) onClick(media);}}
                    />
                </Card>
            );
        }
        else if (mediaType.includes('audio') && media.address.origin ==="generic"){
            return (
                <Card sx={sx}>
                    <CardMedia
                        component="img"
                        sx={{ width: '100%', height:'100%', borderRadius: '8px', padding:'32px', backgroundColor:'#fff'}}
                        src={require('./assets/audio-file.png')}
                        alt="Audio File"
                        onClick={(e) => {if (onClick) onClick(media);}}
                    />
                </Card>
            );
        }
        else{

            return (
                <Card sx={sx}>
                    <CardMedia
                        component="img"
                        sx={{ width: '100%', height:'100%', borderRadius: '8px', padding:'32px', backgroundColor:'#fff', objectFit:'contain'}}
                        src={contentType?.name==='Document'
                            ? require('./assets/pdf-file.png')
                            : require('./assets/generic-file.png')}
                        alt="Generic File"
                        onClick={(e) => {if (onClick) onClick(media);}}
                    />
                </Card>
            );

        }

    }
}
