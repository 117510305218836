import { Language } from "../types/Language";
import { Message } from "../types/Message";
import { MessageRequest } from "../types/MessageRequest";
import { PagedRequest } from "../types/PagedRequest";
import { PagedResponse } from "../types/PagedResponse";
import { TypeRequest } from "../types/TypeRequest";
import { api } from '../utils/api';
import {LanguageLite} from "../types/LanguageLite";

export const getLanguages = async (): Promise<Array<Language>> => {
    const response = await api.get(`/api/languages`);
    return response.data;
};

export const createLanguage = async (request: TypeRequest): Promise<Language> => {
    const response = await api.post(`/api/languages`, request);
    return response.data;
};

export const updateLanguage = async (id: number, request: TypeRequest): Promise<number> => {
    const response = await api.patch(`/api/languages/${id}`, request);
    return response.data;
};

export const deleteLanguage = async (id: number): Promise<void> => {
    await api.delete(`/api/languages/${id}`);
};

export const getCurrentLanguage = async (): Promise<LanguageLite> => {
    const response = await api.get(`/api/languages/current`);
    return response.data;
};

export const getMessages = async (languageId: number, request: PagedRequest): Promise<PagedResponse<Message>> => {
    const response = await api.post(`/api/languages/${languageId}/messages/list`, request);
    return response.data;
};

export const createMessage = async (languageId: number, request: MessageRequest): Promise<Message> => {
    const response = await api.post(`/api/languages/${languageId}/messages`, request);
    return response.data;
};

export const updateMessage = async (id: number, languageId: number, request: MessageRequest): Promise<number> => {
    const response = await api.patch(`/api/languages/${languageId}/messages/${id}`, request);
    return response.data;
};

export const deleteMessage = async (id: number, languageId: number): Promise<void> => {
    await api.delete(`/api/languages/${languageId}/messages/${id}`);
};
