import { api } from '../utils/api';
import { SubscriptionType } from "../types/SubscriptionType";
import { TypeRequest } from "../types/TypeRequest";

export const getSubscriptionTypes = async (): Promise<Array<SubscriptionType>> => {
    const response = await api.get(`/api/subscription-types`);
    return response.data;
};

export const createSubscriptionType = async (request: TypeRequest): Promise<SubscriptionType> => {
    const response = await api.post(`/api/subscription-types`, request);
    return response.data;
};

export const updateSubscriptionType = async (id: string, request: TypeRequest): Promise<number> => {
    const response = await api.patch(`/api/subscription-types/${id}`, request);
    return response.data;
};

export const deleteSubscriptionType = async (id: string): Promise<void> => {
    await api.delete(`/api/subscription-types/${id}`);
};