import {PagedRequest} from "../types/PagedRequest";
import {PagedResponse} from "../types/PagedResponse";
import {Content} from "../types/Content";
import {ContentRequest} from "../types/ContentRequest";
import {api} from '../utils/api';
import { Project } from "../types/Project";
import {Log} from "../utils/utils";
import {ContentMetadata} from "../types/ContentMetadata";

export const getContents = async (request: PagedRequest): Promise<PagedResponse<Content>> => {
    const response = await api.post(`/api/contents/list`, request);
    return response.data;
};

export const getContentById = async (id: string): Promise<Content> => {
    const response = await api.get(`/api/contents/${id}`);
    return response.data;
};

export const downloadContentById = async (id: string): Promise<File> => {

    const response = await api.get(`/api/contents/${id}/download`,{
        responseType: 'blob',
    });

    const fileName = id;
    const fileType = response.headers['content-type'];
    const blob = response.data;

    return new File([blob], fileName, {type: fileType});

};

export const downloadContentsFromMetadata = async (metadata: ContentMetadata[]): Promise<File[]> => {

    const downloadPromises = metadata.map(async (item) => {

        try{

            const id = item.id;

            const response = await api.get(`/api/contents/${id}/download`,{
                responseType: 'blob',
            });

            const fileName = item.id;
            const fileType = response.headers['content-type'];
            const blob = response.data;

            return new File([blob], fileName, {type: fileType});
        }
        catch(err){
            Log.error(err);
            return null;
        }
    });

    return Promise.all(downloadPromises);

};

export const getMyContents = async (request: PagedRequest): Promise<PagedResponse<Content>> => {
    const response = await api.post(`/api/contents/list/me`, request);
    return response.data;
};

export const getContentsByProfileId = async (profileId: string, request: PagedRequest): Promise<PagedResponse<Content>> => {
    const response = await api.post(`/api/contents/list/${profileId}`, request);
    return response.data;
};

export const createContent = async (request: ContentRequest): Promise<Content> => {
    const response = await api.post(`/api/contents`, request);
    return response.data;
};

export const uploadContent = async (request: ContentRequest, file: File): Promise<Content> => {
    const formData = new FormData();
    formData.append('ProfileId', request.profileId);
    formData.append('ContentTypeId',request.contentTypeId);
    formData.append('Text', request.text);
    formData.append('Nsfw',request.nsfw.toString());
    formData.append('file', file);

    const response = await api.post(`/api/contents/upload`, formData,{
        headers:{
            'Content-Type': 'multipart/form-data',
        }
    });
    return response.data;
};

export const updateContent = async (id: string, request: ContentRequest): Promise<Content> => {
    const response = await api.patch(`/api/contents/${id}`, request);
    return response.data;
};

export const updateAndUploadContent = async (id: string, request: ContentRequest, file: File): Promise<Content> => {
    const formData = new FormData();
    formData.append('ProfileId', request.profileId);
    formData.append('ContentTypeId',request.contentTypeId);
    formData.append('Text', request.text);
    formData.append('Nsfw',request.nsfw.toString());
    formData.append('file', file);

    const response = await api.patch(`/api/contents/${id}/upload`, formData,{
        headers:{
            'Content-Type': 'multipart/form-data',
        }
    });
    return response.data;
};

export const deleteContent = async (id: string): Promise<void> => {
    await api.delete(`/api/contents/${id}`);
};

export const getProjectsByContent = async (contentId: string): Promise<Array<Project>> => {
    const response = await api.get(`/api/contents/${contentId}/projects`);
    return response.data;
};
