import {Elements} from '@stripe/react-stripe-js';
import {Appearance, loadStripe} from '@stripe/stripe-js';
import React, {useEffect, useState} from "react";
import CheckoutForm from "../CheckoutForm";
import "../subscription.css";
import {refreshTokenIfNeeded} from "../../../services/authService";
import {Log} from "../../../utils/utils";
import {createSubscription} from "../../../services/subscriptionService";
import {SubscriptionRequest} from "../../../types/SubscriptionRequest";
import Box from "@mui/material/Box";
import {useParams} from "react-router";
import {stripePromise} from "../../../config";
import ModalDialog from "../../../components/ModalDialog";
import {useDb} from "../../../contexts/DbContext";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_51QItsFJ40hZXKAEjnFifKd5iwV4O8cFPQd0zstsnvN2xbV02KQsnCZOl6n2wbzsYZgOCisZ4ps71MXl7yMFUbK4C001vJL36DL');

const StripePayment: React.FC = () => {

    const [clientSecret, setClientSecret] = useState("");
    const [dpmCheckerLink, setDpmCheckerLink] = useState("");
    const {t} = useDb();
    const {id} = useParams();
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const getErrorMessage = (error : AxiosError)=>{
        let errorMessage: string | null = null;
        const errorNum = error.response?.status;
        const simpleData =  error.response?.data as {
            status: number;
            detail: string;
        }
        const detailErr = simpleData?.detail;

        if (detailErr) {
            return detailErr;
        }

        switch (errorNum) {
            case 403:
                errorMessage = t('Unknown Profile');
                break;
            case 401:
                errorMessage = t('The user is unknown or the password is incorrect.');
                break;
            default:
                errorMessage = t("An issue happened at backend") +" (" + errorNum + ")";
                break;
        }

        return errorMessage;
    }

    useEffect(() => {
        const loadBackendData = async ()=>{

            try {
                await refreshTokenIfNeeded();
                const request: SubscriptionRequest = {
                    subscriptionTypeId: id,//'3CD6DA81-DBFD-4755-8AAD-A78D582E6D7E'
                };
                const subscription = await createSubscription(request);
                if (subscription){
                    setClientSecret(subscription?.payments?.at(0).paymentIntent?.clientSecret);
                }
            }
            catch (error){
                Log.error(error);
                setErrorMessage(t(getErrorMessage(error)));
                setShowModal(true);
            }
        }

        loadBackendData();

    }, []);

    const appearance: Appearance = {
        theme: 'stripe',
    };
    // Enable the skeleton loader UI for optimal loading.
    const loader = 'auto';

    const testId = "3bb81c2b-8075-4990-b093-5883a14df0b6";

    return (
        // <Router>
        <div className="Stripe">
            {showModal && (
                <ModalDialog open={showModal} onClose={() => {
                    setShowModal(false);
                    setErrorMessage('');
                    navigate('/subscribe');
                }} label={errorMessage} id={'error'} title={t('Error')}/>
            )}
            {clientSecret && (
                <Elements options={{clientSecret, appearance, loader}} stripe={stripePromise}>
                    <Box sx={{display: 'flex',
                        justifyContent: 'center',
                        width: {xs: '400px', sm: '600px'}}}>
                        <CheckoutForm subscriptionId={id}/>
                    </Box>
                </Elements>
            )}
        </div>
        // </Router>
    );

}

export default StripePayment;
