import { User } from '../types/User';
import { api } from '../utils/api';
import { PagedRequest } from '../types/PagedRequest';
import { PagedResponse } from '../types/PagedResponse';
import { UserUpdateRequest } from '../types/UserUpdateRequest';
import { UserCreateRequest } from '../types/UserCreateRequest';
import { UserAuthorizeRequest } from '../types/UserAuthorizeRequest';
import { UserCountryRequest } from '../types/UserCountryRequest';
import { UserPropertyRequest } from '../types/UserPropertyRequest';
import {MeUpdateRequest} from "../types/MeUpdateRequest";
import { UserLite } from '../types/UserLite';
import { Project } from '../types/Project';
import {Content} from "../types/Content";

export const getUsers = async (request: PagedRequest): Promise<PagedResponse<User>> => {
    const response = await api.post(`/api/users/list`, request);
    return response.data;
};

export const getUsersWaitingApprovals = async (request: PagedRequest): Promise<PagedResponse<User>> => {
    const response = await api.post(`/api/users/list/waiting-approvals`, request);
    return response.data;
};

export const getUserById = async (id: string): Promise<User> => {
    const response = await api.get(`/api/users/${id}`);
    return response.data;
};

export const createUser = async (request: UserCreateRequest): Promise<User> => {
    const response = await api.post(`/api/users`, request);
    return response.data;
};

export const search = async (request: PagedRequest): Promise<PagedResponse<UserLite>> => {
    const response = await api.post(`/api/users/search`, request);
    return response.data;
};

export const updateUser = async (id: string, request: UserUpdateRequest): Promise<number> => {
    const response = await api.patch(`/api/users/${id}`, request);
    return response.data;
};

export const deleteUser = async (id: string): Promise<void> => {
    await api.delete(`/api/users/${id}`);
};

export const authorizeUser = async (request: UserAuthorizeRequest): Promise<User> => {
    const response = await api.post(`/api/users/${request.userId}/roles/${request.roleId}`);
    return response.data;
};

export const unauthorizeUser = async (request: UserAuthorizeRequest): Promise<User> => {
    const response = await api.delete(`/api/users/${request.userId}/roles/${request.roleId}`);
    return response.data;
};

export const getUserDeleteToken = async (): Promise<void> => {
    await api.get(`/api/users/me/delete`);
};

export const applyDeleteToken = async (token: string): Promise<void> => {
    await api.delete(`/api/users/me/delete/${token}`);
};

export const createUserCountry = async (request: UserCountryRequest): Promise<number> => {
    const response = await api.patch(`/api/users/me/countries`, request);
    return response.data;
};

export const deleteUserCountry = async (request: UserCountryRequest): Promise<number> => {
    const response = await api.delete(`/api/users/me/countries`, { data: request });
    return response.data;
};

export const setUserProperty = async (request: UserPropertyRequest): Promise<number> => {
    const response = await api.patch(`/api/users/me/properties`, request);
    return response.data;
};

export const getMe = async (): Promise<User> => {
    const response = await api.get(`/api/users/me`);
    return response.data;
};

export const updateCurrentUser = async (request: MeUpdateRequest): Promise<number> => {
    const response = await api.patch(`/api/users/me`, request);
    return response.data;
};

export const setProfilePicture = async (file: File): Promise<number> => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(`/api/users/me/pictures/profile`, formData,{
        headers:{
            'Content-Type': 'multipart/form-data',
        }
    });
    return response.data;

}

export const setCoverPicture = async (file: File): Promise<number> => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(`/api/users/me/pictures/cover`, formData,{
        headers:{
            'Content-Type': 'multipart/form-data',
        }
    });
    return response.data;

}

export const getProfilePicture = async (): Promise<File> => {

    const response = await api.get(`/api/users/me/pictures/profile`,{
        responseType: 'blob',
    });

    const fileName = 'me-profile-picture.jpg';
    const fileType = response.headers['content-type'];
    const blob = response.data;

    return new File([blob], fileName, {type: fileType});

}

export const getCoverPicture = async (): Promise<File> => {

    const response = await api.get(`/api/users/me/pictures/cover`,{
        responseType: 'blob',
    });

    const fileName = 'me-cover-picture.jpg';
    const fileType = response.headers['content-type'];
    const blob = response.data;

    return new File([blob], fileName, {type: fileType});

}

export const getProfilePictureById = async (id: string): Promise<File> => {

    const response = await api.get(`/api/users/${id}/pictures/profile`,{
        responseType: 'blob',
    });

    const fileName = 'me-profile-picture.jpg';
    const fileType = response.headers['content-type'];
    const blob = response.data;

    return new File([blob], fileName, {type: fileType});

}

export const getCoverPictureById = async (id:string): Promise<File> => {

    const response = await api.get(`/api/users/${id}/pictures/cover`,{
        responseType: 'blob',
    });

    const fileName = 'me-cover-picture.jpg';
    const fileType = response.headers['content-type'];
    const blob = response.data;

    return new File([blob], fileName, {type: fileType});

}

export const searchUsersBySkill = async (skill: string): Promise<Array<UserLite>> => {
    const response = await api.get(`/api/users/${skill}/search`);
    return response.data;
}

export const getProjectsByUser = async (userId: string): Promise<Array<Project>> => {
    const response = await api.get(`/api/users/${userId}/projects`);
    return response.data;
};

export const getContentsByUser = async (userId: string): Promise<Array<Content>> => {
    const response = await api.get(`/api/users/${userId}/contents`);
    return response.data;
};

export const searchUsers = async (request: PagedRequest): Promise<PagedResponse<User>> => {
    const response = await api.post(`/api/users/search`, request);
    return response.data;
};

