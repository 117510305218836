import ReactDOM from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import {SidebarProvider} from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import {DbProvider} from "./contexts/DbContext";
import {AuthProvider} from "./contexts/AuthContext";
import {stripePromise} from "./config";
import {Elements} from "@stripe/react-stripe-js";

ReactDOM.render(
    <Elements stripe={stripePromise}>
        <HelmetProvider>{}
            <SidebarProvider>
                <AuthProvider>
                    <DbProvider>
                        <BrowserRouter>
                            <App/>
                        </BrowserRouter>
                    </DbProvider>
                </AuthProvider>
            </SidebarProvider>
        </HelmetProvider>
    </Elements>,
    document.getElementById('root')
);

serviceWorker.unregister();
