import React, {ChangeEvent, useEffect, useState} from "react";
import {useDb} from "../../../../contexts/DbContext";
import {User} from "../../../../types/User";
import {
    Box,
    Card,
    CardHeader, Checkbox,
    Divider,
    FormControl, IconButton,
    InputLabel,
    MenuItem,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import BulkActions from "./BulkActions";
import TextField from "@mui/material/TextField";
import {PagedRequest} from "../../../../types/PagedRequest";
import {refreshTokenIfNeeded} from "../../../../services/authService";
import {getUsersWaitingApprovals} from "../../../../services/userService";
import {Log} from "../../../../utils/utils";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import {ApprovalTwoTone} from "@mui/icons-material";
import {Profile} from "../../../../types/Profile";
import {ProfileEnableRequest} from "../../../../types/ProfileEnableRequest";
import {enableProfile} from "../../../../services/profileService";
import users from "./index";
import {makeStyles} from "@mui/styles";
import classes from "*.module.css";

interface Filters {
    name?: string;
}

const useStyles = makeStyles({
    caption: {
        padding: 8,
        fontFamily: "Oswald",
        fontSize: "0.875rem"
    },
    toolbar: {
        "& > p:nth-of-type(2)": {
            fontSize: "1.25rem",
            fontFamily: "Oswald",
            fontWeight: 600
        }
    }
});

export const PendingUsersTable: React.FC = () => {

    const {t} = useDb();
    const [selectedUsers, setSelectedUsers] = React.useState<string[]>([]);
    const [pendingUsers, setPendingUsers] = React.useState<User[]>([]);
    const selectedBulkActions = selectedUsers.length > 0;
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(5);
    const [totalCount, setTotalCount] = React.useState(0);

    const [filters, setFilters] = useState<Filters>({
        name: null
    });

    const handleSelectAllUsers = (
        event: ChangeEvent<HTMLInputElement>
    ): void => {
        setSelectedUsers(
            event.target.checked
                ? pendingUsers.map((user) => user.id)
                : []
        );
    };

    const handleSelectOneUser = (
        event: ChangeEvent<HTMLInputElement>,
        userId: string
    ): void => {
        if (!selectedUsers.includes(userId)) {
            setSelectedUsers((prevSelected) => [
                ...prevSelected,
                userId,
            ]);
        } else {
            setSelectedUsers((prevSelected) =>
                prevSelected.filter((id) => id !== userId)
            );
        }
    };

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    const selectedSomeUsers = selectedUsers.length > 0 &&
        selectedUsers.length < pendingUsers.length;
    const selectedAllUsers = selectedUsers.length > 0 && selectedUsers.length === pendingUsers.length;

    const handleApproveUser = async (event, userId)=>{

        try{

            await refreshTokenIfNeeded();

            const user = pendingUsers.find((user)=>user.id===userId);
            if (user){

                const profiles = user.profiles;
                profiles.forEach(async (profile)=>{
                    const request: ProfileEnableRequest = {
                        userId: userId,
                    }
                    await enableProfile(profile.id,request);
                });

            }

            setPage(page);

        }
        catch(error){
            Log.error(error.message);
        }

    }

    const theme = useTheme();
    const classes = useStyles();

    useEffect(() => {

        const loadBackendData = async (request: PagedRequest) => {

            try {
                await refreshTokenIfNeeded();
                const response = await getUsersWaitingApprovals(request);
                if (response) {
                    setPendingUsers(response.items);
                    setTotalCount(response.totalCount);
                }
            } catch (error) {
                Log.error(error);
                setPendingUsers([]);
                setTotalCount(0);
            }
        }

        const request: PagedRequest = {
            page: page,
            size: limit,
            filter: filters.name,
        }

        loadBackendData(request);

    }, [page, limit, filters]);

    return (
        <>
            <Card>
                {selectedBulkActions && (
                    <Box flex={1} p={2}>
                        <BulkActions/>
                    </Box>
                )}
                {!selectedBulkActions && (
                    <CardHeader
                        action={
                            <Box width={200}>
                                <FormControl fullWidth variant="outlined">
                                    <TextField
                                        InputProps={{
                                            style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                                        }}
                                        InputLabelProps={{
                                            style: {fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                                        }}
                                        FormHelperTextProps={{
                                            style: {fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                                        }}
                                        id="outlined-search"
                                        sx={{mr: 2}}
                                        type="search"
                                        label={t("User Name")}
                                        defaultValue={''}
                                        onChange={(e) => console.log(e.target)}
                                    />
                                </FormControl>
                            </Box>
                        }
                        title={
                            <Typography gutterBottom variant="h4" fontFamily={'Oswald,sans-serif'}>
                                {t('Name')}
                            </Typography>
                        }
                    />
                )}
                <Divider/>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={selectedAllUsers}
                                        indeterminate={selectedSomeUsers}
                                        onChange={handleSelectAllUsers}
                                    />
                                </TableCell>
                                <TableCell sx={{fontFamily: 'Oswald', fontSize: 14,display: { xs: 'none', sm: 'table-cell' }}}>{t('Id')}</TableCell>
                                <TableCell sx={{fontFamily: 'Oswald', fontSize: 14,display: { xs: 'none', sm: 'table-cell' }}}>{t('Name')}</TableCell>
                                <TableCell sx={{fontFamily: 'Oswald', fontSize: 14}}>{t('Email')}</TableCell>
                                <TableCell sx={{fontFamily: 'Oswald', fontSize: 14}}
                                           align="right">{t('Actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                pendingUsers.map((user) => {

                                    const isUserSelected = selectedUsers.includes(
                                        user.id
                                    );

                                    return (
                                        <TableRow
                                            hover
                                            key={user.id}
                                            selected={isUserSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isUserSelected}
                                                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                                        handleSelectOneUser(event, user.id)
                                                    }
                                                    value={isUserSelected}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    maxWidth={'300px'}
                                                    variant="body1"
                                                    fontWeight="bold"
                                                    color="text.primary"
                                                    gutterBottom
                                                    noWrap
                                                    sx={{display: { xs: 'none', sm: 'table-cell' }}}
                                                >
                                                    {user.id}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    variant="body1"
                                                    fontWeight="bold"
                                                    color="text.primary"
                                                    gutterBottom
                                                    noWrap
                                                    sx={{display: { xs: 'none', sm: 'table-cell' }}}
                                                >
                                                    {user.firstname} {user.lastname}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    variant="body1"
                                                    fontWeight="bold"
                                                    color="text.primary"
                                                    gutterBottom
                                                    noWrap
                                                >
                                                    {user.email}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title={t("Approve")} arrow>
                                                    <IconButton
                                                        sx={{
                                                            '&:hover': {
                                                                background: theme.colors.primary.lighter
                                                            },
                                                            color: theme.palette.primary.main,
                                                            fontFamily: 'Oswald'
                                                        }}
                                                        color="inherit"
                                                        size="small"
                                                        onClick={async (event) => {handleApproveUser(event, user.id)}}
                                                    >
                                                        <ApprovalTwoTone fontSize="small"/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={t("Delete")} arrow>
                                                    <IconButton
                                                        sx={{
                                                            '&:hover': {background: theme.colors.error.lighter},
                                                            color: theme.palette.error.main,
                                                            fontFamily: 'Oswald'
                                                        }}
                                                        color="inherit"
                                                        size="small"
                                                        disabled={true}
                                                    >
                                                        <DeleteTwoToneIcon fontSize="small"/>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            <Box p={2}>
                <TablePagination
                    component="div"
                    count={pendingUsers.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 30]}
                />
            </Box>
            <Divider />

        </>
    );

}
