import {styled} from "@mui/material/styles";
import {Typography} from "@mui/material";
import {ContentMetadata} from "../../types/ContentMetadata";

const ContentItemWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
}));

const ContentName = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontFamily: 'Oswald, sans-serif',
    fontSize: '16px'
});

interface ContentProps{
    meta: ContentMetadata,
    language: string
}

export const ContentItem: React.FC<ContentProps>=({meta,language})=> {

    return (
        <ContentItemWrapper>
            <ContentName>{meta.name}</ContentName>
        </ContentItemWrapper>
    );

}
